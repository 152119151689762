import { useRouter } from 'next/router';

import { SORT_OPTIONS } from '@constants/sorts';
import useTranslation from '@hooks/useTranslation';
import InfoIcon from '@mui/icons-material/Info';
import {
    Box,
    MenuItem,
    Select,
    SelectChangeEvent,
    type SxProps,
    type Theme,
    Tooltip,
    Typography,
} from '@mui/material';

interface Props {
    disabled?: boolean;
    sortOptions: SORT_OPTIONS[];
    sortBy: SORT_OPTIONS;
    setSortBy: (sortBy: SORT_OPTIONS) => void;
    prefix: '' | 'roads_';
    sx?: SxProps<Theme>;
}

const SortComponent = ({
    disabled,
    sortOptions,
    sortBy,
    setSortBy,
    prefix,
    sx,
}: Props) => {
    const { t } = useTranslation('library');
    const router = useRouter();

    const selectContent = sortOptions.map((option) => {
        return (
            <MenuItem key={option} value={option}>
                <Box
                    sx={{
                        width: '100%',
                        color: '#121212',
                    }}
                    display="flex"
                    alignItems="space-between"
                    justifyContent="space-between"
                >
                    {t(option)}
                    {option === SORT_OPTIONS.AUTHENTICITY && (
                        <Tooltip
                            title={
                                <div>
                                    <span>
                                        تعتمد درجة صحة المصنف على الترتيب
                                        التالي:
                                    </span>
                                    <br />
                                    <ol>
                                        <li>{t('sahih_bukhari')}</li>
                                        <li>{t('sahih_muslim')}</li>
                                        <li>{t('mwata')}</li>
                                        <li>
                                            {t('sunan_al_nasaai_al_sughra')}
                                        </li>
                                        <li>{t('sahih_ibnhaban')}</li>
                                        <li>{t('sahih_ibnkhuzaymah')}</li>
                                        <li>{t('sunan_abu_dawud')}</li>
                                        <li>{t('jamia_tirmidhi')}</li>
                                        <li>{t('sunan_ibn_majah')}</li>
                                        <li>{t('sunan_darimy')}</li>
                                        <li>{t('musnad_ahmad')}</li>
                                        <li>{t('mustadrak')}</li>
                                    </ol>
                                </div>
                            }
                            placement="top"
                        >
                            <InfoIcon color="secondary" />
                        </Tooltip>
                    )}
                </Box>
            </MenuItem>
        );
    });
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: { xs: 'end', sm: 'space-between' },
                width: '100%',
                gap: 1,
                ...sx,
            }}
        >
            <Typography
                sx={{
                    display: { xs: 'none', sm: 'inline' },
                }}
                variant="body1"
                color="primary"
            >
                {t('sort_by')}
            </Typography>
            {disabled ? (
                <Tooltip title="ترتيب النتائج في حال البحث غير المطابق يكون فقط بحسب درجة المطابقة">
                    <Select
                        size="small"
                        disabled
                        sx={{
                            color: '#121212',
                            textAlign: 'center',
                            paddingTop: 0.5,
                            paddingBottom: 0.5,
                        }}
                        dir="rtl"
                        labelId="sort-by-label"
                        id="sort-dropdown"
                        value={sortBy}
                        renderValue={(value: SORT_OPTIONS) => {
                            return <Typography>{`${t(value)}`}</Typography>;
                        }}
                    >
                        {selectContent}
                    </Select>
                </Tooltip>
            ) : (
                <Select
                    size="small"
                    sx={{
                        color: '#121212',
                    }}
                    dir="rtl"
                    labelId="sort-by-label"
                    id="sort-dropdown"
                    value={sortBy}
                    renderValue={(value) => {
                        return (
                            <Typography>{`${t(
                                value as SORT_OPTIONS,
                            )}`}</Typography>
                        );
                    }}
                    onChange={(event: SelectChangeEvent) => {
                        setSortBy(event.target.value as SORT_OPTIONS);
                        router.query[`${prefix}sort`] = event.target.value;
                        router.push(router, undefined, { shallow: true });
                    }}
                    MenuProps={{
                        disableScrollLock: true,
                    }}
                >
                    {selectContent}
                </Select>
            )}
        </Box>
    );
};

export default SortComponent;
