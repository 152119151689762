import { useState } from 'react';

import { BookPreview } from '@components/bookpreview';
import { booksConfig } from '@components/bookpreview/bookConfigs';
import OriginalHadithModal from '@components/modal/Modal/OriginalHadithModal';

import { useCommonContext } from '@hooks/commonContext';
import { useFontSize } from '@hooks/fontSizeContext';
import { useTashkeel } from '@hooks/tashkeelContext';
import useCopyToClipboard from '@hooks/useCopyToClipboard';
import useTranslation from '@hooks/useTranslation';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import PreviewIcon from '@mui/icons-material/Preview';
import ReportGmailerrorred from '@mui/icons-material/ReportGmailerrorred';
import { Box, Divider, IconButton, Typography, useTheme } from '@mui/material';
import { removeTashkeel } from '@utils/tashkeel';
// @ts-ignore this library doesn't have exported types, nor is it part of the definitely typed project
import ReadMoreAndLess from '@vendor/react-read-more-less';

import {
    HadithActions,
    IHadithLeftAction,
} from '../HadithResultItem/HadithActions';
import { HadithHeaderInfoDetails } from '../HadithResultItem/styled';
import { getBookAndIdText } from '../helpers';
import {
    HadithExplanationContainer,
    HadithExplanationTitleContainer,
} from './styled';
import { analytics } from 'lib/analytics';
import {
    BookReference,
    Explanation,
    ExplanationHadith,
} from 'shared/interfaces/hadith';
import { colors } from 'theme';

interface HadithExplanationResultItemProps {
    explanation_object: {
        hadith: ExplanationHadith;
        explanation: Explanation;
    };
    initNavPointId: string;
    quickNavPoints: BookReference[];
}

const HadithExplanationResultItem = ({
    explanation_object,
    quickNavPoints,
    initNavPointId,
}: HadithExplanationResultItemProps) => {
    const { copyToClipboard } = useCopyToClipboard();
    const {
        hadith,
        explanation,
    }: { hadith: ExplanationHadith; explanation: Explanation } =
        explanation_object || {};

    const { explanation_page, explanation_volume, explanation_book_name } =
        explanation;

    const { t } = useTranslation('library');

    const [scroll, setScroll] = useState(0);

    const [openPreviewModal, setOpenPreviewModal] = useState<boolean>(false);
    const [openChildModal, setOpenChildModal] = useState(false);
    const { fontSize } = useFontSize();
    const { tashkeelEnabled } = useTashkeel();

    const pageVolumeContents: string[] = [
        explanation_volume ? `${t('volume')}: ${explanation_volume}` : '',
        explanation_page ? `${t('page')}: ${explanation_page}` : '',
    ].filter((str) => str);

    const theme = useTheme();
    const { setErrorData } = useCommonContext();

    const errorData = JSON.stringify(
        {
            hadith_id: hadith.hadith_id,
            type: 'explanation',
            book_name: explanation_book_name,
            volume: explanation_volume,
            page: explanation_page,
        },
        null,
        2,
    );

    function copyExplanation() {
        const template = `${hadith.book_name} - ${hadith.number.join(', ')}
${explanation.explanation_book_name} - (${explanation.explanation_volume}/${
            explanation.explanation_page
        })

${explanation.hadith_explanation_array
    .map((item) => (tashkeelEnabled ? item.sharh : removeTashkeel(item.sharh)))
    .join('')}`;

        copyToClipboard(template.trim());
    }

    const leftActions: IHadithLeftAction[] = [
        {
            icon: ContentCopyIcon,
            action: copyExplanation,
            isClickable: true,
        },
        {
            icon: PreviewIcon,
            action: () => {
                analytics('book-preview', {
                    type: 'hadith-explanation',
                    id: explanation_book_name,
                    hadith_id: hadith.hadith_id,
                    source: 'hadith-explanation-result-item',
                });
                setOpenPreviewModal(true);
            },
            isClickable: !!(
                booksConfig[explanation_book_name] &&
                explanation_page &&
                explanation_volume
            ),
            notClickableMessage: t('preview_not_clickable'),
        },
        {
            icon: ReportGmailerrorred,
            action: () => {
                setErrorData({
                    elem_id: errorData,
                    type: 'hadith-explanation',
                    source: 'user-report',
                });
            },
            isClickable: true,
        },
    ];

    return (
        <>
            <HadithExplanationContainer
                variant="outlined"
                elevation={0}
                sx={{ margin: { xs: '10px 0', md: '10px 0' } }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                    }}
                >
                    <HadithHeaderInfoDetails>
                        {pageVolumeContents.map((content, i) => (
                            <Typography
                                key={content}
                                sx={{
                                    padding: '5px',
                                    borderLeft:
                                        i > 0
                                            ? `1px solid  ${theme.palette.divider}`
                                            : undefined,
                                    color: colors.primary,
                                }}
                            >
                                {content}
                            </Typography>
                        ))}
                    </HadithHeaderInfoDetails>
                </Box>
                <HadithExplanationTitleContainer>
                    <Typography
                        fontSize={fontSize.title}
                        mb={1}
                        fontWeight={500}
                    >
                        {explanation.explanation_book_name}
                    </Typography>
                    <Typography
                        fontSize={fontSize.subtitle}
                        textAlign="start"
                        color="secondary"
                    >
                        {explanation.explanation_book_author}
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                            mb: 1,
                        }}
                    >
                        <Typography
                            variant="body2"
                            fontSize={fontSize.body}
                            sx={{
                                color: 'grey',
                            }}
                        >
                            {getBookAndIdText(
                                hadith.book_name,
                                hadith.number[0],
                            )}
                        </Typography>
                        <IconButton
                            onClick={() => setOpenChildModal(true)}
                            sx={{
                                borderRadius: '5px',
                                backgroundColor: colors.hover,
                                color: '#C7956D',
                                fontSize: fontSize.body,
                                '&:hover': {
                                    backgroundColor: colors.hover,
                                },
                            }}
                        >
                            {t('view_original_hadith')}
                        </IconButton>
                    </Box>
                </HadithExplanationTitleContainer>
                <Divider />
                <HadithExplanationTitleContainer>
                    <Typography
                        textAlign="justify"
                        variant="body1"
                        fontSize={fontSize.body}
                        sx={{
                            lineHeight: '36px',
                            color: '#121212',
                            whiteSpace: 'pre-line', // Add this line to preserve newlines
                            py: 1,
                        }}
                        component="div"
                    >
                        <ReadMoreAndLess
                            className="read-more-content"
                            charLimit={400}
                            readMoreText={
                                <IconButton
                                    onClick={() => {
                                        // hadith explanation is only ever within the main modal, so it's safe
                                        // to scroll using the main modal
                                        const container =
                                            document.getElementById(
                                                'main-modal',
                                            );
                                        if (!container) {
                                            return;
                                        }

                                        setScroll(container.scrollTop);
                                    }}
                                >
                                    <ExpandCircleDownIcon
                                        color="secondary"
                                        fontSize="small"
                                    />
                                </IconButton>
                            }
                            readLessText={
                                <IconButton
                                    onClick={() => {
                                        // hadith explanation is only ever within the main modal, so it's safe
                                        // to scroll using the main modal
                                        const container =
                                            document.getElementById(
                                                'main-modal',
                                            );

                                        if (!container) {
                                            return;
                                        }

                                        // scroll to old position
                                        container.scrollTo({
                                            top: scroll,
                                        });
                                    }}
                                >
                                    <ExpandCircleDownIcon
                                        color="secondary"
                                        sx={{ rotate: '180deg' }}
                                    />
                                </IconButton>
                            }
                        >
                            {tashkeelEnabled
                                ? explanation.hadith_explanation_array
                                      // id is numeric string, like "123" so cast to number
                                      .sort((a, b) => +a.id - +b.id)
                                      .reduce((acc, curr) => {
                                          return acc + curr.sharh;
                                      }, '')
                                : removeTashkeel(
                                      explanation.hadith_explanation_array
                                          // id is numeric string, like "123" so cast to number
                                          .sort((a, b) => +a.id - +b.id)
                                          .reduce((acc, curr) => {
                                              return acc + curr.sharh;
                                          }, ''),
                                  )}
                        </ReadMoreAndLess>
                    </Typography>
                </HadithExplanationTitleContainer>
                <HadithActions rightActions={[]} leftActions={leftActions} />
                {booksConfig[explanation_book_name] &&
                    explanation_volume &&
                    explanation_page &&
                    openPreviewModal && (
                        <BookPreview
                            open={openPreviewModal}
                            setOpen={setOpenPreviewModal}
                            initNavPointId={initNavPointId}
                            quickNavPoints={quickNavPoints}
                            // no editions yet
                            initEditionsRefs={[]}
                        />
                    )}
            </HadithExplanationContainer>
            {openChildModal && (
                <OriginalHadithModal
                    originalHadith={hadith}
                    onClose={() => setOpenChildModal(false)}
                />
            )}
        </>
    );
};

export default HadithExplanationResultItem;
