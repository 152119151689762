import { useState } from 'react';

import { useRouter } from 'next/router';

import SearchBase from '@components/base/SearchBase';
import FiltersSelected from '@components/filters/elements/FiltersSelected';
import { MoreResultsNotice } from '@components/results/elements/MoreResultsNotice';
import ResultItemList from '@components/results/elements/ResultItemList';
import AmbiguousResultItem from '@components/results/items/AmbiguousResultItem/AmbiguousResultItem';
import HadithSkeleton from '@components/skeletons/HadithSkeleton';

import { ReactiveComponent, StateProvider } from '@appbaseio/reactivesearch';
import { ambiguousIndex } from '@config/reactive-search';
import { AmbiguousDataFields } from '@constants/datafields';
import { AmbiguousViewFilters } from '@constants/filters';
import useTranslation from '@hooks/useTranslation';
import { ReactiveSearchLayout } from '@layout';
import { Typography } from '@mui/material';
import { numberWithCommas } from '@utils/numbersDisplay';

import AmbiguousFilters from './AmbiguosFilters';
import type { BookReference, NextState } from 'shared/interfaces/hadith';
import { checkFiltersSelected } from 'shared/methods';
import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';

export interface AmbiguousViewProps {
    hadithId: string;
    selectedAmbiguousId: string;
    explanationIds: number[];
}
const ResultItems = z.object({
    data: z
        .object({
            author: z.string(),
            book_name: z.string(),
            explanation: z.string(),
            page: z.number().nullable(),
            volume: z.number().nullable(),
        })
        .array(),
    loading: z.boolean(),
});

const AmbiguousReferences = z.object({
    hits: z.object({
        hits: z
            .object({
                _source: z.object({
                    page: z.number().nullable(),
                    volume: z.number().nullable(),
                    book_name: z.string(),
                }),
            })
            .array(),
    }),
});

function getAmbiguousReferences(
    fullSearchState: NextState,
    amniguousCount: number,
): Promise<BookReference[]> {
    const jsonBody = JSON.stringify({
        query: fullSearchState['ambg_result'].query,
        aggs: {},
        size: amniguousCount,
        from: 0,
        _source: {
            includes: [
                AmbiguousDataFields.BOOK_NAME,
                AmbiguousDataFields.VOLUME,
                AmbiguousDataFields.PAGE,
            ],
            excludes: [],
        },
    });

    return fetch(`/api/reactivesearchproxy/${ambiguousIndex}/_search`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: jsonBody,
    })
        .then((res) => res.json())
        .then((json) => {
            const {
                hits: { hits },
            } = AmbiguousReferences.parse(json);

            const ambiguousReferences: BookReference[] = hits.map((hit) => ({
                page: hit._source.page ?? 0,
                volume: hit._source.volume ?? 0,
                book_name: hit._source.book_name,
                id: hit._source.book_name,
                label: hit._source.book_name,
                editionsRefs: [],
            }));
            return ambiguousReferences;
        });
}

const size = 10;

export function AmbiguousView({
    hadithId,
    selectedAmbiguousId,
    explanationIds,
}: AmbiguousViewProps) {
    const { t } = useTranslation('library');
    const router = useRouter();
    const [hasSelectedFilters, setHasSelectedFilters] = useState(false);
    const [collapsed, setCollapsed] = useState(false);
    const [totalResults, setTotalResults] = useState(0);

    const [ambiguousReferences, setAmbiguousReferences] = useState<
        BookReference[]
    >([]);

    const resultStatsMessage = (
        <Typography variant="body1" color="primary">
            {`${t('ambiguous_count')}: ${numberWithCommas(totalResults)}`}
        </Typography>
    );

    return (
        <SearchBase
            app={ambiguousIndex}
            setSearchParams={(newURL) => {
                router.push(newURL, undefined, {
                    shallow: true,
                });
            }}
        >
            {/* This is used to constrain the other filters to the selected ambiguous words, which is only one word actually */}
            {/* so ambiguousIds is an array of length 1 */}
            <ReactiveComponent
                componentId={AmbiguousViewFilters.AMBIGUOUS_ID}
                showFilter={false}
                customQuery={() => ({
                    query: {
                        terms: {
                            [AmbiguousDataFields.ID]: explanationIds,
                        },
                    },
                })}
            />
            <ReactiveSearchLayout
                hasSelectedFilters={hasSelectedFilters}
                collapsed={collapsed}
                setCollapsed={setCollapsed}
                filters={(setDrawerFiltersOpen) => (
                    <AmbiguousFilters
                        setDrawerFiltersOpen={setDrawerFiltersOpen}
                    />
                )}
                resultStatsMessage={resultStatsMessage}
                results={(handleFilter) => (
                    <>
                        <FiltersSelected />
                        <ResultItemList
                            hasSelectedFilters={hasSelectedFilters}
                            componentId={AmbiguousViewFilters.RESULT}
                            dataField={AmbiguousDataFields.ID}
                            resultStatsMessage={resultStatsMessage}
                            onData={(data) =>
                                setTotalResults(
                                    data.resultStats.numberOfResults,
                                )
                            }
                            dependencies={{
                                // this implicitly depends on the component AMBIGUOUS_ID but doesn't explicitly depend on it
                                // because if it explicitly depends on it, you end up with "no results" when switching the
                                // ambiguous words in the dropdown list.
                                // I believe introducing the implicit, indirect dependency on AMBIGUOUS_ID reduces intermediate
                                // states, and that's what was happening with the "no results", it's an intermediate state
                                // that somehow got stuck.
                                // anyways, RESULT -> AMBIGUOUS_AUTHOR, AMBIGUOUS_BOOK -> AMBIGUOUS_ID
                                // so RESULT won't refresh immediately on AMBIGUOUS_ID change, but only after
                                // AMBIGUOUS_AUTHOR, AMBIGUOUS_BOOK have also refreshed, so fewer intermediate states
                                and: [
                                    AmbiguousViewFilters.AMBIGUOUS_AUTHOR,
                                    AmbiguousViewFilters.AMBIGUOUS_BOOK,
                                ],
                            }}
                            size={size}
                            resultItems={(items) => {
                                const { data, loading } =
                                    ResultItems.parse(items);
                                const ambiguousWords = data.map((item) => (
                                    <AmbiguousResultItem
                                        hadithId={hadithId}
                                        author={item.author}
                                        ambgId={selectedAmbiguousId}
                                        reportErrorId={JSON.stringify(
                                            {
                                                hadith_id: hadithId,
                                                id: selectedAmbiguousId,
                                                book_name: item.book_name,
                                                volume: item.volume,
                                                page: item.page,
                                            },
                                            null,
                                            2,
                                        )}
                                        key={uuidv4()}
                                        book_name={item.book_name}
                                        explanation={item.explanation}
                                        page={item.page}
                                        volume={item.volume}
                                        quickNavPoints={ambiguousReferences}
                                    />
                                ));

                                if (loading && data.length > size) {
                                    return ambiguousWords.concat(
                                        Array(size)
                                            .fill(0)
                                            .map(() => (
                                                <HadithSkeleton
                                                    key={uuidv4()}
                                                    withActions={false}
                                                />
                                            )),
                                    );
                                } else if (loading) {
                                    // filtering, not paginating, since we have one page only (data.length <= size)
                                    // return a single page of skeletons, no Hadiths
                                    // the *2 heuristic is because a general skeleton is shorter than a Hadith, not perfect
                                    return Array(size * 2)
                                        .fill(0)
                                        .map(() => (
                                            <HadithSkeleton
                                                key={uuidv4()}
                                                withActions={false}
                                            />
                                        ));
                                }

                                return ambiguousWords.concat(
                                    <MoreResultsNotice
                                        key="more-results-notice"
                                        moreResults={
                                            ambiguousWords.length < totalResults
                                        }
                                    />,
                                );
                            }}
                            includeFields={[
                                'id',
                                'author',
                                'book_name',
                                'explanation',
                                'page',
                                'volume',
                            ]}
                            defaultQuery={() => {
                                return {
                                    track_total_hits: true,
                                    query: {
                                        terms: {
                                            [AmbiguousDataFields.ID]:
                                                explanationIds,
                                        },
                                    },
                                };
                            }}
                            loader={Array(size)
                                .fill(0)
                                .map(() => (
                                    <HadithSkeleton
                                        key={uuidv4()}
                                        withActions={false}
                                    />
                                ))}
                            stream={true}
                            showPagination={false}
                            onFilterClick={handleFilter}
                        />
                        <StateProvider
                            includeKeys={[
                                'value',
                                'hits',
                                'aggregations',
                                'error',
                                'query',
                                'aggs',
                                'size',
                                'includeFields',
                                'react',
                                'sort',
                            ]}
                            //Counts coming from aggregations query
                            onChange={(state, nextState: NextState) => {
                                checkFiltersSelected(
                                    { ...state, ...nextState },
                                    hasSelectedFilters,
                                    setHasSelectedFilters,
                                    'ambg_',
                                );

                                if (nextState?.ambg_result?.hits?.total) {
                                    getAmbiguousReferences(
                                        nextState,
                                        nextState['ambg_result'].hits.total,
                                    ).then((references) =>
                                        setAmbiguousReferences(references),
                                    );
                                }
                            }}
                        />
                    </>
                )}
            />
        </SearchBase>
    );
}
