import { useState } from 'react';

import { type MySnackbarProps } from '@components/snackbar/Snackbar';

import useTranslation from '@hooks/useTranslation';
import { Box } from '@mui/material';
import Alert from '@mui/material/Alert';

import { HadithRulingsView } from 'components';
import {
    type NarrationsType,
    type SearchPageHadith,
} from 'shared/interfaces/hadith';

interface RulingsControllerProps {
    /**
     * @description hadith
     */
    hadith: SearchPageHadith;
    setShowSnackbar: (x: MySnackbarProps) => void;
}

/**
 * @description
 * @info follows this page pattern: '/[page]/[hadithId]/[hadithView]'
 */
const RulingsController = ({
    hadith,
    setShowSnackbar,
}: RulingsControllerProps) => {
    const { t } = useTranslation('library');
    const defaultNarrationsType: NarrationsType = hadith.hasRuling
        ? 'roads'
        : 'shawahed';
    const [narrationsType, setNarrationsType] = useState<NarrationsType>(
        defaultNarrationsType,
    );
    return (
        <Box mt={1}>
            <Alert severity="warning">
                <span
                    dangerouslySetInnerHTML={{
                        __html: t('in_progress_rulings_feature'),
                    }}
                ></span>
            </Alert>
            <Box mt={2}>
                <HadithRulingsView
                    hadith={hadith}
                    setShowSnackbar={setShowSnackbar}
                    narrationsType={narrationsType}
                    setNarrationsType={setNarrationsType}
                />
            </Box>
        </Box>
    );
};

export default RulingsController;
