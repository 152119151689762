import { useState } from 'react';

import { useRouter } from 'next/router';

import { CustomTooltip } from '@components/CustomTooltip';
import { type MySnackbarProps } from '@components/snackbar/Snackbar';

import { mainSearchStateKeys } from '@constants/filters';
import { useContextualRouting, useParams } from '@hooks';
import { ROUTING } from '@hooks/useParams';
import useTranslation from '@hooks/useTranslation';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { Box, Divider, useMediaQuery, useTheme } from '@mui/material';

import { AmbiguousController } from '../AmbiguousController';
import { CommentaryController } from '../CommentaryController';
import { ExplanationController } from '../ExplanationController';
import { NarratorsController } from '../NarratorsController';
import { RoadsController } from '../RoadsController';
import { RulingsController } from '../RulingsController';
import { HadithViewsTab } from './styled';
import { HadithViews, NarratorViews, RoadsViews } from 'constants/views';
import { analytics } from 'lib/analytics';
import { SearchPageHadith } from 'shared/interfaces/hadith';
import { colors } from 'theme';

/**
 * @description controls which hadith details tab is shown
 * @info follows this page pattern: '/[page]/[hadithId]/[hadithView]'
 */
const HadithController = ({
    hadith,
    setShowSnackbar,
}: {
    hadith: SearchPageHadith;
    setShowSnackbar: (x: MySnackbarProps) => void;
}) => {
    const { t } = useTranslation('library');
    const router = useRouter();
    const { makeContextualHref } = useContextualRouting({
        queryToKeep: mainSearchStateKeys,
    });
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const getUseParamsRouting = (selectBy?: string) => {
        if (selectBy === HadithViews.ROADS) return ROUTING.HADITH_PAGE_ROADS;
        return ROUTING.HADITH_PAGE_NARRATOR;
    };

    const {
        hadithId,
        hadithView = HadithViews.ROADS,
        hadithNarratorId,
        hadithNarratorView,
        ambg_id,
        roadsView,
    } = useParams(getUseParamsRouting(router?.query?.param?.[1]));

    if (!hadithId) {
        return null;
    }

    const firstAmbiguousId = hadith.ambiguous?.[0]?.reference_id;
    const selectedAmbiguousId = ambg_id || firstAmbiguousId;
    const ambiguousExplanationIds =
        hadith.ambiguous.find(
            (item) => item.reference_id === selectedAmbiguousId,
        )?.explanation_ids || [];

    const handleRouteChange = (_: any, tab: HadithViews) => {
        const firstNarratorId = hadith.narrators?.[0]?.id;
        const selectedNarratorId = hadithNarratorId || firstNarratorId;
        const selectedNarratorView = hadithNarratorView || NarratorViews.BIO;
        const selectedRoadsView = roadsView || RoadsViews.ROADS;

        analytics('hadith-tabs', {
            hadith_id: hadithId,
            view: tab,
            source: 'hadith-controller',
        });

        if (tab === HadithViews.ROADS) {
            router.push(
                makeContextualHref({
                    hadithId,
                    hadithView: tab,
                    hadithNarratorId: selectedNarratorId,
                    hadithNarratorView: selectedNarratorView,
                    roadsView: selectedRoadsView,
                }),
                undefined,
                { shallow: true },
            );
        } else if (tab === HadithViews.EXPLANATION) {
            router.push(
                makeContextualHref({
                    hadithId,
                    hadithView: tab,
                }),
                undefined,
                { shallow: true },
            );
        } else if (tab === HadithViews.COMMENTARY) {
            router.push(
                makeContextualHref({
                    hadithId,
                    hadithView: tab,
                }),
                undefined,
                { shallow: true },
            );
        } else if (tab === HadithViews.RULINGS) {
            router.push(
                makeContextualHref({
                    hadithId,
                    hadithView: tab,
                }),
                undefined,
                { shallow: true },
            );
        } else if (tab === HadithViews.NARRATOR) {
            router.push(
                makeContextualHref({
                    hadithId,
                    hadithView: tab,
                    hadithNarratorId: selectedNarratorId,
                    hadithNarratorView: selectedNarratorView,
                    roadsView: selectedRoadsView,
                }),
                undefined,
                { shallow: true },
            );
        } else if (tab === HadithViews.AMBIGUOUS_WORDS) {
            router.push(
                makeContextualHref({
                    hadithId,
                    hadithView: tab,
                    ambg_id: selectedAmbiguousId,
                }),
                undefined,
                { shallow: true },
            );
        } else {
            router.push(
                makeContextualHref({
                    hadithId,
                    hadithView: tab,
                }),
                undefined,
                { shallow: true },
            );
        }
    };

    return (
        <Box
            sx={{
                mt: { xs: 1, md: 4 },
            }}
        >
            <TabContext value={hadithView}>
                <TabList
                    onChange={handleRouteChange}
                    variant={isMobile ? 'scrollable' : 'standard'}
                >
                    {!hadith.hadith_id ? (
                        <Box style={{ position: 'relative' }}>
                            <CustomTooltip
                                message={
                                    !hadith.hadith_id
                                        ? t('roads_not_clickable')
                                        : ''
                                }
                            />

                            <HadithViewsTab
                                sx={{
                                    minWidth: 20,
                                    color: !hadith.hadith_id
                                        ? '#ccc'
                                        : 'initial',
                                    '&:hover': {
                                        backgroundColor:
                                            hadithView === HadithViews.ROADS
                                                ? colors.secondary
                                                : colors.hover,
                                    },
                                }}
                                label={<span>{t('roadsWithoutAl')}</span>}
                                value={HadithViews.ROADS}
                            />
                        </Box>
                    ) : (
                        <HadithViewsTab
                            sx={{
                                minWidth: 20,
                                color: !hadith.hadith_id ? '#ccc' : 'initial',
                                '&:hover': {
                                    backgroundColor:
                                        hadithView === HadithViews.ROADS
                                            ? colors.secondary
                                            : colors.hover,
                                },
                            }}
                            label={<span>{t('roadsWithoutAl')}</span>}
                            value={HadithViews.ROADS}
                        />
                    )}
                    {!hadith.narrators.length ? (
                        <Box style={{ position: 'relative' }}>
                            <CustomTooltip
                                message={
                                    !hadith.narrators.length
                                        ? t('narrators_not_clickable')
                                        : ''
                                }
                            />

                            <HadithViewsTab
                                sx={{
                                    minWidth: 20,
                                    color: !hadith.narrators.length
                                        ? '#ccc'
                                        : 'initial',
                                    '&:hover': {
                                        backgroundColor:
                                            hadithView === HadithViews.NARRATOR
                                                ? colors.secondary
                                                : colors.hover,
                                    },
                                }}
                                label={<span>{t('narratorsWithoutAl')}</span>}
                                value={HadithViews.NARRATOR}
                            />
                        </Box>
                    ) : (
                        <HadithViewsTab
                            sx={{
                                minWidth: 20,
                                color: !hadith.narrators.length
                                    ? '#ccc'
                                    : 'initial',
                                '&:hover': {
                                    backgroundColor:
                                        hadithView === HadithViews.NARRATOR
                                            ? colors.secondary
                                            : colors.hover,
                                },
                            }}
                            label={<span>{t('narratorsWithoutAl')}</span>}
                            value={HadithViews.NARRATOR}
                        />
                    )}
                    {!(
                        hadith.hasExplanation || hadith.hasExtendedExplanation
                    ) ? (
                        <Box style={{ position: 'relative' }}>
                            <CustomTooltip
                                message={t('explanation_not_clickable')}
                            />

                            <HadithViewsTab
                                sx={{
                                    minWidth: 20,
                                    color: !hadith.hasExplanation
                                        ? '#ccc'
                                        : 'initial',
                                    '&:hover': {
                                        backgroundColor:
                                            hadithView ===
                                            HadithViews.EXPLANATION
                                                ? colors.secondary
                                                : colors.hover,
                                    },
                                }}
                                label={<span>{t('explanationWithoutAl')}</span>}
                                value={HadithViews.EXPLANATION}
                            />
                        </Box>
                    ) : (
                        <HadithViewsTab
                            sx={{
                                minWidth: 20,
                                color: !(
                                    hadith.hasExplanation ||
                                    hadith.hasExtendedExplanation
                                )
                                    ? '#ccc'
                                    : 'initial',
                                '&:hover': {
                                    backgroundColor:
                                        hadithView === HadithViews.EXPLANATION
                                            ? colors.secondary
                                            : colors.hover,
                                },
                            }}
                            label={<span>{t('explanationWithoutAl')}</span>}
                            value={HadithViews.EXPLANATION}
                        />
                    )}
                    {!hadith.hasCommentary ? (
                        <Box style={{ position: 'relative' }}>
                            <CustomTooltip
                                message={
                                    !hadith.hasCommentary
                                        ? t('commentary_not_clickable')
                                        : ''
                                }
                            />

                            <HadithViewsTab
                                sx={{
                                    minWidth: 20,
                                    color: !hadith.hasCommentary
                                        ? '#ccc'
                                        : 'initial',
                                    '&:hover': {
                                        backgroundColor:
                                            hadithView ===
                                            HadithViews.COMMENTARY
                                                ? colors.secondary
                                                : colors.hover,
                                    },
                                }}
                                label={<span>{t('commentaryWithoutAl')}</span>}
                                value={HadithViews.COMMENTARY}
                            />
                        </Box>
                    ) : (
                        <HadithViewsTab
                            sx={{
                                minWidth: 20,
                                color: !hadith.hasCommentary
                                    ? '#ccc'
                                    : 'initial',
                                '&:hover': {
                                    backgroundColor:
                                        hadithView === HadithViews.COMMENTARY
                                            ? colors.secondary
                                            : colors.hover,
                                },
                            }}
                            label={<span>{t('commentaryWithoutAl')}</span>}
                            value={HadithViews.COMMENTARY}
                        />
                    )}

                    {!(hadith.hasRuling || hadith.hasExtendedRuling) ? (
                        <Box style={{ position: 'relative' }}>
                            <CustomTooltip
                                message={
                                    !(
                                        hadith.hasRuling ||
                                        hadith.hasExtendedRuling
                                    )
                                        ? t('ruling_not_clickable')
                                        : ''
                                }
                            />

                            <HadithViewsTab
                                sx={{
                                    minWidth: 20,
                                    color: !(
                                        hadith.hasRuling ||
                                        hadith.hasExtendedRuling
                                    )
                                        ? '#ccc'
                                        : 'initial',
                                    '&:hover': {
                                        backgroundColor:
                                            hadithView === HadithViews.RULINGS
                                                ? colors.secondary
                                                : colors.hover,
                                    },
                                }}
                                label={<span>{t('rulingsWithoutAl')}</span>}
                                value={HadithViews.RULINGS}
                            />
                        </Box>
                    ) : (
                        <HadithViewsTab
                            sx={{
                                minWidth: 20,
                                color: !(
                                    hadith.hasRuling || hadith.hasExtendedRuling
                                )
                                    ? '#ccc'
                                    : 'initial',
                                '&:hover': {
                                    backgroundColor:
                                        hadithView === HadithViews.RULINGS
                                            ? colors.secondary
                                            : colors.hover,
                                },
                            }}
                            label={<span>{t('rulingsWithoutAl')}</span>}
                            value={HadithViews.RULINGS}
                        />
                    )}
                    {!hadith.ambiguous?.length ? (
                        <Box style={{ position: 'relative' }}>
                            <CustomTooltip
                                message={
                                    !hadith.ambiguous.length
                                        ? t('ambiguous_not_clickable')
                                        : ''
                                }
                            />

                            <HadithViewsTab
                                sx={{
                                    minWidth: 20,
                                    color: !hadith.ambiguous.length
                                        ? '#ccc'
                                        : 'initial',
                                    '&:hover': {
                                        backgroundColor:
                                            hadithView ===
                                            HadithViews.AMBIGUOUS_WORDS
                                                ? colors.secondary
                                                : colors.hover,
                                    },
                                }}
                                label={
                                    <span>
                                        {t('ambiguous_words_withoutAl')}
                                    </span>
                                }
                                value={HadithViews.AMBIGUOUS_WORDS}
                            />
                        </Box>
                    ) : (
                        <HadithViewsTab
                            sx={{
                                minWidth: 20,
                                color: !hadith.ambiguous.length
                                    ? '#ccc'
                                    : 'initial',
                                '&:hover': {
                                    backgroundColor:
                                        hadithView ===
                                        HadithViews.AMBIGUOUS_WORDS
                                            ? colors.secondary
                                            : colors.hover,
                                },
                            }}
                            label={
                                <span>{t('ambiguous_words_withoutAl')}</span>
                            }
                            value={HadithViews.AMBIGUOUS_WORDS}
                        />
                    )}
                </TabList>
                <Divider
                    orientation="horizontal"
                    sx={{
                        mt: 0.5,
                        backgroundColor: colors.secondary,
                        borderBottomWidth: 2,
                    }}
                />
                {hadithView === HadithViews.ROADS && (
                    <RoadsController
                        hadith={hadith}
                        setShowSnackbar={setShowSnackbar}
                    />
                )}
                {hadithView === HadithViews.NARRATOR && (
                    <NarratorsController
                        narrators={hadith.narrators ?? []}
                        containerId="main-modal"
                        setShowSnackbar={setShowSnackbar}
                        autoComplete={false}
                        routing={ROUTING.HADITH_PAGE_NARRATOR}
                    />
                )}
                {hadithView === HadithViews.EXPLANATION && (
                    <ExplanationController
                        hadith={hadith}
                        setShowSnackbar={setShowSnackbar}
                    />
                )}
                {hadithView === HadithViews.COMMENTARY && (
                    <CommentaryController
                        hadith={hadith}
                        setShowSnackbar={setShowSnackbar}
                    />
                )}
                {hadithView === HadithViews.RULINGS && (
                    <RulingsController
                        hadith={hadith}
                        setShowSnackbar={setShowSnackbar}
                    />
                )}
                {hadithView === HadithViews.AMBIGUOUS_WORDS && (
                    <AmbiguousController
                        hadith={hadith}
                        setShowSnackbar={setShowSnackbar}
                        selectedAmbiguousId={selectedAmbiguousId}
                        explanationIds={ambiguousExplanationIds}
                    />
                )}
            </TabContext>
        </Box>
    );
};

export default HadithController;
