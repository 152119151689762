import { NextRouter } from 'next/router';

/**
 * If removeList is empty, the function removes all params from url.
 * @param {router} router  from next/router
 * @param {removeList} removeList list of params to remove from url
 * @param {shallow} shallow whether to use shallow routing or not, default is false
 */
const removeQueryParamsFromRouter = (
    router: NextRouter,
    removeList: string[] = [],
    shallow = false,
) => {
    if (removeList.length > 0) {
        removeList.forEach((param) => delete router.query[param]);
    } else {
        // Remove all
        Object.keys(router.query).forEach(
            (param) => delete router.query[param],
        );
    }
    router.push(
        {
            pathname: router.pathname,
            query: router.query,
        },
        undefined,
        /**
         * Do not refresh the page
         */
        { shallow },
    );
};

export default removeQueryParamsFromRouter;
