import { Box, Paper, styled } from '@mui/material';

export const HadithExplanationContainer = styled(Paper)(() => ({
    borderRadius: '10px',
    textAlign: 'justify',
}));

export const HadithExplanationTitleContainer = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'right',
    flexDirection: 'column',
    paddingRight: 25,
    paddingLeft: 10,
}));
