import { type ReactElement } from 'react';

import { Box } from '@mui/material';

export function SecondHeaderLevelDesktop({
    advancedActions,
    sortComponent,
}: {
    advancedActions: ReactElement | null;
    sortComponent: ReactElement | null;
}) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'end',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'start',
                    gap: 0.5,
                }}
                width="fit-content"
            >
                {advancedActions}
                {sortComponent}
            </Box>
        </Box>
    );
}
