import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { Box } from '@mui/material';

import theme from 'theme';

export function MoreResultsNotice({ moreResults }: { moreResults: boolean }) {
    if (!moreResults) {
        return null;
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Box
                sx={{
                    marginY: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: theme.palette.secondary.main,
                    fontSize: 20,
                    paddingX: 4,
                    paddingY: 1,
                }}
            >
                <KeyboardDoubleArrowDownIcon />
                <span>اسحب لرؤية المزيد</span>
            </Box>
        </Box>
    );
}
