import { load } from 'cheerio';

function highlightSrcTarget(
    hadithHtml: string,
    srcId: string,
    targetId: string,
) {
    const $ = load(hadithHtml);

    const srcNarrator = $(`a.rawy#${srcId}`);
    const targetNarrator = $(`a.rawy#${targetId}`);

    srcNarrator.html(`<highlight-cyan>${srcNarrator.html()}</highlight-cyan>`);
    targetNarrator.html(`<highlight>${targetNarrator.html()}</highlight>`);

    return $.html();
}

export { highlightSrcTarget };
