import { Box, Paper, styled } from '@mui/material';

export const HadithRulingsContainer = styled(Paper)(() => ({
    borderRadius: '10px',
    textAlign: 'justify',
}));

export const HadithRulingsTitleContainer = styled(Box)(() => ({
    paddingTop: 10,
    paddingRight: 25,
    paddingLeft: 10,
}));
