import { useState } from 'react';

import type { MySnackbarProps } from '@components/snackbar/Snackbar';

import { Box } from '@mui/material';

import { HadithCommentaryView } from 'components';
import {
    type NarrationsType,
    SearchPageHadith,
} from 'shared/interfaces/hadith';

interface CommentaryControllerProps {
    /**
     * @description hadith
     */
    hadith: SearchPageHadith;
    setShowSnackbar: (x: MySnackbarProps) => void;
}

/**
 * @description
 * @info follows this page pattern: '/[page]/[hadithId]/[hadithView]'
 */
const CommentaryController = ({
    hadith,
    setShowSnackbar,
}: CommentaryControllerProps) => {
    const [narrationsType, setNarrationsType] = useState<NarrationsType>('all');
    return (
        <Box mt={4}>
            <Box>
                <HadithCommentaryView
                    hadith={hadith}
                    setShowSnackbar={setShowSnackbar}
                    narrationsType={narrationsType}
                    setNarrationsType={setNarrationsType}
                />
            </Box>
        </Box>
    );
};

export default CommentaryController;
