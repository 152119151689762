import {
    getBookAndIdTextToggle,
    getHadithSubtitle,
    getHadithWithTarqeemToggled,
    getHadithWithTashkeelToggled,
    getVolumeAndPageText,
} from '@components/results/items/helpers';

import {
    NarrationsDataFields,
    NarrationsLinksDataFields,
} from '@constants/datafields';

import { getTakhreej, narrationsNumbersToRawNarrations } from './narrations';
import { AlignmentType, Document, HeadingLevel, Packer, Paragraph } from 'docx';
import { analytics } from 'lib/analytics';
import {
    Filter,
    Link,
    NarratorHadith,
    NextState,
    RoadsHadith,
    SearchPageHadith,
} from 'shared/interfaces/hadith';
import { type WorkSheet, utils, writeFile } from 'xlsx';
import { z } from 'zod';

function htmlStripped(hadithHtml: string): string {
    return hadithHtml.replace(/<\/?[^>]+(>|$)/g, '');
}

const NARRATIONS_INDEX = process.env
    .NEXT_PUBLIC_NARRATIONS_INDEX_NAME as string;

const CHAINS_LINK_INDEX = process.env
    .NEXT_PUBLIC_CHAINS_LINKS_INDEX_NAME as string;

type ExportStatePrefix =
    | ''
    | 'roads_'
    | 'scholar_'
    | 'student_'
    | 'narr_student_'
    | 'narr_scholar_';

let exprotedWord: null | string = null;

export type WordConfig = {
    tashkeel: boolean;
    number: boolean;
    book: boolean;
    chapter: boolean;
    subChapter: boolean;
    volume: boolean;
    page: boolean;
    takhreej: boolean;
    tarqeem: boolean;
    rulings: boolean;
};

const ExportAggregation = z.object({
    buckets: z
        .object({
            key: z.string(),
            doc_count: z.number(),
        })
        .array(),
    sum_other_doc_count: z.number(),
});

const ResultsExport = z.object({
    hits: z.object({
        hits: z
            .object({
                _source: SearchPageHadith,
            })
            .array(),
    }),
});

const RoadsResultsExport = z.object({
    hits: z.object({
        hits: z
            .object({
                _source: RoadsHadith,
            })
            .array(),
    }),
});

const StudentScholarResultsExport = z.object({
    hits: z.object({
        hits: z
            .object({
                _source: z.object({
                    link: Link,
                    hadith: NarratorHadith,
                }),
            })
            .array(),
    }),
});

const ExportResponses = z.object({
    responses: z
        .tuple([
            ResultsExport,
            z.object({
                aggregations: z.object({
                    [NarrationsDataFields.BOOK_NAME]: ExportAggregation,
                }),
            }),
            z.object({
                aggregations: z.object({
                    [NarrationsDataFields.NARRATORS_FULL_NAMES]:
                        ExportAggregation,
                }),
            }),
            z.object({
                aggregations: z.object({
                    [NarrationsDataFields.CHAPTER]: ExportAggregation,
                }),
            }),
            z.object({
                aggregations: z.object({
                    [NarrationsDataFields.SUB_CHAPTER]: ExportAggregation,
                }),
            }),
            z.object({
                aggregations: z.object({
                    [NarrationsDataFields.TYPE]: ExportAggregation,
                }),
            }),
        ])
        .rest(z.any()),
});

const RoadsExportResponses = z.object({
    responses: z
        .tuple([
            RoadsResultsExport,
            z.object({
                aggregations: z.object({
                    [NarrationsDataFields.BOOK_NAME]: ExportAggregation,
                }),
            }),
            z.object({
                aggregations: z.object({
                    [NarrationsDataFields.NARRATORS_FULL_NAMES]:
                        ExportAggregation,
                }),
            }),
            z.object({
                aggregations: z.object({
                    [NarrationsDataFields.CHAPTER]: ExportAggregation,
                }),
            }),
            z.object({
                aggregations: z.object({
                    [NarrationsDataFields.SUB_CHAPTER]: ExportAggregation,
                }),
            }),
            z.object({
                aggregations: z.object({
                    [NarrationsDataFields.TYPE]: ExportAggregation,
                }),
            }),
        ])
        .rest(z.any()),
});

const ScholarExportResponses = z.object({
    responses: z
        .tuple([
            StudentScholarResultsExport,
            z.object({
                aggregations: z.object({
                    [NarrationsLinksDataFields.LINK_TGT_FULL_NAME]:
                        ExportAggregation,
                }),
            }),
            z.object({
                aggregations: z.object({
                    [NarrationsLinksDataFields.HADITH_BOOK_NAME]:
                        ExportAggregation,
                }),
            }),
            z.object({
                aggregations: z.object({
                    [NarrationsLinksDataFields.LINK_TGT_GRADE]:
                        ExportAggregation,
                }),
            }),
            z.object({
                aggregations: z.object({
                    [NarrationsLinksDataFields.LINK_VERB]: ExportAggregation,
                }),
            }),

            z.object({
                aggregations: z.object({
                    [NarrationsLinksDataFields.HADITH_TYPE]: ExportAggregation,
                }),
            }),
        ])
        .rest(z.any()),
});

const StudentExportResponses = z.object({
    responses: z
        .tuple([
            StudentScholarResultsExport,
            z.object({
                aggregations: z.object({
                    [NarrationsLinksDataFields.LINK_SRC_FULL_NAME]:
                        ExportAggregation,
                }),
            }),
            z.object({
                aggregations: z.object({
                    [NarrationsLinksDataFields.HADITH_BOOK_NAME]:
                        ExportAggregation,
                }),
            }),
            z.object({
                aggregations: z.object({
                    [NarrationsLinksDataFields.LINK_SRC_GRADE]:
                        ExportAggregation,
                }),
            }),
            z.object({
                aggregations: z.object({
                    [NarrationsLinksDataFields.LINK_VERB]: ExportAggregation,
                }),
            }),

            z.object({
                aggregations: z.object({
                    [NarrationsLinksDataFields.HADITH_TYPE]: ExportAggregation,
                }),
            }),
        ])
        .rest(z.any()),
});

function getStatsJsonBody(
    fullState: NextState,
    filters: `${ExportStatePrefix}${Filter}_filters`[],
) {
    const jsonBodies = filters.map((prefixedFilter) =>
        JSON.stringify({
            query: fullState[prefixedFilter].query,
            aggs: fullState[prefixedFilter].aggs,
            size: fullState[prefixedFilter].size,
        }),
    );

    const jsonBody = jsonBodies.reduce(
        (prev, curr, index) =>
            `${prev}{"preference":"${filters[index]}"}\n${curr}\n`,
        '',
    );

    return jsonBody;
}

type Buckets = { key: string; doc_count: number }[];

function enBucketsToArBuckets(buckets: Buckets) {
    return buckets.map(({ key, doc_count }) => ({
        الإسم: key,
        'عدد الأحاديث': doc_count,
    }));
}

function getHadithsJsonBody(fullState: NextState, prefix: ExportStatePrefix) {
    const jsonBody = JSON.stringify({
        query: fullState[`${prefix}result`].query,
        aggs: {},
        size: 200,
        from: 0,
        sort: fullState[`${prefix}result`].sort,
        // necessary to get the hadith, otherwise we'll get zod parsing error
        // since the hadith is actually extracted from the highlight object
        highlight: fullState[`${prefix}result`].highlight,
        _source: {
            includes: fullState[`${prefix}result`].includeFields,
            excludes: [],
        },
    });

    return `{"preference":"${prefix}result"}\n${jsonBody}\n`;
}

function fetchExportSheets(
    requestJsonl: string,
): Promise<[WorkSheet, string][]> {
    return fetch(`/api/reactivesearchproxy/${NARRATIONS_INDEX}/_msearch`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-ndjson',
        },
        body: requestJsonl,
    })
        .then((res) => res.json())
        .then((data) => {
            const {
                responses: [
                    {
                        hits: { hits },
                    },
                    {
                        aggregations: {
                            [NarrationsDataFields.BOOK_NAME]: {
                                buckets: bookBuckets,
                                sum_other_doc_count: bookOthers,
                            },
                        },
                    },
                    {
                        aggregations: {
                            [NarrationsDataFields.NARRATORS_FULL_NAMES]: {
                                buckets: narratorBuckets,
                                sum_other_doc_count: narratorOthers,
                            },
                        },
                    },
                    {
                        aggregations: {
                            [NarrationsDataFields.CHAPTER]: {
                                buckets: chapterBuckets,
                                sum_other_doc_count: chapterOthers,
                            },
                        },
                    },
                    {
                        aggregations: {
                            [NarrationsDataFields.SUB_CHAPTER]: {
                                buckets: subChapterBuckets,
                                sum_other_doc_count: subChaptherOthers,
                            },
                        },
                    },
                    {
                        aggregations: {
                            [NarrationsDataFields.TYPE]: {
                                buckets: typeBuckets,
                                sum_other_doc_count: typeOthers,
                            },
                        },
                    },
                ],
            } = ExportResponses.parse(data);

            const transformedHits = hits.map((hit) => ({
                المصنف: hit._source.book_name,
                'رقم الحديث': hit._source.number.join(', '),
                الصفحة: hit._source.page,
                الجزء: hit._source.volume,
                الكتاب: hit._source.chapter,
                الباب: hit._source.sub_chapter,
                الرواة: hit._source.narrators
                    .map((narr) => narr.full_name)
                    .join('\n'),
                الحديث: htmlStripped(hit._source.hadith),
                'الحديث بدون تشكيل': htmlStripped(
                    hit._source.hadith,
                ).replaceAll(/[\u064b-\u0652]/g, ''),
            }));

            const worksheetsWithTitles: [WorkSheet, string][] = [
                [utils.json_to_sheet(transformedHits), 'نتائج البحث'],
                // same order as displayed on the filters page
                [
                    utils.json_to_sheet(enBucketsToArBuckets(bookBuckets)),
                    'إحصائيات المصنفات',
                ],
                [
                    utils.json_to_sheet(enBucketsToArBuckets(narratorBuckets)),
                    'إحصائيات الرواة',
                ],
                [
                    utils.json_to_sheet(enBucketsToArBuckets(chapterBuckets)),
                    'إحصائيات الكتب',
                ],
                [
                    utils.json_to_sheet(
                        enBucketsToArBuckets(subChapterBuckets),
                    ),
                    'إحصائيات الأبواب',
                ],
                [
                    utils.json_to_sheet(enBucketsToArBuckets(typeBuckets)),
                    'إحصائيات أنواع الحديث',
                ],
            ];

            worksheetsWithTitles.forEach(([ws]) => {
                ws['!cols'] = [
                    { width: 15 },
                    { width: 10 },
                    { width: 5 },
                    { width: 5 },
                    { width: 10 },
                    { width: 25 },
                    { width: 25 },
                    { width: 75 },
                    { width: 75 },
                ];
            });

            return worksheetsWithTitles;
        });
}

function fetchExportDoc(requestJsonl: string) {
    return fetch(`/api/reactivesearchproxy/${NARRATIONS_INDEX}/_msearch`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-ndjson',
        },
        body: requestJsonl,
    })
        .then((res) => res.json())
        .then((data) => {
            const {
                responses: [
                    {
                        hits: { hits },
                    },
                ],
            } = z.object({ responses: ResultsExport.array() }).parse(data);

            return hits;
        });
}

function fetchRoadsExportSheets(
    requestJsonl: string,
): Promise<[WorkSheet, string][]> {
    return fetch(`/api/reactivesearchproxy/${NARRATIONS_INDEX}/_msearch`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-ndjson',
        },
        body: requestJsonl,
    })
        .then((res) => res.json())
        .then((data) => {
            const {
                responses: [
                    {
                        hits: { hits },
                    },
                    {
                        aggregations: {
                            [NarrationsDataFields.BOOK_NAME]: {
                                buckets: bookBuckets,
                            },
                        },
                    },
                    {
                        aggregations: {
                            [NarrationsDataFields.NARRATORS_FULL_NAMES]: {
                                buckets: narratorBuckets,
                            },
                        },
                    },
                    {
                        aggregations: {
                            [NarrationsDataFields.CHAPTER]: {
                                buckets: chapterBuckets,
                            },
                        },
                    },
                    {
                        aggregations: {
                            [NarrationsDataFields.SUB_CHAPTER]: {
                                buckets: subChapterBuckets,
                            },
                        },
                    },
                    {
                        aggregations: {
                            [NarrationsDataFields.TYPE]: {
                                buckets: typeBuckets,
                            },
                        },
                    },
                ],
            } = RoadsExportResponses.parse(data);

            const transformedHits = hits.map((hit) => ({
                المصنف: hit._source.book_name,
                'رقم الحديث': hit._source.number.join(', '),
                الصفحة: hit._source.page,
                الجزء: hit._source.volume,
                الكتاب: hit._source.chapter,
                الباب: hit._source.sub_chapter,
                الرواة: hit._source.narrators
                    .map((narr) => narr.full_name)
                    .join('\n'),
                المتن: hit._source.matn_with_tashkeel,
                'المتن بدون تشكيل': hit._source.matn_with_tashkeel.replaceAll(
                    /[\u064b-\u0652]/g,
                    '',
                ),
            }));

            const worksheetsWithTitles: [WorkSheet, string][] = [
                [utils.json_to_sheet(transformedHits), 'نتائج البحث'],
                // same order as displayed on the filters page
                [
                    utils.json_to_sheet(enBucketsToArBuckets(bookBuckets)),
                    'إحصائيات المصنفات',
                ],
                [
                    utils.json_to_sheet(enBucketsToArBuckets(narratorBuckets)),
                    'إحصائيات الرواة',
                ],
                [
                    utils.json_to_sheet(enBucketsToArBuckets(chapterBuckets)),
                    'إحصائيات الكتب',
                ],
                [
                    utils.json_to_sheet(
                        enBucketsToArBuckets(subChapterBuckets),
                    ),
                    'إحصائيات الأبواب',
                ],
                [
                    utils.json_to_sheet(enBucketsToArBuckets(typeBuckets)),
                    'إحصائيات أنواع الحديث',
                ],
            ];

            worksheetsWithTitles.forEach(([ws]) => {
                ws['!cols'] = [
                    { width: 15 },
                    { width: 10 },
                    { width: 5 },
                    { width: 5 },
                    { width: 10 },
                    { width: 25 },
                    { width: 25 },
                    { width: 75 },
                    { width: 75 },
                ];
            });

            return worksheetsWithTitles;
        });
}

function fetchRoadsExportDoc(requestJsonl: string) {
    return fetch(`/api/reactivesearchproxy/${NARRATIONS_INDEX}/_msearch`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-ndjson',
        },
        body: requestJsonl,
    })
        .then((res) => res.json())
        .then((data) => {
            const {
                responses: [
                    {
                        hits: { hits },
                    },
                ],
            } = z.object({ responses: RoadsResultsExport.array() }).parse(data);

            return hits;
        });
}

function fetchScholarExportSheets(
    requestJsonl: string,
): Promise<[WorkSheet, string][]> {
    return fetch(`/api/reactivesearchproxy/${CHAINS_LINK_INDEX}/_msearch`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-ndjson',
        },
        body: requestJsonl,
    })
        .then((res) => res.json())
        .then((data) => {
            const {
                responses: [
                    {
                        hits: { hits },
                    },
                    {
                        aggregations: {
                            [NarrationsLinksDataFields.LINK_TGT_FULL_NAME]: {
                                buckets: scholarBuckets,
                            },
                        },
                    },
                    {
                        aggregations: {
                            [NarrationsLinksDataFields.HADITH_BOOK_NAME]: {
                                buckets: bookBuckets,
                            },
                        },
                    },
                    {
                        aggregations: {
                            [NarrationsLinksDataFields.LINK_TGT_GRADE]: {
                                buckets: scholarGradeBuckets,
                            },
                        },
                    },
                    {
                        aggregations: {
                            [NarrationsLinksDataFields.LINK_VERB]: {
                                buckets: verbBuckets,
                            },
                        },
                    },
                    {
                        aggregations: {
                            [NarrationsLinksDataFields.HADITH_TYPE]: {
                                buckets: typeBuckets,
                            },
                        },
                    },
                ],
            } = ScholarExportResponses.parse(data);

            const transformedHits = hits.map((hit) => ({
                المصنف: hit._source.hadith.book_name,
                'رقم الحديث': hit._source.hadith.hadith_id,
                الصفحة: hit._source.hadith.page,
                الجزء: hit._source.hadith.volume,
                الكتاب: hit._source.hadith.chapter,
                الباب: hit._source.hadith.sub_chapter,
                الرواة: hit._source.hadith.narrators
                    .map((narr) => narr.full_name)
                    .join('\n'),
                // TODO[@chammaaomar]: use matn, not hadith, but it's not available
                الحديث: hit._source.hadith.hadith.replace(
                    /<\/?[^>]+(>|$)/g,
                    '',
                ),
                'الحديث بدون تشكيل': hit._source.hadith.hadith
                    .replace(/<\/?[^>]+(>|$)/g, '')
                    .replaceAll(/[\u064b-\u0652]/g, ''),
            }));

            const worksheetsWithTitles: [WorkSheet, string][] = [
                [utils.json_to_sheet(transformedHits), 'نتائج البحث'],
                // same order as displayed on the filters page
                [
                    utils.json_to_sheet(enBucketsToArBuckets(scholarBuckets)),
                    'إحصائيات الشيوخ',
                ],
                [
                    utils.json_to_sheet(enBucketsToArBuckets(bookBuckets)),
                    'إحصائيات المصنفات',
                ],
                [
                    utils.json_to_sheet(
                        enBucketsToArBuckets(scholarGradeBuckets),
                    ),
                    'إحصائيات درجات الشيوخ',
                ],
                [
                    utils.json_to_sheet(enBucketsToArBuckets(verbBuckets)),
                    'إحصائيات صيغ التحديث',
                ],
                [
                    utils.json_to_sheet(enBucketsToArBuckets(typeBuckets)),
                    'إحصائيات أنواع الحديث',
                ],
            ];

            worksheetsWithTitles.forEach(([ws]) => {
                ws['!cols'] = [
                    { width: 15 },
                    { width: 10 },
                    { width: 5 },
                    { width: 5 },
                    { width: 10 },
                    { width: 25 },
                    { width: 25 },
                    { width: 75 },
                    { width: 75 },
                ];
            });

            return worksheetsWithTitles;
        });
}

function fetchStudentExportSheets(
    requestJsonl: string,
): Promise<[WorkSheet, string][]> {
    return fetch(`/api/reactivesearchproxy/${CHAINS_LINK_INDEX}/_msearch`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-ndjson',
        },
        body: requestJsonl,
    })
        .then((res) => res.json())
        .then((data) => {
            const {
                responses: [
                    {
                        hits: { hits },
                    },
                    {
                        aggregations: {
                            [NarrationsLinksDataFields.LINK_SRC_FULL_NAME]: {
                                buckets: studentBuckets,
                            },
                        },
                    },
                    {
                        aggregations: {
                            [NarrationsLinksDataFields.HADITH_BOOK_NAME]: {
                                buckets: bookBuckets,
                            },
                        },
                    },
                    {
                        aggregations: {
                            [NarrationsLinksDataFields.LINK_SRC_GRADE]: {
                                buckets: studentGradeBuckets,
                            },
                        },
                    },
                    {
                        aggregations: {
                            [NarrationsLinksDataFields.LINK_VERB]: {
                                buckets: verbBuckets,
                            },
                        },
                    },
                    {
                        aggregations: {
                            [NarrationsLinksDataFields.HADITH_TYPE]: {
                                buckets: typeBuckets,
                            },
                        },
                    },
                ],
            } = StudentExportResponses.parse(data);

            const transformedHits = hits.map((hit) => ({
                المصنف: hit._source.hadith.book_name,
                'رقم الحديث': hit._source.hadith.hadith_id,
                الجزء: hit._source.hadith.volume,
                الصفحة: hit._source.hadith.page,
                الكتاب: hit._source.hadith.chapter,
                الباب: hit._source.hadith.sub_chapter,
                الرواة: hit._source.hadith.narrators.map(
                    (narr) => narr.full_name,
                ),
                // TODO[@chammaaomar]: use matn, not hadith, but it's not available
                الحديث: hit._source.hadith.hadith.replace(
                    /<\/?[^>]+(>|$)/g,
                    '',
                ),
                'الحديث بدون تشكيل': hit._source.hadith.hadith
                    .replace(/<\/?[^>]+(>|$)/g, '')
                    .replaceAll(/[\u064b-\u0652]/g, ''),
            }));

            const worksheetsWithTitles: [WorkSheet, string][] = [
                [utils.json_to_sheet(transformedHits), 'نتائج البحث'],
                // same order as displayed on the filters page
                [
                    utils.json_to_sheet(enBucketsToArBuckets(studentBuckets)),
                    'إحصائيات التلاميذ',
                ],
                [
                    utils.json_to_sheet(enBucketsToArBuckets(bookBuckets)),
                    'إحصائيات المصنفات',
                ],
                [
                    utils.json_to_sheet(
                        enBucketsToArBuckets(studentGradeBuckets),
                    ),
                    'إحصائيات درجات الطلاب',
                ],
                [
                    utils.json_to_sheet(enBucketsToArBuckets(verbBuckets)),
                    'إحصائيات صيغ التحديث',
                ],
                [
                    utils.json_to_sheet(enBucketsToArBuckets(typeBuckets)),
                    'إحصائيات أنواع الحديث',
                ],
            ];

            worksheetsWithTitles.forEach(([ws]) => {
                ws['!cols'] = [
                    { width: 15 },
                    { width: 10 },
                    { width: 5 },
                    { width: 5 },
                    { width: 10 },
                    { width: 25 },
                    { width: 25 },
                    { width: 75 },
                    { width: 75 },
                ];
            });

            return worksheetsWithTitles;
        });
}

function fetchStudentScholarExportDoc(requestJsonl: string) {
    return fetch(`/api/reactivesearchproxy/${CHAINS_LINK_INDEX}/_msearch`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-ndjson',
        },
        body: requestJsonl,
    })
        .then((res) => res.json())
        .then((data) => {
            const {
                responses: [
                    {
                        hits: { hits },
                    },
                ],
            } = z
                .object({ responses: StudentScholarResultsExport.array() })
                .parse(data);

            return hits;
        });
}

export function onExcelExport(fullState: NextState, prefix: ExportStatePrefix) {
    analytics('excel-export', { source: prefix === '' ? 'main' : prefix });
    const workbook = utils.book_new();
    const filters = fullState[`${prefix}result`].react.and.filter((dep) =>
        dep.endsWith('filters'),
    ) as `${ExportStatePrefix}${Filter}_filters`[];
    const statsRequestJsonl = getStatsJsonBody(fullState, filters);
    const resultsRequestJsonl = getHadithsJsonBody(fullState, prefix);
    const requestJsonl = `${resultsRequestJsonl}${statsRequestJsonl}`;
    if (prefix === '') {
        fetchExportSheets(requestJsonl).then((sheets) => {
            sheets.forEach(([sheet, sheetTitle]) => {
                utils.book_append_sheet(workbook, sheet, sheetTitle);
            });
            writeFile(workbook, 'نتائج_المنصة_الحديثية.xlsx');
        });
    } else if (prefix === 'roads_') {
        fetchRoadsExportSheets(requestJsonl).then((sheets) => {
            sheets.forEach(([sheet, sheetTitle]) => {
                utils.book_append_sheet(workbook, sheet, sheetTitle);
            });
            writeFile(workbook, 'نتائج_المنصة_الحديثية.xlsx');
        });
    } else if (prefix === 'scholar_' || prefix === 'narr_scholar_') {
        fetchScholarExportSheets(requestJsonl).then((sheets) => {
            sheets.forEach(([sheet, sheetTitle]) => {
                utils.book_append_sheet(workbook, sheet, sheetTitle);
            });
            writeFile(workbook, 'نتائج_المنصة_الحديثية.xlsx');
        });
    } else if (prefix === 'student_' || prefix === 'narr_student_') {
        fetchStudentExportSheets(requestJsonl).then((sheets) => {
            sheets.forEach(([sheet, sheetTitle]) => {
                utils.book_append_sheet(workbook, sheet, sheetTitle);
            });
            writeFile(workbook, 'نتائج_المنصة_الحديثية.xlsx');
        });
    }
}

const downloadDocFromHits = (
    hits: Pick<
        SearchPageHadith,
        | 'book_name'
        | 'hadith'
        | 'hadith_serial_id'
        | 'number'
        | 'volume'
        | 'page'
        | 'chapter'
        | 'sub_chapter'
        | 'narrations_numbers'
        | 'rulings'
    >[],
    config: WordConfig,
) => {
    const {
        book,
        number,
        volume,
        page,
        chapter,
        subChapter,
        tashkeel,
        takhreej,
        tarqeem,
        rulings,
    } = config;

    const formattedHadiths = hits.map((hit) =>
        [
            (book || number) &&
                new Paragraph({
                    text: getBookAndIdTextToggle(
                        book,
                        number,
                        hit.book_name,
                        hit.number[0],
                    ),
                    heading: HeadingLevel.HEADING_1,
                    alignment: AlignmentType.RIGHT,
                    spacing: {
                        before: 200,
                    },
                }),
            (volume || page) &&
                new Paragraph({
                    text: getVolumeAndPageText(
                        volume,
                        page,
                        hit.volume,
                        hit.page,
                    ),
                    heading: HeadingLevel.HEADING_2,
                    alignment: AlignmentType.RIGHT,
                }),
            (chapter || subChapter) &&
                new Paragraph({
                    text: getHadithSubtitle(
                        chapter ? hit.chapter : '',
                        subChapter ? hit.sub_chapter ?? '' : '',
                    ),
                    heading: HeadingLevel.HEADING_2,
                    alignment: AlignmentType.RIGHT,
                }),

            new Paragraph({
                text: getHadithWithTarqeemToggled(
                    tarqeem,
                    getHadithWithTashkeelToggled(
                        tashkeel,
                        htmlStripped(hit.hadith),
                    ),
                ),
                alignment: AlignmentType.RIGHT,
            }),
            ...(rulings
                ? hit.rulings.map((rul) => {
                      let ruling = `${rul.ruler}: ${rul.ruling}\n`;
                      if (rul.book_name && rul.page && rul.volume) {
                          ruling += `${rul.book_name}: (${rul.page}/${rul.volume})`;
                      }
                      return new Paragraph({
                          text: ruling,
                          alignment: AlignmentType.RIGHT,
                      });
                  })
                : []
            ).filter((paragraph): paragraph is Paragraph => !!paragraph),
            ...(hit.narrations_numbers && takhreej
                ? getTakhreej(
                      narrationsNumbersToRawNarrations(hit.narrations_numbers),
                  ).map(
                      (narr) =>
                          new Paragraph({
                              text: narr,
                              alignment: AlignmentType.RIGHT,
                          }),
                  )
                : []),
        ].filter((paragraph): paragraph is Paragraph => !!paragraph),
    );
    const doc = new Document({
        sections: [
            {
                properties: {},
                children: formattedHadiths.flat(),
            },
        ],
    });

    Packer.toBuffer(doc).then((buffer) => {
        const data = new Blob([buffer], {
            type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        });

        if (exprotedWord !== null) {
            window.URL.revokeObjectURL(exprotedWord);
        }

        exprotedWord = window.URL.createObjectURL(data);
        const link = document.createElement('a');
        link.setAttribute('download', 'نتائج_المنصة_الحديثية.docx');
        link.href = exprotedWord;
        document.body.appendChild(link);

        // wait for the link to be added to the document
        window.requestAnimationFrame(function () {
            var event = new MouseEvent('click');
            link.dispatchEvent(event);
            document.body.removeChild(link);
        });
    });
};

export function onWordExport(
    fullState: NextState,
    prefix: ExportStatePrefix,
    config: WordConfig,
) {
    analytics('word-export', { source: prefix === '' ? 'main' : prefix });
    const requestJsonl = getHadithsJsonBody(fullState, prefix);

    if (prefix === '') {
        fetchExportDoc(requestJsonl).then((hits) =>
            downloadDocFromHits(
                hits.map((hit) => hit._source),
                config,
            ),
        );
    } else if (prefix === 'roads_') {
        fetchRoadsExportDoc(requestJsonl).then((hits) =>
            downloadDocFromHits(
                hits.map((hit) => hit._source),
                config,
            ),
        );
    } else if (
        prefix === 'scholar_' ||
        prefix === 'narr_scholar_' ||
        prefix === 'student_' ||
        prefix === 'narr_student_'
    ) {
        fetchStudentScholarExportDoc(requestJsonl).then((hits) =>
            downloadDocFromHits(
                hits.map((hit) => ({
                    ...hit._source.hadith,
                    hadith: htmlStripped(hit._source.hadith.hadith),
                })),
                config,
            ),
        );
    }
}
