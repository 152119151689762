import FilterList from '@components/filters/FilterList';

import useTranslation from '@hooks/useTranslation';
import { Divider } from '@mui/material';

import { AmbiguousDataFields } from 'constants/datafields';
import { AmbiguousViewFilters } from 'constants/filters';

const AmbiguousFilters = ({
    setDrawerFiltersOpen,
}: {
    setDrawerFiltersOpen: (open: boolean) => void;
}) => {
    const { t } = useTranslation('library');

    return (
        <>
            <FilterList
                label={t('ambiguous_books')}
                componentId={AmbiguousViewFilters.AMBIGUOUS_BOOK}
                dataField={AmbiguousDataFields.BOOK_NAME}
                dependencies={{
                    and: [
                        AmbiguousViewFilters.AMBIGUOUS_AUTHOR,
                        AmbiguousViewFilters.AMBIGUOUS_ID,
                    ],
                }}
                setDrawerFiltersOpen={setDrawerFiltersOpen}
                showSelectAll={true}
                selectAllLabel={t('select_all')}
                statePrefix="ambg_"
            />
            <Divider sx={{ my: '10px' }} />

            <FilterList
                label={t('ambiguous_authors')}
                componentId={AmbiguousViewFilters.AMBIGUOUS_AUTHOR}
                dataField={AmbiguousDataFields.AUTHOR}
                dependencies={{
                    and: [
                        AmbiguousViewFilters.AMBIGUOUS_BOOK,
                        AmbiguousViewFilters.AMBIGUOUS_ID,
                    ],
                }}
                setDrawerFiltersOpen={setDrawerFiltersOpen}
                showSelectAll={true}
                selectAllLabel={t('select_all')}
                statePrefix="ambg_"
            />
        </>
    );
};

export default AmbiguousFilters;
