// sample hadith to be used in Word export config, since it contains
import { type SearchPageHadith } from 'shared/interfaces/hadith';

// all the export-able fields, such as rulings, chapter, subchapter, ... etc
export const sampleHadith: SearchPageHadith = {
    hadith_id: '195-1',
    hasExtendedExplanation: false,
    hasExtendedRuling: false,
    hadith_serial_id: 1,
    number: [1],
    book_name: 'جامع الترمذي',
    type: 'مرفوع',
    page: 5,
    volume: 1,
    editions: [],
    narrators: [
        {
            full_name: 'قتيبة بن سعيد',
            id: '6460',
            grade: 'ثقة ثبت',
            is_companion: false,
            is_unknown: false,
            reference: 'قُتَيْبَةُ بْنُ سَعِيدٍ ',
        },
        {
            full_name: 'الوضاح بن عبد الله اليشكري',
            id: '8153',
            grade: 'ثقة ثبت',
            is_companion: false,
            is_unknown: false,
            reference: 'أَبُو عَوَانَةَ ',
        },
        {
            full_name: 'سماك بن حرب البكري',
            id: '3638',
            grade: 'صدوق سيء الحفظ, تغير بآخره وروايته عن عكرمة مضطربة',
            is_companion: false,
            is_unknown: false,
            reference: 'سِمَاكِ بْنِ حَرْبٍ ',
        },
        {
            full_name: 'هناد بن السري',
            id: '8098',
            grade: 'ثقة',
            is_companion: false,
            is_unknown: false,
            reference: 'هَنَّادٌ ',
        },
        {
            full_name: 'وكيع بن الجراح',
            id: '8160',
            grade: 'ثقة حافظ إمام',
            is_companion: false,
            is_unknown: false,
            reference: 'وَكِيعٌ ',
        },
        {
            full_name: 'إسرائيل بن يونس بن أبي إسحاق',
            id: '982',
            grade: 'ثقة',
            is_companion: false,
            is_unknown: false,
            reference: 'إِسْرَائِيلَ ',
        },
        {
            full_name: 'سماك بن حرب البكري',
            id: '3638',
            grade: 'صدوق سيء الحفظ, تغير بآخره وروايته عن عكرمة مضطربة',
            is_companion: false,
            is_unknown: false,
            reference: 'سِمَاكٍ ',
        },
        {
            full_name: 'مصعب بن سعد بن أبي وقاص',
            id: '7514',
            grade: 'ثقة',
            is_companion: false,
            is_unknown: false,
            reference: 'مُصْعَبِ بْنِ سَعْدٍ ',
        },
        {
            full_name: 'عبد الله بن عمر بن الخطاب',
            id: '4967',
            grade: 'صحابي',
            is_companion: true,
            is_unknown: false,
            reference: 'ابْنِ عُمَرَ ',
        },
    ],
    extended_narrations: [],
    raw_narrations: [
        '158-334',
        '121-5267',
        '121-4560',
        '121-5058',
        '121-4823',
        '173-268',
        '121-4978',
        '195-1',
    ],
    narrations_numbers: [
        {
            narration_id: '158-334',
            number: ['334'],
        },
        {
            narration_id: '121-5267',
            number: ['5267'],
        },
        {
            narration_id: '121-4560',
            number: ['4560'],
        },
        {
            narration_id: '158-334',
            number: ['334'],
        },
        {
            narration_id: '121-4823',
            number: ['4823'],
        },
        {
            narration_id: '173-268',
            number: ['268'],
        },
        {
            narration_id: '121-4978',
            number: ['4978'],
        },
        {
            narration_id: '195-1',
            number: ['1'],
        },
    ],
    hasExplanation: true,
    hasCommentary: true,
    hasRuling: true,
    chapter: 'أبواب الطَّهَارَةِ',
    sub_chapter: 'بَاب مَا جَاءَ لَا تُقْبَلُ صَلَاةٌ بِغَيْرِ طُهُورٍ',
    matn_with_tashkeel: '',
    hadith: 'حَدَّثَنَا <a class=rawy id=6460>قُتَيْبَةُ بْنُ سَعِيدٍ </a> ، حَدَّثَنَا <a class=rawy id=8153>أَبُو عَوَانَةَ </a> ، عَنْ <a class=rawy id=3638>سِمَاكِ بْنِ حَرْبٍ </a> . ح وحَدَّثَنَا <a class=rawy id=8098>هَنَّادٌ </a> ، حَدَّثَنَا <a class=rawy id=8160>وَكِيعٌ </a> ، عَنْ <a class=rawy id=982>إِسْرَائِيلَ </a> ، عَنْ <a class=rawy id=3638>سِمَاكٍ </a> ، عَنْ <a class=rawy id=7514>مُصْعَبِ بْنِ سَعْدٍ </a> ، عَنْ <a class=rawy id=4967>ابْنِ عُمَرَ </a> ، عَنِ النَّبِيِّ صَلَّى اللَّهُ عَلَيْهِ وَسَلَّمَ ، قَالَ : <a class=matn>" <span class=\'keywords\'>لَا تُقْبَلُ صَلَاةٌ بِغَيْرِ طُهُورٍ وَلَا صَدَقَةٌ مِنْ غُلُولٍ</span> " </a> . قَالَ هَنَّادٌ فِي حَدِيثِهِ : " إِلَّا بِطُهُورٍ " . قَالَ أَبُو عِيسَى : هَذَا الْحَدِيثُ أَصَحُّ شَيْءٍ فِي هَذَا الْبَابِ وَأَحْسَنُ , وَفِي الْبَاب عَنْ أَبِي الْمَلِيحِ ، عَنْ أَبِيهِ , وَأَبِي هُرَيْرَةَ , وَأَنَسٍ , وَأَبُو الْمَلِيحِ بْنُ أُسَامَةَ اسْمُهُ عَامِرٌ ، وَيُقَالُ : زَيْدُ بْنُ أُسَامَةَ بْنِ عُمَيْرٍ الْهُذَلِيُّ .',
    _score: null,
    sort: [4, 1],
    rulings: [
        {
            ruler: 'الترمذي',
            ruling: 'أصح شيء في هذا الباب',
            ruler_dod: 297,
            book_name: 'جامع الترمذي',
            page: 5,
            volume: 1,
        },
        {
            ruler: 'الألباني',
            ruling: 'صحيح',
            ruler_dod: 1420,
            book_name: 'صحيح سنن الترمذي',
            page: 19,
            volume: 1,
        },
    ],
    ambiguous: [],
};
