import { useState } from 'react';

import SearchBase from '@components/base/SearchBase';
import ResultItemList from '@components/results/elements/ResultItemList';
import NarratorsBioResultItem from '@components/results/items/NarratorsBioResultItem';
import HadithSkeleton from '@components/skeletons/HadithSkeleton';
import MySnackbar from '@components/snackbar/Snackbar';

import { ReactiveComponent } from '@appbaseio/reactivesearch';
import { narratorsIndex } from '@config/reactive-search';
import { useCommonContext } from '@hooks/commonContext';
import useTranslation from '@hooks/useTranslation';
import { ReportGmailerrorred } from '@mui/icons-material';
import LinkIcon from '@mui/icons-material/Link';
import { Box, IconButton, useMediaQuery } from '@mui/material';

import { NarratorsDataFields } from 'constants/datafields';
import { NarratorBioViewFilters } from 'constants/filters';
import { NarratorBioSchema } from 'shared/interfaces/hadith';
import theme from 'theme';
import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';

const ResultItems = z.object({
    data: NarratorBioSchema.array(),
    loading: z.boolean(),
});

const NarratorBio = ({ narratorId }: { narratorId?: string }) => {
    const { setErrorData } = useCommonContext();
    const { t } = useTranslation('library');
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [showSnackbar, setShowSnackbar] = useState({
        message: '',
        show: false,
    });
    const copyURLToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(window.location.href);
            if (setShowSnackbar)
                setShowSnackbar({
                    message: t('url_copied'),
                    show: true,
                });
        } catch (err) {}
    };
    if (!narratorId) {
        return null;
    }

    return (
        <Box mt={2} display="flex" flexDirection="column" alignItems="flex-end">
            <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
            >
                <IconButton onClick={copyURLToClipboard}>
                    <LinkIcon
                        color="secondary"
                        fontSize="medium"
                        sx={{ rotate: '135deg' }}
                    />
                </IconButton>
                <IconButton
                    color="secondary"
                    onClick={() =>
                        setErrorData({
                            elem_id: narratorId,
                            type: 'narrator-bio',
                            source: 'user-report',
                        })
                    }
                >
                    <ReportGmailerrorred color="secondary" />
                </IconButton>
            </Box>
            <SearchBase app={narratorsIndex}>
                <ReactiveComponent
                    componentId={NarratorBioViewFilters.NARRATOR_ID}
                    customQuery={() => ({
                        query: {
                            match: {
                                [NarratorsDataFields.ID]: narratorId,
                            },
                        },
                    })}
                />
                <ResultItemList
                    componentId={NarratorBioViewFilters.RESULT}
                    dataField={NarratorsDataFields.ID}
                    includeFields={[
                        NarratorsDataFields.FULL_NAME,
                        NarratorsDataFields.EXTENDED_FULL_NAME,
                        NarratorsDataFields.NICKNAME,
                        NarratorsDataFields.ORIGIN,
                        NarratorsDataFields.LEVEL,
                        NarratorsDataFields.BORN_ON,
                        NarratorsDataFields.DIED_ON,
                        NarratorsDataFields.LIVED_IN,
                        NarratorsDataFields.DIED_IN,
                        NarratorsDataFields.GRADE,
                        NarratorsDataFields.BOOK_TITLES,
                        NarratorsDataFields.TOP_STUDENTS,
                        NarratorsDataFields.TOP_SCHOLARS,
                    ]}
                    dependencies={{
                        and: [NarratorBioViewFilters.NARRATOR_ID],
                    }}
                    resultItems={(items) => {
                        const { data, loading } = ResultItems.parse(items);
                        const narratorsBio = data.map((item) => (
                            <NarratorsBioResultItem
                                key={'narrBio_' + item._id}
                                item={item}
                            />
                        ));

                        if (loading) {
                            return (
                                <HadithSkeleton
                                    key={uuidv4()}
                                    withActions={false}
                                />
                            );
                        }

                        return narratorsBio;
                    }}
                    style={{
                        color: '#778899',
                        marginTop: 10,
                        width: '100%',
                    }}
                    loader={<HadithSkeleton withActions={false} />}
                    showResultStats={false}
                    showPagination={false}
                />
            </SearchBase>
            <MySnackbar
                anchorOrigin={{
                    vertical: isMobile ? 'top' : 'bottom',
                    horizontal: isMobile ? 'center' : 'right',
                }}
                message={showSnackbar.message}
                key="top-center"
                sx={{ mx: { xs: '20%', sm: 'unset' } }}
                open={showSnackbar.show}
                onClose={() => setShowSnackbar({ message: '', show: false })}
                autoHideDuration={3000}
            />
        </Box>
    );
};

export default NarratorBio;
