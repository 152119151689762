import { type ReactElement } from 'react';

import { Grid } from '@mui/material';

export function SecondLevelHeaderMobile({
    resultsStats,
    shareActions,
    sortComponent,
}: {
    resultsStats: ReactElement | null;
    shareActions: ReactElement | null;
    sortComponent: ReactElement | null;
}) {
    return (
        <Grid container rowSpacing={1}>
            {sortComponent && (
                <Grid item xs={12}>
                    {sortComponent}
                </Grid>
            )}
            {resultsStats && (
                <Grid item xs={6}>
                    {resultsStats}
                </Grid>
            )}
            {shareActions && (
                <Grid item xs={6}>
                    {shareActions}
                </Grid>
            )}
        </Grid>
    );
}
