import { useState } from 'react';

import { BookPreview } from '@components/bookpreview';
import { booksConfig } from '@components/bookpreview/bookConfigs';

import { useCommonContext } from '@hooks/commonContext';
import { useFontSize } from '@hooks/fontSizeContext';
import { useTashkeel } from '@hooks/tashkeelContext';
import useTranslation from '@hooks/useTranslation';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import PreviewIcon from '@mui/icons-material/Preview';
import ReportGmailerrorred from '@mui/icons-material/ReportGmailerrorred';
import {
    Box,
    Divider,
    IconButton,
    Paper,
    SvgIcon,
    Tooltip,
    Typography,
    styled,
} from '@mui/material';
import { removeTashkeel } from '@utils/tashkeel';
// @ts-ignore this library doesn't have exported types, nor is it part of the definitely typed project
import ReadMoreAndLess from '@vendor/react-read-more-less';

import { HadithExplanationTitleContainer } from '../HadithExplanationResultItem/styled';
import { HadithHeaderInfoDetails } from '../HadithResultItem/styled';
import { analytics } from 'lib/analytics';
import { BookReference } from 'shared/interfaces/hadith';
import theme, { colors } from 'theme';

const AmbiguoustemContainer = styled(Paper)(() => ({
    borderRadius: '10px',
}));

const AmbiguousResultItem = ({
    author,
    ambgId,
    book_name,
    explanation,
    page,
    volume,
    quickNavPoints,
    reportErrorId,
    hadithId,
}: {
    author: string;
    ambgId: string;
    book_name: string;
    explanation: string;
    page: number | null;
    volume: number | null;
    quickNavPoints: BookReference[];
    reportErrorId: string;
    hadithId: string;
}) => {
    const { t } = useTranslation('library');
    const { setErrorData } = useCommonContext();
    const { fontSize } = useFontSize();
    const { tashkeelEnabled } = useTashkeel();

    const pageVolumeTypeContents: string[] = [
        volume ? `${t('volume')}: ${volume}` : '',
        page ? `${t('page')}: ${page}` : '',
    ].filter((str) => str);

    const [scroll, setScroll] = useState(0);
    const [openPreviewModal, setOpenPreviewModal] = useState(false);

    const bookPreviewNA = !(booksConfig[book_name] && volume && page);

    const bookPreviewButton = (
        <IconButton
            onClick={() => {
                analytics('book-preview', {
                    type: 'ambiguous',
                    id: ambgId,
                    hadith_id: hadithId,
                    source: 'ambiguous-result-item',
                });
                setOpenPreviewModal(true);
            }}
            disabled={bookPreviewNA}
            color="secondary"
            sx={{
                flex: { xs: 1, sm: 'unset' },
            }}
        >
            <PreviewIcon />
        </IconButton>
    );

    return (
        <AmbiguoustemContainer
            elevation={0}
            variant="outlined"
            sx={{
                margin: { xs: '10px 0', md: '10px 0' },
                width: '100%',
                mt: 1,
            }}
        >
            <Box
                display={'flex'}
                alignItems={'flex-start'}
                justifyContent={'flex-end'}
                width={'100%'}
            >
                <HadithHeaderInfoDetails>
                    {pageVolumeTypeContents.map((content, i) => (
                        <Typography
                            key={content}
                            sx={{
                                padding: '5px',
                                borderLeft:
                                    i > 0
                                        ? `1px solid  ${theme.palette.divider}`
                                        : undefined,
                                height: '100%',
                                color: colors.primary,
                            }}
                        >
                            {content}
                        </Typography>
                    ))}
                </HadithHeaderInfoDetails>
            </Box>
            <HadithExplanationTitleContainer>
                <Typography fontSize={fontSize.title} fontWeight={500}>
                    {book_name}
                </Typography>
                <Typography
                    fontSize={fontSize.subtitle}
                    textAlign="start"
                    color="secondary"
                >
                    {author}
                </Typography>
            </HadithExplanationTitleContainer>
            <Divider sx={{ mt: 1 }} />
            <Typography
                textAlign="justify"
                variant="body1"
                fontSize={fontSize.body}
                sx={{
                    lineHeight: '36px',
                    px: 2,
                    pb: 1,
                    color: '#121212',
                    whiteSpace: 'pre-line', // Add this line to preserve newlines
                }}
                component="div"
            >
                <ReadMoreAndLess
                    className="read-more-content"
                    charLimit={400}
                    readMoreText={
                        <IconButton
                            onClick={() => {
                                // hadith explanation is only ever within the main modal, so it's safe
                                // to scroll using the main modal
                                const container =
                                    document.getElementById('main-modal');
                                if (!container) {
                                    return;
                                }

                                setScroll(container.scrollTop);
                            }}
                        >
                            <ExpandCircleDownIcon
                                color="secondary"
                                fontSize="small"
                            />
                        </IconButton>
                    }
                    readLessText={
                        <IconButton
                            onClick={() => {
                                // hadith explanation is only ever within the main modal, so it's safe
                                // to scroll using the main modal
                                const container =
                                    document.getElementById('main-modal');

                                if (!container) {
                                    return;
                                }

                                // scroll to old position
                                container.scrollTo({
                                    top: scroll,
                                });
                            }}
                        >
                            <ExpandCircleDownIcon
                                color="secondary"
                                sx={{ rotate: '180deg' }}
                            />
                        </IconButton>
                    }
                >
                    {tashkeelEnabled
                        ? explanation
                        : removeTashkeel(explanation)}
                </ReadMoreAndLess>
            </Typography>
            <Divider />
            <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={{ xs: 'space-between', sm: 'flex-end' }}
                position="relative"
            >
                {bookPreviewNA ? (
                    <Tooltip
                        placement="top"
                        title={
                            <Typography fontSize={12}>
                                {t('preview_not_clickable')}
                            </Typography>
                        }
                        enterTouchDelay={1}
                    >
                        {/* https://stackoverflow.com/a/75962378 disabled buttons don't emit hover events, so we wrap in a span */}
                        {/* the hover event is necessary to have the tooltip rendered */}
                        <span>{bookPreviewButton}</span>
                    </Tooltip>
                ) : (
                    bookPreviewButton
                )}
                <Box
                    sx={{
                        padding: '10px',
                        display: 'flex',
                        flex: { xs: 1, sm: 'unset' },
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderLeft: '1px solid lightgray',
                    }}
                    onClick={() => {
                        setErrorData({
                            elem_id: reportErrorId,
                            type: 'ambiguous',
                            source: 'user-report',
                        });
                    }}
                >
                    <SvgIcon
                        sx={{
                            cursor: 'pointer',
                        }}
                        component={ReportGmailerrorred}
                        color={'secondary'}
                    />
                </Box>
            </Box>
            {booksConfig[book_name] && volume && page && openPreviewModal && (
                <BookPreview
                    initNavPointId={book_name}
                    open={openPreviewModal}
                    setOpen={setOpenPreviewModal}
                    quickNavPoints={quickNavPoints}
                    // no editions yet
                    initEditionsRefs={[]}
                />
            )}
        </AmbiguoustemContainer>
    );
};

export default AmbiguousResultItem;
