import { type RefObject, useContext } from 'react';

import { useRouter } from 'next/router';

import { FilterListRef } from '@components/filters/FilterList/FilterList';

import { useFontSize } from '@hooks/fontSizeContext';
import { useTashkeel } from '@hooks/tashkeelContext';
import useTranslation from '@hooks/useTranslation';
import { Box, Button, Checkbox, Typography } from '@mui/material';
import { numberWithCommas } from '@utils/numbersDisplay';
import {
    removeQueryParamsFromRouter,
    updateQueryParamFromRouter,
} from '@utils/router';

import { LanguageContext } from '../../../../hooks/translationContext';
import { IDataListItem } from 'shared/interfaces/hadith';
import { colors } from 'theme';

interface IFilterItemProps {
    /**
     * @description Data to display in the list
     */
    listData: IDataListItem[];

    /**
     * @description MultiList component Id for ReactiveSearch
     */
    listId: string;

    /**
     * @description Function to call when data changes
     */
    handleChange: (key: string) => void;

    /**
     * @description MultiList ref
     */
    listRef: FilterListRef;

    /**
     * @description Allow select all for the list
     */
    showSelectAll?: boolean;

    /**
     * @description Label for select all option
     */
    selectAllLabel?: string;

    /**
     * @description Text to display when no results are found
     */
    noResultsText?: string;

    /**
     * @description has a search in parent component
     */
    showSearchMore?: boolean;

    /**
     * @description filter section name
     */
    filterName?: string;

    searchMoreMessage?: string;

    /**
     * @description input ref
     */
    inputRef?: RefObject<HTMLInputElement>;

    setTermBucketsLoading?: (x: boolean) => void;
    setLoadingCounts?: (loading: boolean) => void;
    showAggregateCounts?: boolean;
    setDrawerFiltersOpen: (open: boolean) => void;
}

const FilterItem = ({
    listId,
    listRef,
    listData,
    handleChange,
    showSelectAll = false,
    noResultsText = '',
    selectAllLabel = '',
    showSearchMore = false,
    filterName,
    searchMoreMessage,
    inputRef,
    setTermBucketsLoading,
    setLoadingCounts,
    showAggregateCounts = true,
    setDrawerFiltersOpen,
}: IFilterItemProps) => {
    const router = useRouter();
    const { language } = useContext(LanguageContext);
    const { t } = useTranslation('layout');
    const { fontSize } = useFontSize();

    const visibleOptions = listRef?.current?.state.options;
    const allSelected = visibleOptions?.every(
        ({ key }) => !!listRef?.current?.state?.currentValue[key],
    );

    const handleSelectAll = () => {
        if (setTermBucketsLoading) {
            setTermBucketsLoading(true);
        }
        if (setLoadingCounts) {
            setLoadingCounts(true);
        }
        setDrawerFiltersOpen(false);
        if (allSelected) {
            // redirect to the page without the filter
            removeQueryParamsFromRouter(router, [listId], false);
        } else {
            const options = listRef?.current?.state.options.reduce(
                (prev: string[], cur) => {
                    return [...prev, cur.key];
                },
                [],
            );
            // redirect to the page will all the options
            updateQueryParamFromRouter(router, listId, options);
        }
    };
    let counter = 0;
    return (
        <Box>
            {!listData?.length && (
                <Typography color="primary" variant="body2">
                    {noResultsText}
                </Typography>
            )}
            {listData?.length > 0 && (
                <>
                    {showSelectAll && (
                        <Box
                            onClick={handleSelectAll}
                            sx={{
                                width: '100%',
                                marginRight: '10',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                '&:hover': {
                                    backgroundColor: 'rgba(183, 164, 164, 0.1)',
                                    cursor: 'pointer',
                                },
                            }}
                        >
                            <Typography
                                color="primary"
                                variant="body1"
                                sx={{ fontWeight: 300, color: '#121212' }}
                            >
                                {selectAllLabel}
                            </Typography>
                            <Checkbox
                                size="small"
                                checked={allSelected}
                                name="selectAll"
                                color="secondary"
                            />
                        </Box>
                    )}
                    <ul
                        dir="rtl"
                        style={{
                            margin: 0,
                            height: 'fit-content',
                            maxHeight: '460px',
                            overflowY: 'auto',
                            padding: 0,
                        }}
                    >
                        {listData.map(({ key, doc_count, color }) => {
                            return (
                                <li
                                    key={key}
                                    style={{
                                        display: 'flex',
                                        justifyContent:
                                            language === 'ar' ? 'start' : 'end',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Box
                                        key={key}
                                        onClick={() => {
                                            if (setTermBucketsLoading) {
                                                setTermBucketsLoading(true);
                                            }
                                            if (setLoadingCounts) {
                                                setLoadingCounts(true);
                                            }
                                            handleChange(key);
                                            setDrawerFiltersOpen(false);
                                        }}
                                        dir={language === 'ar' ? 'rtl' : 'ltr'}
                                        component="span"
                                        sx={{
                                            width: '100%',
                                            marginRight: '10',
                                            display: 'flex',
                                            alignItems: 'center',
                                            '&:hover': {
                                                backgroundColor:
                                                    'rgba(183, 164, 164, 0.1)',
                                                cursor: 'pointer',
                                            },
                                        }}
                                    >
                                        <Checkbox
                                            size="small"
                                            value={key}
                                            checked={
                                                !!listRef?.current?.state
                                                    ?.currentValue[key]
                                            }
                                            color="secondary"
                                        />
                                        <Typography
                                            variant="body1"
                                            fontSize={fontSize.body}
                                            color={color || '#121212'}
                                            textAlign="start"
                                            sx={{
                                                backgroundColor: 'transparent',
                                                padding: '0px',
                                                fontWeight: 300,
                                            }}
                                        >
                                            {key.includes('/30') ||
                                            key.includes('/65')
                                                ? key.replace(
                                                      /\/(30|65)/g,
                                                      () => {
                                                          counter++;
                                                          return counter % 2 ===
                                                              1
                                                              ? '⟪⟪'
                                                              : '⟫⟫';
                                                      },
                                                  )
                                                : key.includes('/60')
                                                ? key.replace(/\/(60)/g, () => {
                                                      counter++;
                                                      return counter % 2 === 1
                                                          ? ''
                                                          : '';
                                                  })
                                                : key}
                                            {showAggregateCounts && (
                                                <Typography
                                                    textAlign="start"
                                                    component="span"
                                                    variant="body2"
                                                    color={colors.tertiary}
                                                    ml={1}
                                                >
                                                    (
                                                    {numberWithCommas(
                                                        doc_count,
                                                    )}
                                                    )
                                                </Typography>
                                            )}
                                        </Typography>
                                    </Box>
                                </li>
                            );
                        })}
                        {showSearchMore && (
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Button
                                    color="secondary"
                                    onClick={() => {
                                        inputRef && inputRef.current!.focus();
                                    }}
                                >
                                    {searchMoreMessage ||
                                        t('search_more') + ' ' + filterName}
                                </Button>
                            </div>
                        )}
                    </ul>
                </>
            )}
        </Box>
    );
};

export default FilterItem;
