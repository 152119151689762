import { Dispatch, SetStateAction } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Box, Typography } from '@mui/material';
import { numberWithCommas } from '@utils/numbersDisplay';

interface IFilterHeaderProps {
    /**
     * @description The title for the header
     */
    title: string;

    /**
     * @description Number of results found
     * if it's a number it's displayed, even if zero obviously
     * if it's the empty string it's not displayed at all
     */
    count: number | '';

    /**
     * @description whether the list is expanded or not
     * @default false
     */
    expanded?: boolean;

    /**
     * @description Function to update the expanded state
     * @param {v} v new expanded state
     */
    setExpanded: Dispatch<SetStateAction<boolean>>;
}

const FilterHeader = ({
    title,
    count,
    expanded = false,
    setExpanded,
}: IFilterHeaderProps) => {
    return (
        <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            pr={1}
            onClick={() => {
                setExpanded(!expanded);
            }}
            sx={{
                '&:hover': {
                    cursor: 'pointer',
                },
            }}
        >
            <Typography
                color="primary"
                variant="h6"
                fontWeight="500"
                fontSize={18}
            >
                {title}
            </Typography>
            <Box display="flex">
                <Typography color="primary">
                    {Number.isInteger(count)
                        ? `(${numberWithCommas(count.toString())})`
                        : ''}
                </Typography>
                {expanded && (
                    <ArrowDropDownIcon
                        color="primary"
                        onClick={() => {
                            setExpanded(false);
                        }}
                    />
                )}
                {!expanded && (
                    <ArrowDropUpIcon
                        color="primary"
                        onClick={() => {
                            setExpanded(true);
                        }}
                    />
                )}
            </Box>
        </Box>
    );
};

export default FilterHeader;
