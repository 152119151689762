import { Box, } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const ScrollSvg = (
    props: {
        onClickUp: () => void;
        onClickDown: () => void;
    },
) => (
    <Box sx={{
        display: "flex",
        justifyContent :"center",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        width: "25px",
        borderRadius: "25px",
        border: "1px solid #C7956D"
    }}>
        <ArrowDropUpIcon color='secondary' onClick={props.onClickUp} sx={{
            cursor: "pointer",
            fontSize: 45,
            height: "50%",
            width:"100%",
            borderTopLeftRadius: "25px",
            borderTopRightRadius: "25px",
            "&:hover":{
                backgroundColor: "#C7956D",
                color: "#fff"
            }
        }}/>
        <ArrowDropDownIcon color='secondary' onClick={props.onClickDown} fontSize={'large'} sx={{
            cursor: "pointer",
            fontSize: 45,
            height: "50%",
            width:"100%",
            borderBottomLeftRadius: "25px",
            borderBottomRightRadius: "25px",
            "&:hover":{
                backgroundColor: "#C7956D",
                color: "#fff"
            }
        }}/>
    </Box>
);

export default ScrollSvg;
