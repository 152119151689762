import { Box, Paper, styled } from '@mui/material';

export const HadithCommentaryContainer = styled(Paper)(() => ({
    borderRadius: '10px',
}));

export const HadithCommentaryHeaderInfo = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
}));

export const HadithCommentaryHeaderInfoDetails = styled(Box)(
    ({ theme: { palette } }) => ({
        display: 'flex',
        alignItems: 'center',
        width: 'max-content',

        borderBottomLeftRadius: '5px',
        borderBottom: `1px solid  ${palette.divider}`,
        borderLeft: `1px solid  ${palette.divider}`,
    }),
);

export const HadithCommentaryTitleContainer = styled(Box)(() => ({
    width: '100%',
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'right',
    flexDirection: 'column',
    marginTop: 15,
    paddingLeft: 10,
    marginBottom: 15,
}));

export const HadithCommentaryTextContainer = styled(Box)(() => ({
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'right',
    textAlign: 'justify',
}));

export const HadithCommentaryHeaderInfoNumber = styled(Box)(
    ({ theme: { palette } }) => ({
        display: 'flex',
        alignItems: 'center',
        width: 'max-content',
        padding: '5px',
        borderBottomRightRadius: '5px',
        borderBottom: `1px solid ${palette.divider}`,
        borderRight: `1px solid  ${palette.divider}`,
    }),
);
