import { useRef, useState } from 'react';

import { useRouter } from 'next/router';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';

import { CustomTooltip } from '@components/CustomTooltip';
import { NarrationsTypeToggle } from '@components/NarrationsTypeToggle';
import SearchBase from '@components/base/SearchBase';
import FiltersSelected from '@components/filters/elements/FiltersSelected';
import ResultItemList from '@components/results/elements/ResultItemList';

import Graphin from '@antv/graphin';
import { ReactiveComponent, StateProvider } from '@appbaseio/reactivesearch';
import { chainsGraphIndex } from '@config/reactive-search';
import { GraphDataFields } from '@constants/datafields';
import useTranslation from '@hooks/useTranslation';
import { ReactiveSearchLayout } from '@layout';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import {
    Alert,
    Box,
    Checkbox,
    FormControlLabel,
    IconButton,
    Paper,
    Skeleton,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { NarratorColor } from '@utils/narratorColor';
import { numberWithCommas } from '@utils/numbersDisplay';

import RoadsFilters from '../RoadsHadiths/RoadsFilters';
import {
    CustomLinkObject,
    CustomNodeObject,
    GraphResultItemsSchema,
    GraphinGraph,
    buildNarrationIdLinksMap,
    getCurvedLinks,
    getNodeColor,
    sortLinks,
} from './Graph';
import { makeLegend } from './Graph/Legend';
import { GraphCanvasContainer } from './styled';
import {
    RoadsHadithsGraphViewFilters,
    RoadsHadithsViewFilters,
} from 'constants/filters';
import fscreen from 'fscreen';
import {
    type NarrationsType,
    SearchPageHadith,
} from 'shared/interfaces/hadith';
import { checkFiltersSelected } from 'shared/methods';
import { z } from 'zod';

export type Rankdir = 'BT' | 'RL' | 'LR';

let globalLoading = false;
let timeoutId: NodeJS.Timeout;

const zIndexModal = 1300;

function LoadingSkeleton({
    showStats,
    isMobile,
}: {
    showStats: boolean;
    isMobile: boolean;
}) {
    const verbSkeletonWidth = isMobile ? '40%' : '25%';
    const actionsSkeletonWidth = isMobile ? '40%' : '25%';

    const statsSkeletonWidth = isMobile ? '25%' : '15%';
    return (
        <GraphCanvasContainer
            dir="rtl"
            id="graphin-roads-graph"
            sx={{
                mt: 1,
                width: '100%',
                // so that the properties modal contained in this doesn't get cut off
                // by the top bar on mobile
                zIndex: zIndexModal + 1,
            }}
        >
            {showStats && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    <Skeleton
                        animation="wave"
                        variant="text"
                        width={verbSkeletonWidth}
                    />
                    <Skeleton
                        animation="wave"
                        variant="text"
                        width={actionsSkeletonWidth}
                    />
                </Box>
            )}
            {showStats && (
                <Skeleton
                    animation="wave"
                    variant="text"
                    width={statsSkeletonWidth}
                />
            )}
            <Paper
                id="habox"
                sx={{
                    width: '100%',
                    height: 600,
                    border: 'none',
                }}
            >
                <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width="100%"
                    height={600}
                />
            </Paper>
        </GraphCanvasContainer>
    );
}

const NextState = z.object({
    result: z
        .object({
            aggregations: z
                .object({
                    unique_hadith_ids: z
                        .object({
                            buckets: z
                                .object({
                                    key: z.string(),
                                    doc_count: z.number(),
                                })
                                .array(),
                        })
                        .optional(),
                })
                .optional(),
        })
        .optional(),
});

const RoadsGraph = ({
    hadith,
    narrationsType,
    setNarrationsType,
    collapsed,
    setCollapsed,
}: {
    hadith: SearchPageHadith;
    narrationsType: NarrationsType;
    setNarrationsType: (x: NarrationsType) => void;
    collapsed: boolean;
    setCollapsed: (x: boolean) => void;
}) => {
    const { t } = useTranslation('library');
    const router = useRouter();

    const narrations =
        narrationsType === 'all'
            ? hadith.raw_narrations
                  .concat(hadith.extended_narrations)
                  .concat(hadith.hadith_id)
            : narrationsType === 'roads'
            ? hadith.raw_narrations.concat(hadith.hadith_id)
            : hadith.extended_narrations;

    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const defaultRankdir = isMobile ? 'RL' : 'BT';

    const [rankdir, setRankdir] = useState<Rankdir>(defaultRankdir);
    const [showVerbs, setShowVerbs] = useState(true);

    const [totalResults, setTotalResults] = useState(0);

    const fullScreenHandle = useFullScreenHandle();
    const containerHeight = 800;
    const [hasSelectedFilters, setHasSelectedFilters] = useState(false);
    const resultStatsLabel = hasSelectedFilters
        ? t('chains_count_with_filters')
        : t('chains_count');

    const resultStatsMessage = isMobile ? (
        <Typography variant="body1" color="primary">
            {`${t('chains_count')}: ${numberWithCommas(totalResults)}`}
        </Typography>
    ) : (
        <Typography variant="body1" color="primary">
            {`${resultStatsLabel}: ${numberWithCommas(totalResults)}`}
        </Typography>
    );

    const [fsTooltipOpen, setFsTooltipOpen] = useState(false);

    const fullScreenToggleButton = fullScreenHandle.active ? (
        <IconButton onClick={fullScreenHandle.exit}>
            <FullscreenExitIcon color="secondary" />
        </IconButton>
    ) : (
        <Box sx={{ position: 'relative' }}>
            <IconButton
                onClick={() => {
                    if (fscreen.fullscreenEnabled) {
                        fullScreenHandle.enter();
                    } else {
                        setFsTooltipOpen(true);
                        setTimeout(() => setFsTooltipOpen(false), 3000);
                    }
                }}
            >
                <FullscreenIcon
                    color={fscreen.fullscreenEnabled ? 'secondary' : 'disabled'}
                />
                <CustomTooltip
                    open={fsTooltipOpen}
                    message={t('full_screen_disabled')}
                    sx={{ position: 'absolute' }}
                />
            </IconButton>
        </Box>
    );

    const [narratorsColors, setNarratorsColors] = useState<NarratorColor[]>([]);
    const [filteredHadithIds, setFilteredHadithIds] = useState(narrations);

    const graphRef = useRef<Graphin>();

    const generateCanvasPng = () => {
        if (!graphRef.current) {
            return;
        }
        const { graph } = graphRef.current;

        const originalPixelRatio = window.devicePixelRatio;

        window.devicePixelRatio = 10;

        graph.downloadFullImage(
            `hadithplatform-${hadith.hadith_id}-graph`,
            'image/png',
            {
                backgroundColor: '#FFFFFF',
            },
        );

        window.devicePixelRatio = originalPixelRatio;
    };

    return (
        <FullScreen handle={fullScreenHandle}>
            <SearchBase
                app={chainsGraphIndex}
                setSearchParams={(newURL) => {
                    router.push(newURL, undefined, {
                        shallow: true,
                    });
                }}
            >
                <ReactiveComponent
                    componentId={RoadsHadithsViewFilters.HADITHS_IDS}
                    customQuery={() => ({
                        query: {
                            terms: {
                                [GraphDataFields.HADITH_ID]: narrations,
                            },
                        },
                    })}
                />
                <ReactiveSearchLayout
                    hasSelectedFilters={hasSelectedFilters}
                    filters={(setDrawerFiltersOpen) => (
                        <RoadsFilters
                            source="roads_graph"
                            narratorsColors={narratorsColors}
                            setNarratorsColors={setNarratorsColors}
                            setDrawerFiltersOpen={setDrawerFiltersOpen}
                        />
                    )}
                    collapsed={collapsed}
                    setCollapsed={setCollapsed}
                    fullScreenHandle={fullScreenHandle}
                    resultStatsMessage={resultStatsMessage}
                    results={(handleFilter) => (
                        <>
                            <FiltersSelected />
                            <Box>
                                <Alert
                                    severity="warning"
                                    sx={
                                        isMobile
                                            ? { textAlign: 'center' }
                                            : {
                                                  mt: 3,
                                                  width: 'fit-content',
                                              }
                                    }
                                >
                                    <span>
                                        {t('in_progress_roads_graph_feature')}
                                    </span>
                                </Alert>
                            </Box>
                            <Box
                                sx={
                                    isMobile
                                        ? {
                                              display: 'flex',
                                              justifyContent: 'center',
                                              mt: 1,
                                          }
                                        : {
                                              mt: 1,
                                              display: 'flex',
                                              justifyContent: 'start',
                                          }
                                }
                            >
                                <NarrationsTypeToggle
                                    narrationsType={narrationsType}
                                    setNarrationsType={(type) => {
                                        setNarrationsType(type);

                                        const newNarrations =
                                            type === 'all'
                                                ? hadith.raw_narrations
                                                      .concat(
                                                          hadith.extended_narrations,
                                                      )
                                                      .concat(hadith.hadith_id)
                                                : type === 'roads'
                                                ? hadith.raw_narrations.concat(
                                                      hadith.hadith_id,
                                                  )
                                                : hadith.extended_narrations;

                                        setFilteredHadithIds(newNarrations);
                                    }}
                                    disabledShawahed={
                                        hadith.extended_narrations.length === 0
                                    }
                                    disabledShawahedMessage={t(
                                        'shawahed_not_clickable',
                                    )}
                                />
                            </Box>
                            <ResultItemList
                                hasSelectedFilters={hasSelectedFilters}
                                resultStatsMessage={resultStatsMessage}
                                //It's highly unlikely we will have more than 500 TODO: enrich hadith with the actual sub-narration-account to do it accurately
                                size={500}
                                componentId={
                                    RoadsHadithsGraphViewFilters.RESULT
                                }
                                dataField={GraphDataFields.HADITH_ID}
                                onData={(data) =>
                                    setTotalResults(
                                        data.resultStats.numberOfResults,
                                    )
                                }
                                fullScreenHandle={fullScreenHandle}
                                dependencies={{
                                    and: [
                                        RoadsHadithsViewFilters.HADITHS_IDS,
                                        RoadsHadithsViewFilters.BOOKS_FILTERS,
                                        RoadsHadithsViewFilters.HADITH_TYPES_FILTERS,
                                        RoadsHadithsViewFilters.NARRATORS_FILTERS,
                                        RoadsHadithsViewFilters.CHAPTERS_FILTERS,
                                        RoadsHadithsViewFilters.SUB_CHAPTERS_FILTERS,
                                    ],
                                }}
                                defaultQuery={() => {
                                    return {
                                        track_total_hits: true,
                                        aggs: {
                                            unique_hadith_ids: {
                                                terms: {
                                                    field: GraphDataFields.HADITH_ID,
                                                    size: 500,
                                                },
                                            },
                                            unique_narrators: {
                                                cardinality: {
                                                    field: GraphDataFields.NARRATORS_FULL_NAMES,
                                                },
                                            },
                                            unique_chapters: {
                                                cardinality: {
                                                    field: GraphDataFields.CHAPTER,
                                                },
                                            },
                                            unique_sub_chapters: {
                                                cardinality: {
                                                    field: GraphDataFields.SUB_CHAPTER,
                                                },
                                            },
                                        },
                                    };
                                }}
                                includeFields={[
                                    GraphDataFields.HADITH_ID,
                                    GraphDataFields.NODES,
                                    GraphDataFields.LINKS,
                                    GraphDataFields.CHAINS_TEXT,
                                ]}
                                headerFirstLevel={
                                    <FormControlLabel
                                        label={t('toggle_verb')}
                                        sx={{ color: 'primary.main' }}
                                        control={
                                            <Checkbox
                                                color="secondary"
                                                checked={showVerbs}
                                                onChange={(event) => {
                                                    setShowVerbs(
                                                        event.target.checked,
                                                    );
                                                    if (graphRef.current) {
                                                        graphRef.current.graph.refresh();
                                                    }
                                                }}
                                            />
                                        }
                                    />
                                }
                                resultItems={(
                                    items: GraphResultItemsSchema,
                                ) => {
                                    const { data, loading } =
                                        GraphResultItemsSchema.parse(items);

                                    if (loading && !globalLoading) {
                                        // only set it first time
                                        globalLoading = true;
                                        timeoutId = setTimeout(() => {
                                            if (globalLoading) {
                                                router.replace({
                                                    query: window.location.search.substring(
                                                        1,
                                                    ),
                                                });
                                            }
                                        }, 4000);
                                    }

                                    if (loading) {
                                        return (
                                            <LoadingSkeleton
                                                showStats={data.length === 0}
                                                isMobile={isMobile}
                                            />
                                        );
                                    }

                                    // no loading
                                    globalLoading = false;
                                    clearTimeout(timeoutId);

                                    if (filteredHadithIds && data.length) {
                                        const nodesMap = data.reduce(
                                            (rNodes, d) => {
                                                d.nodes.forEach((node) => {
                                                    if (
                                                        node.id &&
                                                        !(node.id in rNodes)
                                                    ) {
                                                        rNodes[node.id] = {
                                                            ...node,
                                                            color: getNodeColor(
                                                                node.node_type,
                                                                node.is_companion,
                                                            ),
                                                        };
                                                    }
                                                });
                                                return rNodes;
                                            },
                                            {} as Record<
                                                string,
                                                CustomNodeObject
                                            >,
                                        );

                                        const nodes = Object.values(nodesMap);

                                        const edges = data.reduce(
                                            (arrLinks, d) => {
                                                return arrLinks.concat(
                                                    d.links.map((link) => ({
                                                        ...link,
                                                        linkWidth: 3,
                                                        arrowLength: 15,
                                                    })),
                                                );
                                            },
                                            [] as CustomLinkObject[],
                                        );

                                        const narrationIdLinksMap =
                                            buildNarrationIdLinksMap(
                                                edges as CustomLinkObject[],
                                            );

                                        const filteredLinks: CustomLinkObject[] =
                                            Object.entries(
                                                narrationIdLinksMap,
                                            ).reduce(
                                                (
                                                    allEdges: CustomLinkObject[],
                                                    [key, edges],
                                                ) => {
                                                    if (
                                                        filteredHadithIds.includes(
                                                            key,
                                                        )
                                                    )
                                                        return allEdges.concat(
                                                            edges,
                                                        );
                                                    return allEdges;
                                                },
                                                [],
                                            );
                                        const curvedLinks =
                                            getCurvedLinks(filteredLinks);

                                        const filteredNodesMap: Record<
                                            string,
                                            CustomNodeObject | string | number
                                        > = filteredLinks.reduce(
                                            (
                                                map: Record<
                                                    string,
                                                    | CustomNodeObject
                                                    | string
                                                    | number
                                                >,
                                                link,
                                            ) => {
                                                const source = link.source;
                                                const target = link.target;

                                                if (!(`${source}` in map)) {
                                                    map[`${source}`] = source;
                                                }

                                                if (!(`${target}` in map)) {
                                                    map[`${target}`] = target;
                                                }
                                                return map;
                                            },
                                            {},
                                        );

                                        const filteredNodes = nodes.filter(
                                            (node) => {
                                                if (
                                                    node.id &&
                                                    node.id in filteredNodesMap
                                                )
                                                    return node;
                                            },
                                        );

                                        //     size: number;
                                        // /** 填充色 */
                                        // fill: string;
                                        // /** 包围边颜色 */
                                        // stroke: string;
                                        // /** 边框的宽度 */
                                        // lineWidth: number;

                                        const filteredData = {
                                            edges: sortLinks(curvedLinks),
                                            nodes: filteredNodes,
                                        };

                                        const legendItems =
                                            makeLegend(filteredData);

                                        return (
                                            <GraphCanvasContainer
                                                dir="rtl"
                                                id="graphin-roads-graph"
                                                sx={{
                                                    mt: 1,
                                                    width: '100%',
                                                }}
                                            >
                                                <Paper
                                                    id="habox"
                                                    sx={{
                                                        width: '100%',
                                                        height: containerHeight,
                                                        border: 'none',
                                                    }}
                                                >
                                                    <GraphinGraph
                                                        data={filteredData}
                                                        rankdir={rankdir}
                                                        setRankdir={setRankdir}
                                                        fullScreenToggleButton={
                                                            fullScreenToggleButton
                                                        }
                                                        legendItems={
                                                            legendItems
                                                        }
                                                        generateCanvasPng={
                                                            generateCanvasPng
                                                        }
                                                        fullScreen={
                                                            fullScreenHandle.active
                                                        }
                                                        externalGraphRef={
                                                            graphRef
                                                        }
                                                        key={`graphin-nodes${filteredData.nodes.length}-rankdir${rankdir}`}
                                                        showVerbs={showVerbs}
                                                        mainNarrationId={
                                                            hadith.hadith_id
                                                        }
                                                    />
                                                </Paper>
                                            </GraphCanvasContainer>
                                        );
                                    }

                                    return <></>;
                                }}
                                // to turn off the default "loading..." loader
                                // if you put a nullish string, such as '' or null
                                // it still shows up
                                loader={' '}
                                showPagination={false}
                                onFilterClick={handleFilter}
                            />
                            <></>

                            <StateProvider
                                //Counts coming from aggregations query
                                onChange={(state, nextState) => {
                                    checkFiltersSelected(
                                        { ...state, ...nextState },
                                        hasSelectedFilters,
                                        setHasSelectedFilters,
                                        'roads_',
                                    );
                                    const parsedNextState =
                                        NextState.parse(nextState);
                                    if (parsedNextState.result?.aggregations) {
                                        //Aggregate hadithIds
                                        const hadithsIdsArray =
                                            parsedNextState.result.aggregations.unique_hadith_ids?.buckets.map(
                                                (bucket) => bucket.key,
                                            );
                                        if (hadithsIdsArray) {
                                            setFilteredHadithIds(
                                                hadithsIdsArray,
                                            );
                                        }
                                    }
                                }}
                            />
                        </>
                    )}
                />
            </SearchBase>
        </FullScreen>
    );
};

export default RoadsGraph;
