import { Tab, type Theme, styled } from '@mui/material';

import { colors } from 'theme';

export const HadithViewsTab = styled(Tab)(({ theme }: { theme: Theme }) => ({
    marginLeft: '5px',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    borderTop: `1px solid ${theme.palette.divider}`,
    borderLeft: `1px solid ${theme.palette.divider}`,
    borderRight: `1px solid ${theme.palette.divider}`,
    color: '#121212',
    '&.Mui-selected': {
        backgroundColor: theme.palette.secondary.main,
    },
}));
