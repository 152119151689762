import FilterList from '@components/filters/FilterList';

import { ReactiveComponent } from '@appbaseio/reactivesearch';
import { ROUTING } from '@hooks/useParams';
import useTranslation from '@hooks/useTranslation';
import { Button, Divider } from '@mui/material';
import {
    NarratorColor as ScholarColor,
    handleNarratorColorChange as handleScholarColorChange,
} from '@utils/narratorColor';

import { NarrationsLinksDataFields } from 'constants/datafields';
import {
    HadithNarratorScholarsViewFilters,
    NarratorScholarsViewFilters,
} from 'constants/filters';
import { StatePrefix } from 'shared/interfaces/hadith';

interface INarratorScholarsFiltersProps {
    scholarsColors: ScholarColor[];
    setScholarsColors: (colors: ScholarColor[]) => void;
    routing?: ROUTING;
    setDrawerFiltersOpen: (open: boolean) => void;
}

const NarratorScholarsFilters = ({
    scholarsColors,
    setScholarsColors,
    routing = ROUTING.HADITH_PAGE_NARRATOR,
    setDrawerFiltersOpen,
}: INarratorScholarsFiltersProps) => {
    const { t } = useTranslation('library');

    const ScholarsViewFilters:
        | typeof NarratorScholarsViewFilters
        | typeof HadithNarratorScholarsViewFilters =
        routing === ROUTING.NARRATOR_PAGE
            ? NarratorScholarsViewFilters
            : HadithNarratorScholarsViewFilters;

    const statePrefix: StatePrefix =
        routing === ROUTING.NARRATOR_PAGE ? 'narr_scholar_' : 'scholar_';

    return (
        <>
            <Divider sx={{ my: '10px' }} />

            <ReactiveComponent
                componentId={ScholarsViewFilters.SINGULARITY_TOGGLE}
                URLParams
                showFilter
                react={{
                    and: [
                        ScholarsViewFilters.NARRATOR_ID,
                        ScholarsViewFilters.HADITH_VERB_FILTERS,
                        ScholarsViewFilters.GRADES_FILTERS,
                        ScholarsViewFilters.SCHOLARS_FILTERS,
                        ScholarsViewFilters.BOOKS_FILTERS,
                        ScholarsViewFilters.TYPES_FILTERS,
                    ],
                }}
                customQuery={(value) => ({
                    query: {
                        term: {
                            [NarrationsLinksDataFields.LINK_IS_SINGULAR]:
                                value || false,
                        },
                    },
                })}
                render={({ value, setQuery }) => (
                    <Button
                        onClick={() => {
                            setQuery({
                                query: {
                                    term: {
                                        [NarrationsLinksDataFields.LINK_IS_SINGULAR]:
                                            !value,
                                    },
                                },
                                value: !value,
                            });
                        }}
                        sx={{
                            color: value ? '#fff' : '#000',
                            backgroundColor: value ? '#C7956D' : '#ddd',
                            paddingY: '8px',
                            paddingX: '16px',
                            ':hover': {
                                cursor: 'pointer',
                                // don't change bg color on hover
                                backgroundColor: value ? '#C7956D' : '#ddd',
                            },
                            borderRadius: '5px',
                            mb: '10px',
                        }}
                    >
                        {t('scholar_singularity_toggle_label')}
                    </Button>
                )}
                filterLabel={t('scholar_singularity_toggle_label')}
            />

            {/* Search By Scholars */}
            <FilterList
                label={t('scholars_filter')}
                statePrefix={statePrefix}
                countAggregateIdentifier={'unique_scholars'}
                dataField={NarrationsLinksDataFields.LINK_TGT_FULL_NAME}
                componentId={ScholarsViewFilters.SCHOLARS_FILTERS}
                setDrawerFiltersOpen={setDrawerFiltersOpen}
                dependencies={{
                    and: [
                        ScholarsViewFilters.NARRATOR_ID,
                        ScholarsViewFilters.BOOKS_FILTERS,
                        ScholarsViewFilters.HADITH_VERB_FILTERS,
                        ScholarsViewFilters.GRADES_FILTERS,
                        ScholarsViewFilters.TYPES_FILTERS,
                        ScholarsViewFilters.SINGULARITY_TOGGLE,
                    ],
                }}
                searchType="server"
                searchComponentId={ScholarsViewFilters.SCHOLARS_SEARCH}
                searchField={
                    NarrationsLinksDataFields.LINK_TGT_FULL_NAME_NORMALIZED
                }
                searchPlaceholder={t('search_for_a_narrator')}
                searchMoreMessage={t('narrator_search_more')}
                size={30}
                coloredItems={scholarsColors}
                setColoredItems={(newList) =>
                    handleScholarColorChange(
                        newList,
                        scholarsColors,
                        setScholarsColors,
                    )
                }
            />
            <Divider sx={{ my: '10px' }} />
            {/* Search By Books */}
            <FilterList
                label={t('books')}
                statePrefix={statePrefix}
                componentId={ScholarsViewFilters.BOOKS_FILTERS}
                dataField={NarrationsLinksDataFields.HADITH_BOOK_NAME}
                setDrawerFiltersOpen={setDrawerFiltersOpen}
                dependencies={{
                    and: [
                        ScholarsViewFilters.HADITH_VERB_FILTERS,
                        ScholarsViewFilters.GRADES_FILTERS,
                        ScholarsViewFilters.NARRATOR_ID,
                        ScholarsViewFilters.SCHOLARS_FILTERS,
                        ScholarsViewFilters.TYPES_FILTERS,
                        ScholarsViewFilters.SINGULARITY_TOGGLE,
                    ],
                }}
                size={30}
            />
            <Divider sx={{ my: '10px' }} />
            {/* Search By Narrator Grade */}
            <FilterList
                label={t('scholar_grade')}
                statePrefix={statePrefix}
                countAggregateIdentifier={'unique_grades'}
                dataField={NarrationsLinksDataFields.LINK_TGT_GRADE}
                componentId={ScholarsViewFilters.GRADES_FILTERS}
                setDrawerFiltersOpen={setDrawerFiltersOpen}
                dependencies={{
                    and: [
                        ScholarsViewFilters.NARRATOR_ID,
                        ScholarsViewFilters.HADITH_VERB_FILTERS,
                        ScholarsViewFilters.BOOKS_FILTERS,
                        ScholarsViewFilters.SCHOLARS_FILTERS,
                        ScholarsViewFilters.TYPES_FILTERS,
                        ScholarsViewFilters.SINGULARITY_TOGGLE,
                    ],
                }}
                searchMoreMessage={t('scholar_grade_search_more')}
                searchType="server"
                searchComponentId={ScholarsViewFilters.GRADES_SEARCH}
                searchField={
                    NarrationsLinksDataFields.LINK_TGT_GRADE_NORMALIZED
                }
                searchPlaceholder={t('search_for_a_grade')}
                size={20}
            />
            <Divider sx={{ my: '10px' }} />

            {/* Search By Narration verb */}
            <FilterList
                label={t('hadith_delivering_verb')}
                statePrefix={statePrefix}
                countAggregateIdentifier={'unique_verbs'}
                componentId={ScholarsViewFilters.HADITH_VERB_FILTERS}
                dataField={NarrationsLinksDataFields.LINK_VERB}
                setDrawerFiltersOpen={setDrawerFiltersOpen}
                dependencies={{
                    and: [
                        ScholarsViewFilters.GRADES_FILTERS,
                        ScholarsViewFilters.BOOKS_FILTERS,
                        ScholarsViewFilters.NARRATOR_ID,
                        ScholarsViewFilters.SCHOLARS_FILTERS,
                        ScholarsViewFilters.TYPES_FILTERS,
                        ScholarsViewFilters.SINGULARITY_TOGGLE,
                    ],
                }}
                searchType="server"
                searchComponentId={ScholarsViewFilters.VERBS_SEARCH}
                searchField={NarrationsLinksDataFields.LINK_VERB_NORMALIZED}
                searchPlaceholder={t('search_for_a_verb')}
                size={20}
            />

            <Divider sx={{ my: '10px' }} />
            {/* Search By Type */}
            <FilterList
                label={t('hadith_type')}
                statePrefix={statePrefix}
                componentId={ScholarsViewFilters.TYPES_FILTERS}
                dataField={NarrationsLinksDataFields.HADITH_TYPE}
                setDrawerFiltersOpen={setDrawerFiltersOpen}
                dependencies={{
                    and: [
                        ScholarsViewFilters.NARRATOR_ID,
                        ScholarsViewFilters.HADITH_VERB_FILTERS,
                        ScholarsViewFilters.GRADES_FILTERS,
                        ScholarsViewFilters.SCHOLARS_FILTERS,
                        ScholarsViewFilters.BOOKS_FILTERS,
                        ScholarsViewFilters.SINGULARITY_TOGGLE,
                    ],
                }}
            />
        </>
    );
};

export default NarratorScholarsFilters;
