import { useState } from 'react';

import { useRouter } from 'next/router';

import { NarrationsTypeToggle } from '@components/NarrationsTypeToggle';
import SearchBase from '@components/base/SearchBase';
import FiltersSelected from '@components/filters/elements/FiltersSelected';
import { MoreResultsNotice } from '@components/results/elements/MoreResultsNotice';
import ResultItemList from '@components/results/elements/ResultItemList';
import HadithCommentaryResultItem from '@components/results/items/HadithCommentaryResultItem';
import HadithResultItem from '@components/results/items/HadithResultItem';
import HadithSkeleton from '@components/skeletons/HadithSkeleton';
import type { MySnackbarProps } from '@components/snackbar/Snackbar';

import { ReactiveComponent, StateProvider } from '@appbaseio/reactivesearch';
import { hadithCommentaryIndex } from '@config/reactive-search';
import useTranslation from '@hooks/useTranslation';
import { ReactiveSearchLayout } from '@layout';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { numberWithCommas } from '@utils/numbersDisplay';

import HadithCommentaryFilters from './HadithCommentaryFilters';
import { HadithCommentaryDataFields } from 'constants/datafields';
import { HadithCommentaryViewFilters } from 'constants/filters';
import {
    BookReference,
    Commentary,
    CommentaryHadith,
    EditionRefs,
    HadithNumbers,
    NarrationsType,
    NextState,
    SearchPageHadith,
    appendPrimaryEditionToEditions,
} from 'shared/interfaces/hadith';
import { checkFiltersSelected } from 'shared/methods';
import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';

const ResultItems = z.object({
    data: z.array(
        z.object({
            commentary: Commentary,
            hadith: CommentaryHadith,
            type: z.enum(['embedded', 'external']),
            _id: z.string(),
        }),
    ),
    loading: z.boolean(),
});

interface HadithCommentaryViewProps {
    /**
     * @description hadith
     */
    hadith: SearchPageHadith;
    setShowSnackbar: (x: MySnackbarProps) => void;
    narrationsType: NarrationsType;
    setNarrationsType: (x: NarrationsType) => void;
}

const size = 10;

const CommentariesReferences = z.object({
    hits: z.object({
        hits: z
            .object({
                _source: z.object({
                    type: z.enum(['embedded', 'external']),
                    hadith: z.object({
                        book_name: z.string(),
                        page: z.number(),
                        hadith_id: z.string(),
                        volume: z.number(),
                        number: HadithNumbers,
                        editions: EditionRefs,
                    }),
                    commentary: z.object({
                        id: z
                            .string()
                            .or(z.number().transform((num) => `${num}`)),
                        book_name: z.string(),
                        page: z.number(),
                        volume: z.number(),
                    }),
                    serial_id: z.number(),
                }),
            })
            .transform((obj) => {
                appendPrimaryEditionToEditions(obj._source.hadith);
                return obj;
            })
            .array(),
    }),
});

function getCommentariesReferences(
    fullSearchState: NextState,
    commentariesCount: number,
): Promise<BookReference[]> {
    const jsonBody = JSON.stringify({
        query: fullSearchState['comm_result'].query,
        aggs: {},
        size: commentariesCount,
        from: 0,
        _source: {
            includes: [
                HadithCommentaryDataFields.TYPE,
                HadithCommentaryDataFields.HADITH_BOOK_NAME,
                HadithCommentaryDataFields.HADITH_VOLUME,
                HadithCommentaryDataFields.HADITH_HADITH_ID,
                HadithCommentaryDataFields.HADITH_PAGE,
                HadithCommentaryDataFields.HADITH_EDITIONS,
                HadithCommentaryDataFields.COMMENTARY_BOOK_NAME,
                HadithCommentaryDataFields.COMMENTARY_VOLUME,
                HadithCommentaryDataFields.COMMENTARY_PAGE,
                HadithCommentaryDataFields.HADITH_NUMBER,
                HadithCommentaryDataFields.COMMENTARY_ID,
                HadithCommentaryDataFields.SERIAL_ID,
            ],
            excludes: [],
        },
    });

    return fetch(`/api/reactivesearchproxy/${hadithCommentaryIndex}/_search`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: jsonBody,
    })
        .then((res) => res.json())
        .then((json) => {
            const {
                hits: { hits },
            } = CommentariesReferences.parse(json);

            const commentariesReferences: BookReference[] = [];

            hits.forEach(({ _source: commentaryItem }) => {
                if (commentaryItem.type === 'embedded') {
                    const hadithNumber =
                        commentaryItem.hadith.number.join(', ');
                    commentariesReferences.push({
                        ...commentaryItem.hadith,
                        // this ends up being used in the book preview inside the <HadithResultItem>
                        // so this id must match the initNavPointId prop in the <BookPreview> inside <HadithResultItem>
                        id: commentaryItem.hadith.hadith_id,
                        label: `${commentaryItem.hadith.book_name} - ${hadithNumber}`,
                        editionsRefs: [],
                        group: commentaryItem.hadith.book_name,
                    });
                } else {
                    const label = `${commentaryItem.commentary.book_name} - ${commentaryItem.commentary.id}`;
                    commentariesReferences.push({
                        // this ends up being used in the book preview inside the <HadithCommentaryResultItem>
                        // so the ID here must match the initNavPointId prop in the <BookPreview> inside <HadithCommentaryResultItem>
                        ...commentaryItem.commentary,
                        label,
                        editionsRefs: [],
                        group: commentaryItem.commentary.book_name,
                    });
                }
            });

            return commentariesReferences.sort((a, b) => {
                const aGroup = a.group as string;
                const bGroup = b.group as string;
                // MUST SORT ON .group first! then sort on whichever other criterion, so that grouped books actually
                // end up continguous in the list
                if (aGroup > bGroup) {
                    return 1;
                } else if (bGroup > aGroup) {
                    return -1;
                } else {
                    // same book
                    const aId = Number.isInteger(parseInt(a.id, 10))
                        ? parseInt(a.id, 10)
                        : a.id;
                    const bId = Number.isInteger(parseInt(b.id, 10))
                        ? parseInt(b.id, 10)
                        : b.id;
                    return aId < bId ? -1 : 1;
                }
            });
        });
}

const HadithCommentaryView = ({
    hadith,
    setShowSnackbar,
    narrationsType,
    setNarrationsType,
}: HadithCommentaryViewProps) => {
    // const scrollToTopMin =
    //     typeof window !== 'undefined' ? window.innerHeight * 3 : undefined;
    const { t } = useTranslation('library');
    const router = useRouter();
    const [hasSelectedFilters, setHasSelectedFilters] = useState(false);

    const [totalResults, setTotalResults] = useState(0);
    const [commentariesReferences, setCommentariesReferences] = useState<
        BookReference[]
    >([]);

    const resultStatsLabel = hasSelectedFilters
        ? t('commentaries_count_with_filters')
        : t('commentaries_count');

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const resultStatsMessage = isMobile ? (
        <Typography variant="body1" color="primary">
            {`${t('commentaries_count')}: ${numberWithCommas(totalResults)}`}
        </Typography>
    ) : (
        <Typography variant="body1" color="primary">
            {`${resultStatsLabel}: ${numberWithCommas(totalResults)}`}
        </Typography>
    );
    const [collapsed, setCollapsed] = useState(false);

    const narrations =
        narrationsType === 'all'
            ? hadith.raw_narrations
                  .concat(hadith.extended_narrations)
                  .concat(hadith.hadith_id)
            : narrationsType === 'roads'
            ? hadith.raw_narrations.concat(hadith.hadith_id)
            : hadith.extended_narrations;
    return (
        <SearchBase
            app={hadithCommentaryIndex}
            setSearchParams={(newURL) => {
                router.push(newURL, undefined, {
                    shallow: true,
                });
            }}
        >
            {/* constrain the other filters to hadith matching the hadithIds */}
            <ReactiveComponent
                componentId={HadithCommentaryViewFilters.HADITHS_IDS}
                customQuery={() => ({
                    query: {
                        terms: {
                            [HadithCommentaryDataFields.COMMENTARY_NARRATIONS]:
                                narrations,
                        },
                    },
                })}
            />
            <ReactiveSearchLayout
                hasSelectedFilters={hasSelectedFilters}
                collapsed={collapsed}
                setCollapsed={setCollapsed}
                filters={(setDrawerFiltersOpen) => (
                    <HadithCommentaryFilters
                        setDrawerFiltersOpen={setDrawerFiltersOpen}
                    />
                )}
                resultStatsMessage={resultStatsMessage}
                results={(handleFilter) => (
                    <>
                        <FiltersSelected />
                        {/* re-enable once road and shawahed distinction is working better */}
                        {/* <Box
                            sx={
                                isMobile
                                    ? {
                                          display: 'flex',
                                          justifyContent: 'center',
                                          mt: 1,
                                      }
                                    : {
                                          mt: 4,
                                          display: 'flex',
                                          justifyContent: 'start',
                                      }
                            }
                        >
                            <NarrationsTypeToggle
                                narrationsType={narrationsType}
                                setNarrationsType={setNarrationsType}
                                disabledShawahed={
                                    hadith.extended_narrations.length === 0
                                }
                            />
                        </Box> */}
                        <ResultItemList
                            hasSelectedFilters={hasSelectedFilters}
                            resultStatsMessage={resultStatsMessage}
                            componentId={HadithCommentaryViewFilters.RESULT}
                            dataField={
                                HadithCommentaryDataFields.HADITH_HADITH_ID
                            }
                            onData={(data) =>
                                setTotalResults(
                                    data.resultStats.numberOfResults,
                                )
                            }
                            dependencies={{
                                and: [
                                    HadithCommentaryViewFilters.HADITHS_IDS,
                                    HadithCommentaryViewFilters.BOOKS_FILTERS,
                                    HadithCommentaryViewFilters.COMMENTATOR_FILTERS,
                                ],
                            }}
                            defaultQuery={() => {
                                return {
                                    track_total_hits: true,
                                    sort: [
                                        {
                                            [HadithCommentaryDataFields.TIME_ORDER]:
                                                {
                                                    order: 'asc',
                                                },
                                        },
                                        {
                                            [HadithCommentaryDataFields.SERIAL_ID]:
                                                {
                                                    order: 'asc',
                                                },
                                        },
                                    ],
                                };
                            }}
                            includeFields={[
                                HadithCommentaryDataFields.TYPE,
                                HadithCommentaryDataFields.HADITH,
                                HadithCommentaryDataFields.COMMENTARY,
                            ]}
                            size={size}
                            resultItems={(items) => {
                                const { data, loading } =
                                    ResultItems.parse(items);

                                const hadiths = data.map((item) => {
                                    return item.type == 'embedded' ? (
                                        <HadithResultItem
                                            key={uuidv4()}
                                            hadith_object={item.hadith}
                                            rightActions={[]}
                                            allowNarratorClick={false}
                                            setShowSnackbar={setShowSnackbar}
                                            quickNavPoints={
                                                commentariesReferences
                                            }
                                            noCopy
                                            reportErrorType="hadith-commentary"
                                        />
                                    ) : (
                                        <HadithCommentaryResultItem
                                            key={uuidv4()}
                                            commentary={item.commentary}
                                            commentariesReferences={
                                                commentariesReferences
                                            }
                                            hadithId={hadith.hadith_id}
                                            reportErrorId={JSON.stringify(
                                                {
                                                    hadith_id: hadith.hadith_id,
                                                    id: item.commentary.id,
                                                },
                                                null,
                                                2,
                                            )}
                                        />
                                    );
                                });

                                if (loading && data.length > size) {
                                    return hadiths.concat(
                                        Array(size)
                                            .fill(0)
                                            .map(() => (
                                                <HadithSkeleton
                                                    key={uuidv4()}
                                                    withActions={false}
                                                />
                                            )),
                                    );
                                } else if (loading) {
                                    // filtering, not paginating, since we have one page only (data.length <= size)
                                    // return a single page of skeletons, no Hadiths
                                    // the *2 heuristic is because a general skeleton is shorter than a Hadith, not perfect
                                    return Array(size * 2)
                                        .fill(0)
                                        .map(() => (
                                            <HadithSkeleton
                                                key={uuidv4()}
                                                withActions={false}
                                            />
                                        ));
                                }

                                return hadiths.concat(
                                    <MoreResultsNotice
                                        key="more-results-notice"
                                        moreResults={
                                            hadiths.length < totalResults
                                        }
                                    />,
                                );
                            }}
                            loader={Array(size)
                                .fill(0)
                                .map(() => (
                                    <HadithSkeleton
                                        key={uuidv4()}
                                        withActions={false}
                                    />
                                ))}
                            stream={true}
                            showPagination={false}
                            onFilterClick={handleFilter}
                            scrollTarget="main-modal"
                        />
                        {/* TODO[chammaaomar]: Figure out how to get scroll to top to work for scrollable containers */}
                        {/* This library only supports window.scrollTo out of the box, it's kinda tricky to modify */}
                        {/* <ScrollToTop
                            smooth
                            top={scrollToTopMin}
                            containerId="main-modal"
                        /> */}

                        <StateProvider
                            includeKeys={[
                                'value',
                                'hits',
                                'aggregations',
                                'error',
                                'query',
                                'aggs',
                                'size',
                                'includeFields',
                                'react',
                                'sort',
                            ]}
                            //Counts coming from aggregations query
                            onChange={(state, nextState: NextState) => {
                                checkFiltersSelected(
                                    { ...state, ...nextState },
                                    hasSelectedFilters,
                                    setHasSelectedFilters,
                                    'comm_',
                                );
                                if (nextState?.comm_result?.hits?.total) {
                                    getCommentariesReferences(
                                        nextState,
                                        nextState['comm_result'].hits.total,
                                    ).then((references) =>
                                        setCommentariesReferences(references),
                                    );
                                }
                            }}
                        />
                    </>
                )}
            />
        </SearchBase>
    );
};

export default HadithCommentaryView;
