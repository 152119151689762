import { useState } from 'react';

import type { MySnackbarProps } from '@components/snackbar/Snackbar';

import useTranslation from '@hooks/useTranslation';
import { Box } from '@mui/material';
import Alert from '@mui/material/Alert';

import { HadithExplanationView } from 'components';
import {
    type NarrationsType,
    SearchPageHadith,
} from 'shared/interfaces/hadith';

interface ExplanationControllerProps {
    /**
     * @description hadith
     */
    hadith: SearchPageHadith;
    setShowSnackbar: (x: MySnackbarProps) => void;
}

/**
 * @description
 * @info follows this page pattern: '/[page]/[hadithId]/[hadithView]'
 */
const ExplanationController = ({
    hadith,
    setShowSnackbar,
}: ExplanationControllerProps) => {
    const defaultNarrationsType: NarrationsType = hadith.hasExplanation
        ? 'roads'
        : 'shawahed';
    const { t } = useTranslation('library');
    const [narrationsType, setNarrationsType] = useState<NarrationsType>(
        defaultNarrationsType,
    );
    return (
        <Box mt={1}>
            {/* <Alert severity="warning">
                <span
                    dangerouslySetInnerHTML={{
                        __html: t('in_progress_explainations_feature'),
                    }}
                ></span>
            </Alert> */}
            <Box mt={2}>
                <HadithExplanationView
                    hadith={hadith}
                    setShowSnackbar={setShowSnackbar}
                    narrationsType={narrationsType}
                    setNarrationsType={setNarrationsType}
                />
            </Box>
        </Box>
    );
};

export default ExplanationController;
