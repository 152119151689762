import {
    Component,
    type ReactNode,
    type RefObject,
    useRef,
    useState,
} from 'react';

import FilterHeader from '@components/filters/elements/FilterHeader';
import FilterItem from '@components/filters/elements/FilterItem';
import FilterSkeleton from '@components/skeletons/FilterSkeleton';

import {
    MultiList,
    ReactiveComponent,
    StateProvider,
} from '@appbaseio/reactivesearch';
import type { MultiList as MultiListType } from '@appbaseio/reactivesearch/lib/components/list/MultiList';
import { queryFormatSearch } from '@appbaseio/reactivesearch/lib/types';
import { Box, TextField, useMediaQuery, useTheme } from '@mui/material';

import { IDataListItem, type StatePrefix } from 'shared/interfaces/hadith';
import { z } from 'zod';

interface State {
    currentValue: Record<string, boolean>;
    options: IDataListItem[];
}

export type FilterListRef = RefObject<Component<MultiListType, State>> | null;

const NestedAggregate = z.object({
    key: z.string(),
    doc_count: z.number(),
    root_document_count: z.object({
        doc_count: z.number(),
    }),
});

type NestedAggregate = z.infer<typeof NestedAggregate>;

const Aggregate = z.object({
    key: z.string(),
    doc_count: z.number(),
});

type Aggregate = z.infer<typeof Aggregate>;

const SelectedTermsResponse = z.object({
    aggregations: z.object({
        selected_terms: z.object({
            buckets: Aggregate.array(),
        }),
    }),
});

interface IFilterListProps {
    /**
     * @description The title for the header
     */
    label: string;

    /**
     * @description This determines whether we just add up the buckets in memory for count, or fetch it from an aggregate query result
     */
    countAggregateIdentifier?: string;

    /**
     * @description MultiList component Id for ReactiveSearch
     */
    componentId: string;

    /**
     * @description Reactive base data field for the MultiList
     */
    dataField: string;

    /**
     * @description Reactive base nested field for the MultiList
     */
    nestedField?: 'narrators' | 'hadith.narrators';

    /**
     * @description Reactive base nested field for the MultiList
     */
    nestedFieldFull?: 'narrators.full_name' | 'hadith.narrators.full_name';

    /**
     * @description MultiList react prop
     * @default {}
     */
    dependencies?: { and?: string[] };

    /**
     * @description MultiList queryFormat prop. Can be one of 'or' or 'and'
     * @default 'or'
     */
    queryFormat?: queryFormatSearch;

    /**
     * @description Displays the filter label and selected items once selected
     * @default true
     */
    showFilter?: boolean;

    /**
     * @description Number of items to show in the list
     * @default 10
     */
    size?: number;

    /**
     * @description Whether to add url params based on filter selection
     * @default true
     */
    URLParams?: boolean;

    /**
     * @description MultiList no result text label
     * @default ''
     */
    noResultsText?: string;

    /**
     * @description MultiList style prop.
     * @default {marginBottom: 20}
     */
    style?: object;

    /**
     * @description Shows missing bucket
     * @default false
     */
    showMissing?: boolean;

    searchMoreMessage?: string;

    /**
     * @description Allow select all for the list
     * @default false
     */
    showSelectAll?: boolean;

    /**
     * @description selectAllLabel
     * @default ''
     */
    selectAllLabel?: string;

    /**
     * @description can take on three values 'memory','server','none' to determine search type on top filter
     * @default 'none'
     * TODO: Switch this to a type instead of string
     */
    searchType?: string;

    /**
     * @description search bar component id. only applicable if searchType is server
     * @default ''
     * TODO: Switch this to a type instead of string
     */
    searchComponentId?: string;

    /**
     * @description Field to use for searching for items on the server
     * @default ''
     */
    searchField?: string;

    /**
     * @description Text to display if search text is empty
     */
    searchPlaceholder?: string;

    /**
     * @description Function to adjust the list data before rendering
     */
    manipulateListData?: (data: IDataListItem[]) => IDataListItem[];

    /**
     * @description Can be used to render more JSX elements under the header, but won't be shown if the list is collapsed
     */
    children?: ReactNode;

    /**
     * @description Array of colored items
     * @default []
     */
    coloredItems?: { color: string; key: string }[];

    /**
     * @description Functions to set colored items
     * TODO: Switch this to a type instead of string
     */
    setColoredItems?: (items: string[]) => void;

    /**
     * @description Prefix to access state attributes in the <StateProvider/>
     */
    statePrefix: StatePrefix;

    setLoadingCounts?: (loading: boolean) => void;
    showAggregateCounts?: boolean;
    setDrawerFiltersOpen: (open: boolean) => void;
}

/**
 * @description SearchFilter component to render a list of items with a search input
 * @param {*} IFilterListProps
 * @returns JSX.Element
 */

const FilterList = ({
    label,
    componentId,
    dataField,
    countAggregateIdentifier = '',
    nestedField,
    nestedFieldFull,
    statePrefix,
    dependencies = {},
    searchMoreMessage,
    queryFormat = 'or',
    showFilter = true,
    size = 10,
    URLParams = true,
    noResultsText = '',
    style = {
        marginBottom: 20,
    },
    showMissing = false,
    showSelectAll = false,
    selectAllLabel = '',
    searchType = 'none',
    searchComponentId = '',
    searchPlaceholder = '',
    searchField = '',
    manipulateListData = (data) => data,
    children,
    coloredItems = [],
    setColoredItems = () => {},
    setLoadingCounts,
    showAggregateCounts = true,
    setDrawerFiltersOpen,
}: IFilterListProps) => {
    const listRef: FilterListRef = useRef(null);
    const inputRef: RefObject<HTMLInputElement> = useRef(null);
    const [expanded, setExpanded] = useState(false);
    const [count, setCount] = useState(0);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [textInput, setTextInput] = useState('');
    // this should always be empty for AND filters, don't populate it
    const [selectedOrTerms, setSelectedOrTerms] = useState<string[]>([]);
    const [selectedTermsBuckets, setSelectedTermsBuckets] = useState<
        Aggregate[]
    >([]);

    const [termBucketsLoading, setTermBucketsLoading] = useState(false);

    const handleTextInputChange = (event: any) => {
        setTextInput(event.target.value);
    };

    const preventEnterNewLine = (e: any) => {
        if (e.keyCode == 13) {
            e.preventDefault();
        }
    };

    const [searchAggregations, setSearchAggregations] = useState<
        IDataListItem[]
    >([]);

    const querySelectedBuckets = queryFormat === 'or';

    return (
        <>
            <FilterHeader
                expanded={expanded}
                setExpanded={setExpanded}
                title={label}
                count={showAggregateCounts ? count : ''}
            />

            <Box
                mb={1}
                sx={{
                    width: '100%',
                    marginRight: '10',
                    display: expanded ? 'none' : 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    '&:hover': {
                        backgroundColor: 'rgba(183, 164, 164, 0.1)',
                        cursor: 'pointer',
                    },
                }}
            >
                {children}
            </Box>

            <Box sx={{ display: expanded ? 'none' : '' }}>
                {(searchType === 'server' ||
                    searchType === 'server-nested-field') && (
                    <>
                        <TextField
                            inputRef={inputRef}
                            label={searchPlaceholder}
                            value={textInput}
                            onChange={handleTextInputChange}
                            onKeyDown={preventEnterNewLine}
                            variant="outlined"
                            multiline
                            rows={1.2}
                            fullWidth
                            InputLabelProps={{
                                sx: {
                                    fontSize: '16px',
                                    pb: 0.5,
                                },
                            }}
                        />

                        {searchType === 'server-nested-field' &&
                        nestedField &&
                        nestedFieldFull &&
                        textInput ? (
                            <ReactiveComponent
                                componentId={searchComponentId}
                                react={dependencies} //TODO: Add a dependency on the multilist item if "and" search
                                //TODO: Add a clear button
                                defaultQuery={() => {
                                    if (textInput == '') {
                                        return null;
                                    }
                                    return (
                                        //Only execute if there is something in text input
                                        {
                                            size: 0,
                                            aggs: {
                                                [nestedField]: {
                                                    nested: {
                                                        path: nestedField,
                                                    },
                                                    aggs: {
                                                        filtered: {
                                                            filter: {
                                                                wildcard: {
                                                                    [searchField]:
                                                                        '*' +
                                                                        textInput +
                                                                        '*',
                                                                },
                                                            },
                                                            aggs: {
                                                                [nestedFieldFull]:
                                                                    {
                                                                        terms: {
                                                                            field: nestedFieldFull,
                                                                            size: size,
                                                                            order: {
                                                                                _count: 'desc',
                                                                            },
                                                                        },
                                                                        aggs: {
                                                                            root_document_count:
                                                                                {
                                                                                    reverse_nested:
                                                                                        {},
                                                                                },
                                                                        },
                                                                    },
                                                            },
                                                        },
                                                    },
                                                },
                                            },
                                        }
                                    );
                                }}
                                onData={(data) => {
                                    if (
                                        textInput != '' &&
                                        nestedField &&
                                        nestedFieldFull
                                    ) {
                                        //This will get aggregates of nested field and its parent docs
                                        let aggregates:
                                            | NestedAggregate[]
                                            | undefined =
                                            data?.aggregations[nestedField]
                                                ?.filtered[nestedFieldFull]
                                                ?.buckets;

                                        if (!aggregates) {
                                            return;
                                        }

                                        // Raise root document count to top level
                                        const searchAggregations: IDataListItem[] =
                                            aggregates.map(
                                                ({
                                                    key,
                                                    root_document_count,
                                                }) => {
                                                    return {
                                                        key,
                                                        doc_count:
                                                            root_document_count.doc_count,
                                                    };
                                                },
                                            );

                                        setSearchAggregations(
                                            searchAggregations,
                                        );
                                    }
                                }}
                            />
                        ) : null}

                        {searchType === 'server' && textInput ? (
                            <ReactiveComponent
                                componentId={searchComponentId}
                                react={dependencies} //TODO: Add a dependency on the multilist item if "and" search
                                //TODO: Add a clear button
                                defaultQuery={() => {
                                    if (textInput == '') {
                                        return null;
                                    }
                                    return {
                                        query: {
                                            wildcard: {
                                                [searchField]:
                                                    '*' + textInput + '*',
                                            },
                                        },
                                        size: 0,
                                        aggs: {
                                            [dataField]: {
                                                terms: {
                                                    field: dataField,
                                                },
                                            },
                                        },
                                    };
                                }}
                                onData={(data) => {
                                    if (textInput != '') {
                                        setSearchAggregations(
                                            data?.aggregations[dataField]
                                                ?.buckets,
                                        );
                                    }
                                }}
                            />
                        ) : null}
                    </>
                )}
                {/* This is to ensure the buckets selected by the user always show up in the list of buckets / aggregates.
                    Because in an OR filter only the top N are shown, and the user may select a bucket that's outside the top
                    N, so it won't show up in the list of buckets. So, we separately query for the buckets selected by the user
                    and prepend them to the list of buckets / filters. This doesn't apply to AND filters, because when the user
                    selects a bucket, all the other buckets results in subsets of the selected bucket, so they're always smaller
                    ensuring that when we ordering by bucket sizes descending, we get the selected ones by the user at the very top
                */}
                {querySelectedBuckets && selectedOrTerms.length > 0 ? (
                    <ReactiveComponent
                        componentId={`${componentId}-selected-buckets`}
                        react={dependencies}
                        defaultQuery={() => {
                            return {
                                size: 0,
                                aggs: {
                                    selected_terms: {
                                        terms: {
                                            field: dataField,
                                            size: selectedOrTerms.length,
                                            include: selectedOrTerms,
                                            order: { _count: 'desc' },
                                        },
                                    },
                                },
                            };
                        }}
                        onData={(data) => {
                            // if we just do a straight up parse this breaks the roads_graph and roads_hadith
                            // not sure why... tried invesitgating but couldn't figure it out. so this a nice
                            // long-term maintanable solution
                            const testParse =
                                SelectedTermsResponse.safeParse(data);

                            if (!testParse.success) {
                                return;
                            }

                            const parsedData = testParse.data;

                            const {
                                aggregations: {
                                    selected_terms: {
                                        buckets: selectedBuckets,
                                    },
                                },
                            } = parsedData;

                            setTermBucketsLoading(false);
                            setSelectedTermsBuckets(selectedBuckets);
                        }}
                    />
                ) : null}

                <MultiList
                    ref={listRef}
                    componentId={componentId}
                    filterLabel={label}
                    dataField={dataField}
                    URLParams={URLParams}
                    showMissing={showMissing}
                    queryFormat={queryFormat}
                    showFilter={showFilter}
                    showSearch={searchType === 'memory'}
                    placeholder={searchPlaceholder}
                    onValueChange={(list: string[]) => {
                        if (querySelectedBuckets) {
                            setSelectedOrTerms(list);

                            if (!list.length) {
                                setTermBucketsLoading(false);
                                setSelectedTermsBuckets([]);
                            }
                        }

                        if (isMobile && statePrefix === '') {
                            // main search filters, since on mobile we have a sticky navbar that allows filtering from any scroll
                            // like scroll y: 500
                            // so when the user filters, we take them to the top of the new search results
                            // on desktop the search and filters aren't sticky
                            window.scrollTo({ top: 0 });
                        }

                        setColoredItems(list);
                        searchType === 'server' ||
                        searchType === 'server-nested-field'
                            ? setTextInput('')
                            : null;
                    }}
                    render={(props) => {
                        let processedData = manipulateListData(
                            props.data as IDataListItem[],
                        );

                        if (
                            !textInput &&
                            selectedOrTerms.length &&
                            querySelectedBuckets
                        ) {
                            processedData = [
                                ...manipulateListData(selectedTermsBuckets),
                                ...processedData.filter(
                                    (agg) => !selectedOrTerms.includes(agg.key),
                                ),
                            ].slice(0, size);
                        }

                        // Add color to the item, if needed
                        processedData = processedData.map((item) => {
                            const color = coloredItems.find(
                                ({ key }) => key === item.key,
                            )?.color;
                            return {
                                ...item,
                                color,
                            };
                        });

                        if (props.loading || termBucketsLoading) {
                            return <FilterSkeleton size={size} />;
                        }

                        return (
                            <FilterItem
                                showAggregateCounts={showAggregateCounts}
                                handleChange={props.handleChange}
                                listData={processedData}
                                listId={componentId}
                                listRef={listRef}
                                noResultsText={noResultsText}
                                showSelectAll={showSelectAll}
                                selectAllLabel={selectAllLabel}
                                setTermBucketsLoading={
                                    querySelectedBuckets
                                        ? setTermBucketsLoading
                                        : undefined
                                }
                                inputRef={inputRef}
                                filterName={label}
                                searchMoreMessage={searchMoreMessage}
                                setDrawerFiltersOpen={setDrawerFiltersOpen}
                                showSearchMore={
                                    // search more appears if extra results not shown AND
                                    // if there's a search bar AND
                                    // user is not already searching
                                    count > size &&
                                    (searchType === 'server' ||
                                        searchType === 'server-nested-field') &&
                                    !textInput
                                }
                                setLoadingCounts={setLoadingCounts}
                            />
                        );
                    }}
                    react={dependencies}
                    size={size + selectedOrTerms.length}
                    style={style}
                    showCheckbox={false} //Not used as we're using custom render method
                    transformData={(data) => {
                        if (
                            searchType !== 'server' &&
                            searchType !== 'server-nested-field'
                        ) {
                            return data;
                        }

                        //Exclude selected items from searchAggregations
                        // let updatedSearchAggregation = searchAggregations.filter(ar => !listItems.find(rm => (rm == ar.key)))
                        data = textInput.length > 0 ? searchAggregations : data;
                        return data;
                    }}
                />

                <StateProvider
                    // TODO[@chammaaomar]: Type this stuff properly
                    onChange={(prevState, nextState) => {
                        // Counts coming from filter bucket list size
                        if (nextState[componentId]?.aggregations) {
                            if (countAggregateIdentifier != '') {
                                setCount(
                                    nextState?.[`${statePrefix}result`]
                                        ?.aggregations?.[
                                        countAggregateIdentifier
                                    ]?.value,
                                );
                            } else {
                                setCount(
                                    nextState[componentId]?.aggregations[
                                        dataField
                                    ]?.buckets?.length,
                                );
                            }
                        }
                    }}
                />
            </Box>
        </>
    );
};

export default FilterList;
export type { IFilterListProps };
