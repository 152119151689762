import { MutableRefObject, useRef } from 'react';

import { useRouter } from 'next/router';

import { MoreResultsNotice } from '@components/results/elements/MoreResultsNotice';
import HadithResultItem from '@components/results/items/HadithResultItem';
import HadithSkeleton from '@components/skeletons/HadithSkeleton';
import { MySnackbarProps } from '@components/snackbar/Snackbar';

import { NarratorColor } from '@utils/narratorColor';

import { SearchPageHadith } from 'shared/interfaces/hadith';
import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';

const HadithResultItemsProps = z.object({
    data: z.array(SearchPageHadith),
    loading: z.boolean(),
});

export type HadithResultItemsProps = z.infer<typeof HadithResultItemsProps>;

export function ResultItems({
    items,
    size,
    narratorsColors,
    setShowSnackbar,
    resultsStats,
    totalResults,
    firstRender,
}: {
    items: HadithResultItemsProps;
    size: number;
    narratorsColors: NarratorColor[];
    setShowSnackbar: (x: MySnackbarProps) => void;
    resultsStats: JSX.Element;
    totalResults: number;
    firstRender: MutableRefObject<boolean>;
}): JSX.Element {
    const router = useRouter();
    const globalLoading = useRef(false);
    const timeoutId = useRef<NodeJS.Timeout | null>(null);

    const { loading } = items;

    if (loading && !globalLoading.current) {
        // only set it first time
        globalLoading.current = true;
        timeoutId.current = setTimeout(() => {
            if (globalLoading) {
                router.replace({
                    query: window.location.search.substring(1),
                });
            }
        }, 3000);
    }

    const parsed = HadithResultItemsProps.parse(items);

    const { data } = parsed;

    const hadiths = data.map((item) => (
        <HadithResultItem
            key={uuidv4()}
            hadith_object={item}
            narratorsColors={narratorsColors}
            setShowSnackbar={setShowSnackbar}
        />
    ));

    // this is for updating the search results due to filters
    if (loading && data.length > size) {
        // paginating; add the skeletons to the existing hadiths
        return (
            <>
                {hadiths.concat(
                    Array(size)
                        .fill(0)
                        .map(() => (
                            <HadithSkeleton key={uuidv4()} withActions />
                        )),
                )}
            </>
        );
    } else if (loading) {
        // filtering, not paginating, since we have one page only (data.length <= size)
        // return a single page of skeletons, no Hadiths
        // the *2 heuristic is because a general skeleton is shorter than a Hadith, not perfect
        if (firstRender.current) {
            return (
                <>
                    {[resultsStats].concat(
                        Array(size * 2)
                            .fill(0)
                            .map(() => (
                                <HadithSkeleton key={uuidv4()} withActions />
                            )),
                    )}
                </>
            );
        } else {
            return (
                <>
                    {Array(size * 2)
                        .fill(0)
                        .map(() => (
                            <HadithSkeleton key={uuidv4()} withActions />
                        ))}
                </>
            );
        }
    }

    // no loading
    globalLoading.current = false;
    clearTimeout(timeoutId.current ?? undefined);

    return (
        <>
            {hadiths.concat(
                <MoreResultsNotice
                    key="more-results-notice"
                    moreResults={hadiths.length < totalResults}
                />,
            )}
        </>
    );
}
