import { SVGProps } from 'react';

const ExcelSvg = (props: SVGProps<SVGSVGElement> & { disabled: boolean }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
        <path
            fill={props.disabled ? '#808080' : '#303c42'}
            d="M23.5 3H14V.5a.502.502 0 0 0-.595-.491l-13 2.5A.5.5 0 0 0 0 3v18a.5.5 0 0 0 .405.491l13 2.5a.495.495 0 0 0 .413-.106A.495.495 0 0 0 14 23.5V21h9.5a.5.5 0 0 0 .5-.5v-17a.5.5 0 0 0-.5-.5z"
        />
        <path
            fill={props.disabled ? '#808080' : '#2e7d32'}
            d="M13 22.895 1 20.587V3.413l12-2.307z"
        />
        <path
            fill="#fff"
            d="M23 20h-9v-1h2.5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H14v-1h2.5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H14v-1h2.5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H14v-1h2.5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H14V7h2.5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H14V4h9v16z"
        />
        <path
            fill={props.disabled ? '#808080' : '#303c42'}
            d="M18.5 7h3a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5zm0 3h3a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5zm0 3h3a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5zm0 3h3a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5zm0 3h3a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5z"
        />
        <path
            fill="#fff"
            d="M8.724 8.053a.503.503 0 0 0-.671.223L6.5 11.382 4.947 8.276a.501.501 0 0 0-.894.448L5.941 12.5l-1.888 3.776a.5.5 0 0 0 .894.448L6.5 13.618l1.553 3.105a.499.499 0 1 0 .895-.447L7.059 12.5l1.888-3.776a.5.5 0 0 0-.223-.671z"
        />
        <linearGradient
            id="a"
            x1={10}
            x2={22.5}
            y1={8}
            y2={20.5}
            gradientUnits="userSpaceOnUse"
        >
            <stop offset={0} stopOpacity={0.1} />
            <stop offset={1} stopOpacity={0} />
        </linearGradient>
        <path
            fill="url(#a)"
            d="M14 4v1h1l-1-1zm2.854 2.854A.502.502 0 0 1 16.5 7H14v1h2.5a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H14v1h2.5a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H14v1h2.5a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H14v1h2.5a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H14v1h9v-7l-6.146-6.146z"
        />
        <linearGradient
            id="b"
            x1={-1.442}
            x2={25.059}
            y1={5.982}
            y2={18.34}
            gradientUnits="userSpaceOnUse"
        >
            <stop offset={0} stopColor="#fff" stopOpacity={0.2} />
            <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <path
            fill="url(#b)"
            d="M23.5 3H14V.5a.502.502 0 0 0-.595-.491l-13 2.5A.5.5 0 0 0 0 3v18a.5.5 0 0 0 .405.491l13 2.5a.495.495 0 0 0 .413-.106A.495.495 0 0 0 14 23.5V21h9.5a.5.5 0 0 0 .5-.5v-17a.5.5 0 0 0-.5-.5z"
        />
    </svg>
);
export default ExcelSvg;
