import { useState } from 'react';
import { Fragment } from 'react';

import { BookPreview } from '@components/bookpreview';
import { booksConfig } from '@components/bookpreview/bookConfigs';

import { useCommonContext } from '@hooks/commonContext';
import { useFontSize } from '@hooks/fontSizeContext';
import { useTashkeel } from '@hooks/tashkeelContext';
import useTranslation from '@hooks/useTranslation';
import { ReportGmailerrorred } from '@mui/icons-material';
import PreviewIcon from '@mui/icons-material/Preview';
import {
    Box,
    Divider,
    IconButton,
    Paper,
    SvgIcon,
    Tooltip,
    Typography,
    styled,
} from '@mui/material';
import { removeTashkeel } from '@utils/tashkeel';

import { HadithHeaderInfoDetails } from '../HadithResultItem/styled';
import { analytics } from 'lib/analytics';
import { BookReference } from 'shared/interfaces/hadith';
import theme, { colors } from 'theme';

const NarratorsCommentaryItemContainer = styled(Paper)(() => ({
    borderRadius: '10px',
}));

const NarratorsCommentaryResultItem = ({
    author,
    comments,
    commentator,
    commId,
    book,
    page,
    volume,
    quickNavPoints,
    reportErrorId,
    narratorId,
}: {
    author: string;
    comments: string[];
    commentator: string;
    commId: string;
    book: string;
    page: number | null;
    volume: number | null;
    quickNavPoints: BookReference[];
    reportErrorId: string;
    narratorId: string;
}) => {
    const { t } = useTranslation('library');
    const { setErrorData } = useCommonContext();
    const { fontSize } = useFontSize();
    const { tashkeelEnabled } = useTashkeel();
    const pageVolumeTypeContents: string[] = [
        volume ? `${t('volume')}: ${volume}` : '',
        page ? `${t('page')}: ${page}` : '',
    ].filter((str) => str);
    const [openPreviewModal, setOpenPreviewModal] = useState(false);

    const bookPreviewNA = !(booksConfig[book] && volume && page);
    const bookPreviewButton = (
        <IconButton
            onClick={() => {
                analytics('book-preview', {
                    type: 'narrator-commentary',
                    id: commentator,
                    narrator_id: narratorId,
                    source: 'narrators-commentary-result-item',
                });
                setOpenPreviewModal(true);
            }}
            disabled={bookPreviewNA}
            sx={{
                flex: { xs: 1, sm: 'unset' },
            }}
            color="secondary"
        >
            <PreviewIcon />
        </IconButton>
    );

    return (
        <NarratorsCommentaryItemContainer
            elevation={0}
            variant="outlined"
            sx={{
                margin: { xs: '10px 0', md: '10px 0' },
                width: '100%',
                my: 1,
            }}
        >
            <Box
                display={'flex'}
                alignItems={'flex-start'}
                justifyContent={'flex-end'}
                width={'100%'}
            >
                <HadithHeaderInfoDetails>
                    {pageVolumeTypeContents.map((content, i) => (
                        <Typography
                            key={content}
                            sx={{
                                padding: '5px',
                                borderLeft:
                                    i > 0
                                        ? `1px solid  ${theme.palette.divider}`
                                        : undefined,
                                height: '100%',
                                color: colors.primary,
                            }}
                        >
                            {content}
                        </Typography>
                    ))}
                </HadithHeaderInfoDetails>
            </Box>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                sx={{
                    flexDirection: { xs: 'column', sm: 'row' },
                    alignItems: { xs: 'flex-start', sm: 'center' },
                    gap: { xs: 1, sm: 0 },
                }}
                pl={2}
                pb={1}
            >
                <Typography
                    variant="body1"
                    fontSize={fontSize.body}
                    fontWeight={600}
                >
                    {commentator}
                </Typography>
                {book && author && (
                    <>
                        <Typography
                            mx={1}
                            fontSize={fontSize.body}
                            sx={{ display: { xs: 'none', sm: 'block' } }}
                        >
                            -
                        </Typography>
                        <Typography
                            fontSize={fontSize.body}
                            variant="body2"
                            color="secondary"
                        >
                            {`(${book} - ${author})`}
                        </Typography>
                    </>
                )}
            </Box>
            <Divider sx={{ width: '100%' }} />
            <Typography
                variant="body1"
                sx={{
                    color: '#121212',
                    py: 2,
                    px: 2,
                    textAlign: 'justify',
                    fontSize: fontSize.body,
                }}
            >
                {comments.map((line, index) => (
                    <Fragment key={index}>
                        {tashkeelEnabled ? line : removeTashkeel(line)}
                        <br />
                    </Fragment>
                ))}
            </Typography>
            <Divider sx={{ width: '100%' }} />
            <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={{ xs: 'space-between', sm: 'flex-end' }}
                position="relative"
            >
                {bookPreviewNA ? (
                    <Tooltip
                        placement="top"
                        title={
                            <Typography fontSize={12}>
                                {t('preview_not_clickable')}
                            </Typography>
                        }
                        enterTouchDelay={1}
                    >
                        {/* https://stackoverflow.com/a/75962378 disabled buttons don't emit hover events, so we wrap in a span */}
                        {/* the hover event is necessary to have the tooltip rendered */}
                        <span>{bookPreviewButton}</span>
                    </Tooltip>
                ) : (
                    bookPreviewButton
                )}
                <Box
                    sx={{
                        padding: '10px',
                        display: 'flex',
                        flex: { xs: 1, sm: 'unset' },
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderLeft: '1px solid lightgray',
                    }}
                    onClick={() => {
                        setErrorData({
                            elem_id: reportErrorId,
                            type: 'narrator-commentary',
                            source: 'user-report',
                        });
                    }}
                >
                    <SvgIcon
                        sx={{
                            cursor: 'pointer',
                        }}
                        component={ReportGmailerrorred}
                        color={'secondary'}
                    />
                </Box>
            </Box>
            {booksConfig[book] && volume && page && openPreviewModal && (
                <BookPreview
                    open={openPreviewModal}
                    setOpen={setOpenPreviewModal}
                    initEditionsRefs={[]}
                    initNavPointId={commId}
                    quickNavPoints={quickNavPoints}
                />
            )}
        </NarratorsCommentaryItemContainer>
    );
};

export default NarratorsCommentaryResultItem;
