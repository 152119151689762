// we don't add the ambiguous span to the html in elasticsearch because it intefers
// with the highlight functionality. So we inject ONLY inside the hadith modal
import { SearchPageHadith } from 'shared/interfaces/hadith';

// we don't inject it on the main page on the frontend
// maybe a heavy function, but we only run it on one hadith when it's opened
// we don't run it inside render functions
export function addAmbiguousSpan(hadith: SearchPageHadith): void {
    hadith.ambiguous.forEach((ambg) => {
        const ambgRef = ambg.reference;
        const ambgRefId = ambg.reference_id;

        // to allow for differences in tashkeel
        const ambgRefNoTashkeel = ambgRef.replaceAll(/[\u064b-\u0652]/g, '');
        // allow any tashkeel or no tashkeel after each letter
        // including the final letter
        // put in parantheses for capture group
        const ambgRefAnyTashkeelRegex = new RegExp(
            `(${ambgRefNoTashkeel
                .split('')
                .join('[\u064b-\u0652]*')}[\u064b-\u0652]*)`,
            'g',
        );

        // we do $1 so we don't modify hadith, just add a span
        hadith.hadith = hadith.hadith.replaceAll(
            ambgRefAnyTashkeelRegex,
            `<span id=${ambgRefId} class="ambiguous">$1</span>`,
        );
    });
}
