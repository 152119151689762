import { useState } from 'react';

import { useCommonContext } from './commonContext';
import useTranslation from './useTranslation';

/**
 * Custom hook to provide the option to copy a given text to the clipboard.
 * @returns {Object} - An object containing the copy function and the copy status.
 */
const useCopyToClipboard = () => {
    const [isCopied, setIsCopied] = useState(false);
    const { setShowSnackbar } = useCommonContext();
    const { t } = useTranslation('library');

    /**
     * Copy the provided text to the clipboard.
     * @param {string} text - The text to be copied to the clipboard.
     */
    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text).then(
            () => {
                setIsCopied(true);
                setShowSnackbar({
                    message: t('explanation_copied'),
                    severity: 'success',
                    show: true,
                });
                setTimeout(() => setIsCopied(false), 2000); // Reset the copy status after 2 seconds
            },
            (err) => {
                console.error('Failed to copy text: ', err);
            },
        );
    };

    return { copyToClipboard, isCopied };
};

export default useCopyToClipboard;
