import { useState } from 'react';

import FilterList from '@components/filters/FilterList';

import { SORT_OPTIONS } from '@constants/sorts';
import useTranslation from '@hooks/useTranslation';
import { Box, Checkbox, Divider, Typography } from '@mui/material';
import { NarratorColor, handleNarratorColorChange } from '@utils/narratorColor';
import {
    HadithBook,
    sortByAuthenticity,
    sortByEarliest,
    sortByLatest,
} from '@utils/sortBooks';

import { GraphDataFields, NarrationsDataFields } from 'constants/datafields';
import { RoadsHadithsViewFilters } from 'constants/filters';
import { IDataListItem } from 'shared/interfaces/hadith';

interface RoadsHadithsFiltersProps {
    source: 'roads_hadiths' | 'roads_graph';
    narratorsColors?: NarratorColor[];
    setNarratorsColors: (colors: NarratorColor[]) => void;
    sortBy?: SORT_OPTIONS;
    setDrawerFiltersOpen: (open: boolean) => void;
}

const RoadsFilters = ({
    source,
    narratorsColors = [],
    setNarratorsColors,
    sortBy = SORT_OPTIONS.AUTHENTICITY,
    setDrawerFiltersOpen,
}: RoadsHadithsFiltersProps) => {
    const { t } = useTranslation('library');
    const [narratorsLinkedSwitch, setNarratorsLinkedSwitch] = useState(true);

    // Since both roads hadiths and roads graphs use the same filters
    // but the data fields reference is different and graph has it nested within a hadith object
    // we need to determine which one to use
    const DataFieldsReference =
        source == 'roads_graph' ? GraphDataFields : NarrationsDataFields;

    const statePrefix = 'roads_';

    return (
        <Box>
            {/* Search By Books */}
            <FilterList
                label={t('books')}
                size={23}
                URLParams={true}
                componentId={RoadsHadithsViewFilters.BOOKS_FILTERS}
                dataField={DataFieldsReference.BOOK_NAME}
                statePrefix={statePrefix}
                setDrawerFiltersOpen={setDrawerFiltersOpen}
                dependencies={{
                    and: [
                        RoadsHadithsViewFilters.HADITHS_IDS,
                        RoadsHadithsViewFilters.NARRATORS_FILTERS,
                        RoadsHadithsViewFilters.CHAPTERS_FILTERS,
                        RoadsHadithsViewFilters.HADITH_TYPES_FILTERS,
                        RoadsHadithsViewFilters.SUB_CHAPTERS_FILTERS,
                    ],
                }}
                showSelectAll={true}
                selectAllLabel={t('select_all')}
                manipulateListData={(data: IDataListItem[]) => {
                    switch (sortBy) {
                        case SORT_OPTIONS.NEWEST_TO_OLDEST:
                            return sortByLatest(data as HadithBook[]);
                        case SORT_OPTIONS.OLDEST_TO_NEWEST:
                            return sortByEarliest(data as HadithBook[]);
                        default:
                            return sortByAuthenticity(data as HadithBook[]);
                    }
                }}
            />
            <Divider sx={{ my: '10px' }} />

            {/* Search By Narrators */}
            <FilterList
                label={t('narrators')}
                URLParams={true}
                statePrefix={statePrefix}
                countAggregateIdentifier={'unique_narrators'}
                componentId={RoadsHadithsViewFilters.NARRATORS_FILTERS}
                dataField={DataFieldsReference.NARRATORS_FULL_NAMES}
                nestedField={DataFieldsReference.NARRATORS}
                nestedFieldFull={DataFieldsReference.NARRATORS_FULL_NAME}
                searchType="server-nested-field"
                searchComponentId={RoadsHadithsViewFilters.NARRATORS_SEARCH}
                searchField={DataFieldsReference.NARRATORS_FULL_NAME_NORMALIZED}
                searchPlaceholder={t('search_for_a_narrator')}
                queryFormat={narratorsLinkedSwitch ? 'and' : 'or'}
                size={20}
                setDrawerFiltersOpen={setDrawerFiltersOpen}
                dependencies={
                    narratorsLinkedSwitch
                        ? {
                              and: [
                                  RoadsHadithsViewFilters.HADITHS_IDS,
                                  RoadsHadithsViewFilters.BOOKS_FILTERS,
                                  RoadsHadithsViewFilters.CHAPTERS_FILTERS,
                                  RoadsHadithsViewFilters.NARRATORS_FILTERS,
                                  RoadsHadithsViewFilters.HADITH_TYPES_FILTERS,
                                  RoadsHadithsViewFilters.SUB_CHAPTERS_FILTERS,
                              ],
                          }
                        : {
                              and: [
                                  RoadsHadithsViewFilters.HADITHS_IDS,
                                  RoadsHadithsViewFilters.BOOKS_FILTERS,
                                  RoadsHadithsViewFilters.CHAPTERS_FILTERS,
                                  RoadsHadithsViewFilters.HADITH_TYPES_FILTERS,
                                  RoadsHadithsViewFilters.SUB_CHAPTERS_FILTERS,
                              ],
                          }
                }
                coloredItems={narratorsColors}
                setColoredItems={(newList) =>
                    handleNarratorColorChange(
                        newList,
                        narratorsColors,
                        setNarratorsColors,
                    )
                }
            >
                <>
                    <Typography variant="body1" color="primary">
                        {t('narrators_linked_in_narration_chain')}
                    </Typography>
                    <Checkbox
                        size="small"
                        checked={narratorsLinkedSwitch}
                        onChange={(event) => {
                            setNarratorsLinkedSwitch(event.target.checked);
                        }}
                        name="narratorsLinkedSwitch"
                        color="secondary"
                    />
                </>
            </FilterList>
            <Divider sx={{ my: '10px' }} />

            {/* Search By Chapters */}
            <FilterList
                label={t('chapters')}
                statePrefix={statePrefix}
                countAggregateIdentifier={'unique_chapters'}
                URLParams={true}
                componentId={RoadsHadithsViewFilters.CHAPTERS_FILTERS}
                dataField={DataFieldsReference.CHAPTER}
                setDrawerFiltersOpen={setDrawerFiltersOpen}
                dependencies={{
                    and: [
                        RoadsHadithsViewFilters.HADITHS_IDS,
                        RoadsHadithsViewFilters.BOOKS_FILTERS,
                        RoadsHadithsViewFilters.NARRATORS_FILTERS,
                        RoadsHadithsViewFilters.HADITH_TYPES_FILTERS,
                        RoadsHadithsViewFilters.SUB_CHAPTERS_FILTERS,
                    ],
                }}
                searchType="server"
                searchComponentId={RoadsHadithsViewFilters.CHAPTERS_SEARCH}
                searchField={DataFieldsReference.CHAPTER_NORMALIZED}
                searchPlaceholder={t('search_for_a_chapter')}
            />
            <Divider sx={{ my: 2 }} />

            {/* Search By Sub Chapter */}
            <FilterList
                label={t('sub_chapters')}
                statePrefix={statePrefix}
                countAggregateIdentifier={'unique_sub_chapters'}
                URLParams={true}
                componentId={RoadsHadithsViewFilters.SUB_CHAPTERS_FILTERS}
                dataField={DataFieldsReference.SUB_CHAPTER}
                setDrawerFiltersOpen={setDrawerFiltersOpen}
                dependencies={{
                    and: [
                        RoadsHadithsViewFilters.HADITHS_IDS,
                        RoadsHadithsViewFilters.BOOKS_FILTERS,
                        RoadsHadithsViewFilters.NARRATORS_FILTERS,
                        RoadsHadithsViewFilters.HADITH_TYPES_FILTERS,
                        RoadsHadithsViewFilters.CHAPTERS_FILTERS,
                    ],
                }}
                searchType="server"
                searchComponentId={RoadsHadithsViewFilters.SUB_CHAPTERS_SEARCH}
                searchField={DataFieldsReference.SUB_CHAPTER_NORMALIZED}
                searchPlaceholder={t('search_for_a_sub_chapter')}
            />
            <Divider sx={{ my: 2 }} />

            {/* Search By Hadith Types */}
            <FilterList
                label={t('hadith_type')}
                statePrefix={statePrefix}
                componentId={RoadsHadithsViewFilters.HADITH_TYPES_FILTERS}
                dataField={DataFieldsReference.TYPE}
                setDrawerFiltersOpen={setDrawerFiltersOpen}
                dependencies={{
                    and: [
                        RoadsHadithsViewFilters.HADITHS_IDS,
                        RoadsHadithsViewFilters.BOOKS_FILTERS,
                        RoadsHadithsViewFilters.NARRATORS_FILTERS,
                        RoadsHadithsViewFilters.CHAPTERS_FILTERS,
                        RoadsHadithsViewFilters.SUB_CHAPTERS_FILTERS,
                    ],
                }}
                size={4}
            />
        </Box>
    );
};

export default RoadsFilters;
