//Common

export { NarratorsController, HadithController } from './controllers';

//Views
export {
    NarratorBio as NarratorBioView,
    NarratorScholars as NarratorScholarsView,
    NarratorStudents as NarratorStudentsView,
    NarratorCommentary as NarratorCommentaryView,
    RoadsHadiths as RoadsHadithsView,
    HadithExplanation as HadithExplanationView,
    HadithCommentary as HadithCommentaryView,
    HadithRulings as HadithRulingsView
} from './views';
