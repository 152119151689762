import { useState } from 'react';

import { useRouter } from 'next/router';

import { NarrationsTypeToggle } from '@components/NarrationsTypeToggle';
import SearchBase from '@components/base/SearchBase';
import FiltersSelected from '@components/filters/elements/FiltersSelected';
import { MoreResultsNotice } from '@components/results/elements/MoreResultsNotice';
import ResultItemList from '@components/results/elements/ResultItemList';
import HadithExplanationResultItem from '@components/results/items/HadithExplanationResultItem';
import HadithSkeleton from '@components/skeletons/HadithSkeleton';
import { type MySnackbarProps } from '@components/snackbar/Snackbar';

import { ReactiveComponent, StateProvider } from '@appbaseio/reactivesearch';
import { explanationIndex } from '@config/reactive-search';
import useTranslation from '@hooks/useTranslation';
import { ReactiveSearchLayout } from '@layout';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { numberWithCommas } from '@utils/numbersDisplay';
import {
    authenticity_order,
    explanation_authenticity_order,
} from '@utils/sortBooks';

import HadithExplanationFilters from './HadithExplanationFilters';
import { HadithExplanationDataFields } from 'constants/datafields';
import { HadithExplanationViewFilters } from 'constants/filters';
import {
    type BookReference,
    Explanation,
    ExplanationHadith,
    HadithNumbers,
    type NarrationsType,
    type NextState,
    SearchPageHadith,
} from 'shared/interfaces/hadith';
import { checkFiltersSelected } from 'shared/methods';
import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';

const ResultItems = z.object({
    data: z.array(
        z.object({
            _id: z.string(),
            hadith: ExplanationHadith,
            explanation: Explanation,
        }),
    ),
    loading: z.boolean(),
});

interface HadithExplanationViewProps {
    /**
     * @description hadith
     */
    hadith: SearchPageHadith;
    setShowSnackbar: (x: MySnackbarProps) => void;
    narrationsType: NarrationsType;
    setNarrationsType: (x: NarrationsType) => void;
}

type BookReferenceWithHadith = BookReference & {
    hadith_book_name: string;
    hadith_number: number;
};

const ExplanationsReferences = z.object({
    hits: z.object({
        hits: z
            .object({
                _id: z.string(),
                _source: z.object({
                    explanation: z.object({
                        explanation_book_name: z.string(),
                        explanation_volume: z.number().nullable().optional(),
                        explanation_page: z.number().nullable().optional(),
                    }),
                    hadith: z.object({
                        number: HadithNumbers,
                        book_name: z.string(),
                        chapter: z.string(),
                    }),
                }),
            })
            .array(),
    }),
});

function getExplanationsReferences(
    fullSearchState: NextState,
    explanationsCount: number,
): Promise<BookReference[]> {
    const jsonBody = JSON.stringify({
        query: fullSearchState['expl_result'].query,
        aggs: {},
        size: explanationsCount,
        from: 0,
        _source: {
            includes: [
                HadithExplanationDataFields.HADITH_NUMBER,
                HadithExplanationDataFields.HADITH_BOOK_NAME,
                HadithExplanationDataFields.HADITH_CHAPTER,
                HadithExplanationDataFields.EXPLANATION_BOOK_NAME,
                HadithExplanationDataFields.EXPLANATION_PAGE,
                HadithExplanationDataFields.EXPLANATION_VOLUME,
            ],
            excludes: [],
        },
    });

    return fetch(`/api/reactivesearchproxy/${explanationIndex}/_search`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: jsonBody,
    })
        .then((res) => res.json())
        .then((json) => {
            const {
                hits: { hits },
            } = ExplanationsReferences.parse(json);

            const explanationsReferences: BookReferenceWithHadith[] = hits.map(
                (hit) => ({
                    page: hit._source.explanation.explanation_page ?? 0,
                    volume: hit._source.explanation.explanation_volume ?? 0,
                    book_name: hit._source.explanation.explanation_book_name,
                    id: hit._id,
                    hadith_book_name: hit._source.hadith.book_name,
                    hadith_number: hit._source.hadith.number[0],
                    label: `${hit._source.hadith.number.join(', ')} - ${
                        hit._source.hadith.chapter
                    }`,
                    group: hit._source.explanation.explanation_book_name,
                    editionsRefs: [],
                }),
            );
            return explanationsReferences.sort((a, b) => {
                if (a.group !== b.group) {
                    // sort on group! YOU ALWAYS HAVE TO DO THIS! so grouped entries
                    // actually end up contiguous / next to each other
                    const explBookNameA =
                        a.group as keyof typeof explanation_authenticity_order;
                    const explBookNameB =
                        b.group as keyof typeof explanation_authenticity_order;
                    if (
                        explanation_authenticity_order[explBookNameA] >
                        explanation_authenticity_order[explBookNameB]
                    ) {
                        return 1;
                    } else if (
                        explanation_authenticity_order[explBookNameB] >
                        explanation_authenticity_order[explBookNameA]
                    ) {
                        return -1;
                    }
                }

                // same group (explanation book) now sort on hadith book
                const hadithBookNameA =
                    a.hadith_book_name as keyof typeof authenticity_order;
                const hadithBookNameB =
                    b.hadith_book_name as keyof typeof authenticity_order;
                if (
                    authenticity_order[hadithBookNameA] >
                    authenticity_order[hadithBookNameB]
                ) {
                    return 1;
                } else if (
                    authenticity_order[hadithBookNameB] >
                    authenticity_order[hadithBookNameA]
                ) {
                    return -1;
                } else {
                    // same hadith book
                    return a.hadith_number < b.hadith_number ? -1 : 1;
                }
            });
        });
}

const size = 5;

const HadithExplanationView = ({
    hadith,
    narrationsType,
    setNarrationsType,
}: HadithExplanationViewProps) => {
    const { t } = useTranslation('library');
    const router = useRouter();
    const [hasSelectedFilters, setHasSelectedFilters] = useState(false);

    const [totalResults, setTotalResults] = useState(0);

    const resultStatsLabel = hasSelectedFilters
        ? t('explanations_count_with_filters')
        : t('explanations_count');

    const [explanationsReferences, setExplanationsReferences] = useState<
        BookReference[]
    >([]);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const resultStatsMessage = isMobile ? (
        <Typography variant="body1" color="primary">
            {`${t('explanations_count')}: ${numberWithCommas(totalResults)}`}
        </Typography>
    ) : (
        <Typography variant="body1" color="primary">
            {`${resultStatsLabel}: ${numberWithCommas(totalResults)}`}
        </Typography>
    );
    const [collapsed, setCollapsed] = useState(false);

    const narrations =
        narrationsType === 'all'
            ? hadith.raw_narrations
                  .concat(hadith.extended_narrations)
                  .concat(hadith.hadith_id)
            : narrationsType === 'roads'
            ? hadith.raw_narrations.concat(hadith.hadith_id)
            : hadith.extended_narrations;

    return (
        <SearchBase
            app={explanationIndex}
            setSearchParams={(newURL) => {
                router.push(newURL, undefined, {
                    shallow: true,
                });
            }}
        >
            {/* constrain the other filters to the hadith id */}
            <ReactiveComponent
                componentId={HadithExplanationViewFilters.HADITHS_IDS}
                customQuery={() => ({
                    query: {
                        terms: {
                            [HadithExplanationDataFields.HADITH_HADITH_ID]:
                                narrations,
                        },
                    },
                })}
            />
            <ReactiveSearchLayout
                hasSelectedFilters={hasSelectedFilters}
                collapsed={collapsed}
                setCollapsed={setCollapsed}
                filters={(setDrawerFiltersOpen) => (
                    <HadithExplanationFilters
                        setDrawerFiltersOpen={setDrawerFiltersOpen}
                    />
                )}
                resultStatsMessage={resultStatsMessage}
                results={(handleFilter) => (
                    <>
                        <FiltersSelected />
                        <Box
                            sx={
                                isMobile
                                    ? {
                                          display: 'flex',
                                          justifyContent: 'center',
                                          mt: 1,
                                      }
                                    : {
                                          mt: 4,
                                          display: 'flex',
                                          justifyContent: 'start',
                                      }
                            }
                        >
                            <NarrationsTypeToggle
                                narrationsType={narrationsType}
                                setNarrationsType={setNarrationsType}
                                disabledShawahed={
                                    !hadith.hasExtendedExplanation
                                }
                                disabledShawahedMessage={t(
                                    'extended_explanations_disabled',
                                )}
                            />
                        </Box>
                        <ResultItemList
                            hasSelectedFilters={hasSelectedFilters}
                            resultStatsMessage={resultStatsMessage}
                            componentId={HadithExplanationViewFilters.RESULT}
                            dataField={
                                HadithExplanationDataFields.HADITH_HADITH_SERIAL_ID
                            }
                            onData={(data) =>
                                setTotalResults(
                                    data.resultStats.numberOfResults,
                                )
                            }
                            dependencies={{
                                and: [
                                    HadithExplanationViewFilters.HADITHS_IDS,
                                    HadithExplanationViewFilters.BOOKS_FILTERS,
                                    HadithExplanationViewFilters.EXPLANATION_BOOKS_FILTERS,
                                    HadithExplanationViewFilters.EXPLANATION_BOOKS_AUTHORS_FILTERS,
                                    HadithExplanationViewFilters.CHAPTERS_FILTERS,
                                    HadithExplanationViewFilters.SUB_CHAPTERS_FILTERS,
                                ],
                            }}
                            defaultQuery={() => {
                                return {
                                    track_total_hits: true,
                                    sort: [
                                        {
                                            [HadithExplanationDataFields.HADITH_EXPLANATION_AUTHENTICITY_ORDER]:
                                                {
                                                    order: 'asc',
                                                },
                                        },
                                        {
                                            [HadithExplanationDataFields.HADITH_HADITH_SERIAL_ID]:
                                                {
                                                    order: 'asc',
                                                },
                                        },
                                    ],
                                    aggs: {
                                        unique_hadith_ids: {
                                            terms: {
                                                field: HadithExplanationDataFields.HADITH_HADITH_ID,
                                                size: 500,
                                            },
                                        },
                                        unique_chapters: {
                                            cardinality: {
                                                field: HadithExplanationDataFields.HADITH_CHAPTER,
                                            },
                                        },
                                        unique_sub_chapters: {
                                            cardinality: {
                                                field: HadithExplanationDataFields.HADITH_SUB_CHAPTER,
                                            },
                                        },
                                    },
                                };
                            }}
                            includeFields={[
                                HadithExplanationDataFields.HADITH,
                                HadithExplanationDataFields.EXPLANATION,
                            ]}
                            size={size}
                            resultItems={(items) => {
                                const { data, loading } =
                                    ResultItems.parse(items);

                                const hadithExplanations = data.map((item) => (
                                    <HadithExplanationResultItem
                                        key={uuidv4()}
                                        initNavPointId={item._id}
                                        explanation_object={item}
                                        quickNavPoints={explanationsReferences}
                                    />
                                ));

                                if (loading && data.length > size) {
                                    return hadithExplanations.concat(
                                        Array(size)
                                            .fill(0)
                                            .map(() => (
                                                <HadithSkeleton
                                                    key={uuidv4()}
                                                    withActions={false}
                                                />
                                            )),
                                    );
                                } else if (loading) {
                                    // filtering, not paginating, since we have one page only (data.length <= size)
                                    // return a single page of skeletons, no Hadiths
                                    // the *2 heuristic is because a general skeleton is shorter than a Hadith, not perfect
                                    return Array(size * 2)
                                        .fill(0)
                                        .map(() => (
                                            <HadithSkeleton
                                                key={uuidv4()}
                                                withActions={false}
                                            />
                                        ));
                                }

                                return hadithExplanations.concat(
                                    <MoreResultsNotice
                                        key="more-results-notice"
                                        moreResults={
                                            hadithExplanations.length <
                                            totalResults
                                        }
                                    />,
                                );
                            }}
                            loader={Array(size)
                                .fill(0)
                                .map(() => (
                                    <HadithSkeleton
                                        key={uuidv4()}
                                        withActions={false}
                                    />
                                ))}
                            stream={true}
                            showPagination={false}
                            onFilterClick={handleFilter}
                            scrollTarget={'main-modal'}
                        />

                        <StateProvider
                            includeKeys={[
                                'value',
                                'hits',
                                'aggregations',
                                'error',
                                'query',
                                'aggs',
                                'size',
                                'includeFields',
                                'react',
                                'sort',
                            ]}
                            //Counts coming from aggregations query
                            onChange={(state, nextState: NextState) => {
                                checkFiltersSelected(
                                    { ...state, ...nextState },
                                    hasSelectedFilters,
                                    setHasSelectedFilters,
                                    'expl_',
                                );

                                if (nextState?.expl_result?.hits?.total) {
                                    getExplanationsReferences(
                                        nextState,
                                        nextState['expl_result'].hits.total,
                                    ).then((references) =>
                                        setExplanationsReferences(references),
                                    );
                                }
                            }}
                        />
                    </>
                )}
            />
            {/* TODO[chammaaomar]: Get this to work for scrollable container */}
            {/* <ScrollToTop smooth top={scrollToTopMin} containerId="main-modal" /> */}
        </SearchBase>
    );
};

export default HadithExplanationView;
