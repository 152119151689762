import FilterList from '@components/filters/FilterList';

import useTranslation from '@hooks/useTranslation';
import { Box, Divider } from '@mui/material';

import { ReferencesDataFields } from 'constants/datafields';
import { ReferencesFilters } from 'constants/filters';

interface IReferencesPageFiltersProps {
    filters: {
        [key in ReferencesFilters]?: string[];
    };
    setDrawerFiltersOpen: (open: boolean) => void;
}

const ReferencesPageFilters = ({
    setDrawerFiltersOpen,
}: IReferencesPageFiltersProps) => {
    const { t } = useTranslation('references');

    return (
        <Box>
            <FilterList
                label={t('book_type_filter_header')}
                componentId={ReferencesFilters.BOOK_FILTER}
                dataField={ReferencesDataFields.TYPE_KEYWORD}
                size={10} //keep it high to avoid the search
                setDrawerFiltersOpen={setDrawerFiltersOpen}
                dependencies={{
                    and: [
                        ReferencesFilters.CONTENT_SEARCH,
                        ReferencesFilters.AUTHOR_FILTER,
                        ReferencesFilters.STATUS_FILTER,
                    ],
                }}
                showSelectAll={true}
                selectAllLabel={t('select_all')}
                statePrefix="ref_"
            />
            <Divider sx={{ my: '10px' }} />
            <FilterList
                label={t('author_filter_header')}
                componentId={ReferencesFilters.AUTHOR_FILTER}
                dataField={ReferencesDataFields.AUTHOR_KEYWORD}
                size={200} //keep it high to avoid the search in server side for something this simple
                searchType="memory"
                searchPlaceholder={t('search_for_an_author')}
                setDrawerFiltersOpen={setDrawerFiltersOpen}
                dependencies={{
                    and: [
                        ReferencesFilters.CONTENT_SEARCH,
                        ReferencesFilters.BOOK_FILTER,
                        ReferencesFilters.STATUS_FILTER,
                    ],
                }}
                statePrefix="ref_"
            />
            <Divider sx={{ my: '10px' }} />
            <FilterList
                label={t('status_filter_header')}
                componentId={ReferencesFilters.STATUS_FILTER}
                dataField={ReferencesDataFields.STATUS_KEYWORD}
                size={5}
                setDrawerFiltersOpen={setDrawerFiltersOpen}
                dependencies={{
                    and: [
                        ReferencesFilters.CONTENT_SEARCH,
                        ReferencesFilters.BOOK_FILTER,
                        ReferencesFilters.AUTHOR_FILTER,
                    ],
                }}
                showSelectAll={true}
                selectAllLabel={t('select_all')}
                statePrefix="ref_"
            />
        </Box>
    );
};

export default ReferencesPageFilters;
