import { type SVGProps } from 'react';

const WordSvg = (props: SVGProps<SVGSVGElement> & { disabled: boolean }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        id="word"
        {...props}
    >
        <path
            fill={props.disabled ? '#808080' : '#303c42'}
            d="M23.5 3H14V.5a.502.502 0 0 0-.595-.491l-13 2.5A.5.5 0 0 0 0 3v18a.5.5 0 0 0 .405.491l13 2.5a.495.495 0 0 0 .413-.106A.495.495 0 0 0 14 23.5V21h9.5a.5.5 0 0 0 .5-.5v-17a.5.5 0 0 0-.5-.5z"
        ></path>
        <path
            fill={props.disabled ? '#808080' : '#1565c0'}
            d="M13 22.895 1 20.587V3.413l12-2.307z"
        ></path>
        <path
            fill="#fff"
            d="M23 20h-9v-1.5h7.5a.5.5 0 0 0 0-1H14v-2h7.5a.5.5 0 0 0 0-1H14v-2h7.5a.5.5 0 0 0 0-1H14v-2h7.5a.5.5 0 0 0 0-1H14v-2h7.5a.5.5 0 0 0 0-1H14V4h9v16zM10.629 7.517a.498.498 0 0 0-.612.354l-1.534 5.751L6.98 8.363A.499.499 0 0 0 6.505 8c-.195.04-.417.141-.483.353L4.55 13.138 3.485 8.879a.5.5 0 0 0-.97.242l1.5 6a.501.501 0 0 0 .472.379.464.464 0 0 0 .491-.353l1.505-4.889 1.536 5.38a.5.5 0 0 0 .48.363h.004a.5.5 0 0 0 .479-.371l2.001-7.501a.499.499 0 0 0-.354-.612z"
        ></path>
        <linearGradient
            id="a"
            x1="10"
            x2="22.5"
            y1="8"
            y2="20.5"
            gradientUnits="userSpaceOnUse"
        >
            <stop offset="0" stopOpacity=".1"></stop>
            <stop offset="1" stopOpacity="0"></stop>
        </linearGradient>
        <path
            fill="url(#a)"
            d="M16.5 6.5H14v2h4.5l-2-2zM14 4v1.5h1.5L14 4zm8 8a.5.5 0 0 1-.5.5H14v2h7.5a.5.5 0 0 1 0 1H14v2h7.5a.5.5 0 0 1 0 1H14V20h9v-7l-1-1zm-2.5-2.5H14v2h7.5l-2-2z"
        ></path>
        <linearGradient
            id="b"
            x1="-1.442"
            x2="25.059"
            y1="5.982"
            y2="18.34"
            gradientUnits="userSpaceOnUse"
        >
            <stop offset="0" stopColor="#fff" stopOpacity=".2"></stop>
            <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <path
            fill="url(#b)"
            d="M23.5 3H14V.5a.502.502 0 0 0-.595-.491l-13 2.5A.5.5 0 0 0 0 3v18a.5.5 0 0 0 .405.491l13 2.5a.495.495 0 0 0 .413-.106A.495.495 0 0 0 14 23.5V21h9.5a.5.5 0 0 0 .5-.5v-17a.5.5 0 0 0-.5-.5z"
        ></path>
    </svg>
);
export default WordSvg;
