import { useState } from 'react';

import { BookPreview } from '@components/bookpreview';
import { booksConfig } from '@components/bookpreview/bookConfigs';

import { useCommonContext } from '@hooks/commonContext';
import { useFontSize } from '@hooks/fontSizeContext';
import { useTashkeel } from '@hooks/tashkeelContext';
import useTranslation from '@hooks/useTranslation';
import PreviewIcon from '@mui/icons-material/Preview';
import ReportGmailerrorred from '@mui/icons-material/ReportGmailerrorred';
import { Divider, Typography, useTheme } from '@mui/material';
import { removeTashkeel } from '@utils/tashkeel';

import {
    HadithActions,
    IHadithLeftAction,
} from '../HadithResultItem/HadithActions';
import {
    HadithCommentaryContainer,
    HadithCommentaryHeaderInfo,
    HadithCommentaryHeaderInfoDetails,
    HadithCommentaryHeaderInfoNumber,
    HadithCommentaryTextContainer,
    HadithCommentaryTitleContainer,
} from './styled';
import parse, { HTMLReactParserOptions, domToReact } from 'html-react-parser';
import { analytics } from 'lib/analytics';
import { BookReference, Commentary } from 'shared/interfaces/hadith';
import { colors } from 'theme';

interface HadithCommentaryResultItemProps {
    key: string;
    hadithId: string;
    commentary: Commentary;
    commentariesReferences: BookReference[];
    reportErrorId: string;
}

const commentaryOptions: HTMLReactParserOptions = {
    replace: (node) => {
        if (!('name' in node && 'attribs' in node)) {
            // only process 'Element' node
            return;
        }
        const { name, attribs, children } = node;
        // Matn component
        if (name === 'a' && attribs.class === 'matn') {
            return (
                <span style={{ color: '#a00000', fontWeight: 500 }}>
                    {domToReact(children, commentaryOptions)}
                </span>
            );
        }

        if (name === 'a' && attribs.class === 'rawi') {
            return (
                <span style={{ color: '#121212', fontWeight: 600 }}>
                    {domToReact(children, commentaryOptions)}
                </span>
            );
        }
    },
};

const HadithCommentaryResultItem = ({
    key,
    hadithId,
    commentary,
    commentariesReferences,
    reportErrorId,
}: HadithCommentaryResultItemProps) => {
    const { id, book_name, author_name, full_text_html, page, volume } =
        commentary;
    const [openPreviewModal, setOpenPreviewModal] = useState<boolean>(false);

    const { fontSize } = useFontSize();
    const { tashkeelEnabled } = useTashkeel();

    const { setErrorData } = useCommonContext();

    const { t } = useTranslation('library');
    const theme = useTheme();

    const pageVolumeContents: string[] = [
        volume ? `${t('volume')}: ${volume}` : '',
        page ? `${t('page')}: ${page}` : '',
    ].filter((str) => str);

    const leftActions: IHadithLeftAction[] = [
        {
            icon: PreviewIcon,
            action: () => {
                analytics('book-preview', {
                    type: 'hadith-commentary',
                    id: `${id}`,
                    hadith_id: hadithId,
                    source: 'hadith-commentary-result-item',
                });
                setOpenPreviewModal(true);
            },
            isClickable: !!(booksConfig[book_name] && page && volume),
            notClickableMessage: t('preview_not_clickable'),
        },
        {
            icon: ReportGmailerrorred,
            action: () => {
                setErrorData({
                    elem_id: reportErrorId,
                    type: 'hadith-commentary',
                    source: 'user-report',
                });
            },
            isClickable: true,
        },
    ];

    return (
        <HadithCommentaryContainer
            key={key}
            elevation={0}
            variant="outlined"
            sx={{ margin: { xs: '10px 0', md: '10px 0' }, width: '100%' }}
        >
            <HadithCommentaryHeaderInfo>
                <HadithCommentaryHeaderInfoNumber>
                    <Typography>{id}</Typography>
                </HadithCommentaryHeaderInfoNumber>
                <HadithCommentaryHeaderInfoDetails>
                    {pageVolumeContents.map((content, i) => (
                        <Typography
                            key={content}
                            sx={{
                                padding: '5px',
                                borderLeft:
                                    i > 0
                                        ? `1px solid  ${theme.palette.divider}`
                                        : undefined,
                                height: '100%',
                                color: colors.primary,
                            }}
                        >
                            {content}
                        </Typography>
                    ))}
                </HadithCommentaryHeaderInfoDetails>
            </HadithCommentaryHeaderInfo>

            {booksConfig[book_name] && volume && page && openPreviewModal && (
                <BookPreview
                    initNavPointId={`${id}`}
                    open={openPreviewModal}
                    setOpen={setOpenPreviewModal}
                    quickNavPoints={commentariesReferences}
                    // no editions yet
                    initEditionsRefs={[]}
                />
            )}

            <HadithCommentaryTitleContainer dir="rtl">
                <Typography fontSize={fontSize.title} fontWeight={500}>
                    {author_name}
                </Typography>
                <Typography
                    fontSize={fontSize.subtitle}
                    color="secondary"
                    textAlign="start"
                >
                    {book_name}&nbsp;
                </Typography>
            </HadithCommentaryTitleContainer>
            <Divider />

            <HadithCommentaryTextContainer>
                <Typography
                    variant="body1"
                    fontSize={fontSize.body}
                    lineHeight={'36px'}
                    sx={{ color: '#121212' }}
                >
                    {tashkeelEnabled
                        ? parse(full_text_html, commentaryOptions)
                        : parse(
                              removeTashkeel(full_text_html),
                              commentaryOptions,
                          )}
                </Typography>
            </HadithCommentaryTextContainer>
            <HadithActions leftActions={leftActions} rightActions={[]} />
        </HadithCommentaryContainer>
    );
};

export default HadithCommentaryResultItem;
