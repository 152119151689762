import { NarrationsDataFields } from '@constants/datafields';
import { SORT_OPTIONS } from '@constants/sorts';

import { SearchType } from 'shared/interfaces/hadith';

interface SortQuery {
    [key: string]: {
        order: 'asc' | 'desc';
    };
}

export const getDefaultQuerySort = (
    sortBy: SORT_OPTIONS,
    searchType: SearchType = 'phrase',
) => {
    if (searchType === 'token') {
        return [
            '_score',
            {
                [NarrationsDataFields.AUTHENTICITY_ORDER]: {
                    order: 'asc',
                },
            },
            {
                [NarrationsDataFields.HADITH_SERIAL_ID]: {
                    order: 'asc',
                },
            },
        ];
    }

    // for phrase / exact search, we allow multiple options
    let res = new Array<SortQuery>();
    switch (sortBy) {
        case SORT_OPTIONS.AUTHENTICITY:
            res.push({
                [NarrationsDataFields.AUTHENTICITY_ORDER]: {
                    order: 'asc',
                },
            });
            break;
        case SORT_OPTIONS.OLDEST_TO_NEWEST:
        case SORT_OPTIONS.NEWEST_TO_OLDEST:
            res.push({
                [NarrationsDataFields.TIME_ORDER]: {
                    order:
                        sortBy === SORT_OPTIONS.NEWEST_TO_OLDEST
                            ? 'desc'
                            : 'asc',
                },
            });
            break;
    }
    res.push({
        [NarrationsDataFields.HADITH_SERIAL_ID]: {
            order: 'asc',
        },
    });
    return res;
};

export const getIncludeFields = () => {
    return [
        NarrationsDataFields.HADITH_ID,
        NarrationsDataFields.HADITH_SERIAL_ID,
        NarrationsDataFields.NUMBER,
        NarrationsDataFields.BOOK_NAME,
        NarrationsDataFields.TYPE,
        NarrationsDataFields.NARRATORS,
        NarrationsDataFields.EXT_NARRATIONS,
        NarrationsDataFields.RAW_NARRATIONS,
        NarrationsDataFields.NARRATIONS_NUMBERS,
        NarrationsDataFields.HAS_EXPLANATION,
        NarrationsDataFields.HAS_EXTENDED_EXPLANATION,
        NarrationsDataFields.HAS_COMMENTARY,
        NarrationsDataFields.HAS_RULING,
        NarrationsDataFields.HAS_EXTENDED_RULING,
        NarrationsDataFields.CHAPTER,
        NarrationsDataFields.SUB_CHAPTER,
        NarrationsDataFields.PAGE,
        NarrationsDataFields.VOLUME,
        NarrationsDataFields.MATN_WITH_TASHKEEL,

        NarrationsDataFields.RULINGS,
        NarrationsDataFields.RULINGS_RULER,
        NarrationsDataFields.RULINGS_RULING,
        NarrationsDataFields.RULINGS_BOOK_NAME,
        NarrationsDataFields.RULINGS_PAGE,
        NarrationsDataFields.RULINGS_VOLUME,

        NarrationsDataFields.HADITH_AMBIGUOUS,

        NarrationsDataFields.EDITIONS,

        // we fetch all three fields here, but the backend only return 'hadith'
        // and populates it with the appropriate field either
        // 'hadith' or 'hadith.tokenized' or 'hadith.exact'
        // depending on the matched query
        NarrationsDataFields.HADITH,
        NarrationsDataFields.HADITH_TOKENIZED,
        NarrationsDataFields.HADITH_EXACT,
    ];
};
