import { Skeleton } from '@mui/material';

const FilterSkeleton = ({ size }: { size: number }) => {
    return (
        <div style={{ width: '100%' }}>
            {[...Array(size)].map((e, index) => (
                <div
                    key={'skel_' + index}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        flexWrap: 'nowrap',
                        justifyContent: 'flex-start',
                        gap: '10px',
                    }}
                >
                    <Skeleton
                        variant="text"
                        width={20}
                        sx={{ fontSize: '18px' }}
                    />
                    <Skeleton
                        variant="text"
                        sx={{ fontSize: '18px', flexGrow: 1 }}
                    />
                </div>
            ))}
        </div>
    );
};

export default FilterSkeleton;
