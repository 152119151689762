import { type ReactNode } from 'react';

interface ConditionalWrapperProps {
    condition: boolean;
    wrap: (children: ReactNode) => JSX.Element;
    children: JSX.Element;
}

const ConditionalWrapper = ({
    condition,
    wrap,
    children,
}: ConditionalWrapperProps) => (condition ? wrap(children) : children);

export { ConditionalWrapper };
