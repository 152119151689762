import FilterList from '@components/filters/FilterList';

import useTranslation from '@hooks/useTranslation';
import { Divider } from '@mui/material';
import {
    HadithBook,
    HadithExplanationBook,
    sortByAuthenticity,
    sortByExplanationAuthenticity,
} from '@utils/sortBooks';


import { HadithExplanationDataFields } from 'constants/datafields';
import { HadithExplanationViewFilters } from 'constants/filters';
import { IDataListItem } from 'shared/interfaces/hadith';

const HadithExplanationFilters = ({
    setDrawerFiltersOpen,
}: {
    setDrawerFiltersOpen: (open: boolean) => void;
}) => {
    const { t } = useTranslation('library');

    return (
        <>
            <FilterList
                label={t('books')}
                componentId={
                    HadithExplanationViewFilters.BOOKS_FILTERS
                }
                dataField={HadithExplanationDataFields.HADITH_BOOK_NAME}
                dependencies={{
                    and: [
                        HadithExplanationViewFilters.EXPLANATION_BOOKS_FILTERS,
                        HadithExplanationViewFilters.EXPLANATION_BOOKS_AUTHORS_FILTERS,
                        HadithExplanationViewFilters.HADITHS_IDS,
                        HadithExplanationViewFilters.CHAPTERS_FILTERS,
                        HadithExplanationViewFilters.SUB_CHAPTERS_FILTERS,
                    ],
                }}
                setDrawerFiltersOpen={setDrawerFiltersOpen}
                showSelectAll={true}
                selectAllLabel={t('select_all')}
                manipulateListData={(data: IDataListItem[]) =>
                    sortByAuthenticity(
                        data as HadithBook[],
                    )
                }
                statePrefix="expl_"
            />
            <Divider sx={{ my: '10px' }} />

            {/* Search By Explanation Books */}
            <FilterList
                label={t('explanation_books')}
                componentId={
                    HadithExplanationViewFilters.EXPLANATION_BOOKS_FILTERS
                }
                dataField={HadithExplanationDataFields.EXPLANATION_BOOK_NAME}
                dependencies={{
                    and: [
                        HadithExplanationViewFilters.BOOKS_FILTERS,
                        HadithExplanationViewFilters.EXPLANATION_BOOKS_AUTHORS_FILTERS,
                        HadithExplanationViewFilters.HADITHS_IDS,
                        HadithExplanationViewFilters.CHAPTERS_FILTERS,
                        HadithExplanationViewFilters.SUB_CHAPTERS_FILTERS,
                    ],
                }}
                setDrawerFiltersOpen={setDrawerFiltersOpen}
                showSelectAll={true}
                selectAllLabel={t('select_all')}
                manipulateListData={(data: IDataListItem[]) =>
                    sortByExplanationAuthenticity(
                        data as HadithExplanationBook[],
                    )
                }
                statePrefix="expl_"
            />
            <Divider sx={{ my: '10px' }} />

            {/* Search By Explanation Authors */}
            <FilterList
                label={t('explanation_authors')}
                componentId={
                    HadithExplanationViewFilters.EXPLANATION_BOOKS_AUTHORS_FILTERS
                }
                setDrawerFiltersOpen={setDrawerFiltersOpen}
                dataField={HadithExplanationDataFields.EXPLANATION_BOOK_AUTHOR}
                dependencies={{
                    and: [
                        HadithExplanationViewFilters.BOOKS_FILTERS,
                        HadithExplanationViewFilters.EXPLANATION_BOOKS_FILTERS,
                        HadithExplanationViewFilters.HADITHS_IDS,
                        HadithExplanationViewFilters.CHAPTERS_FILTERS,
                        HadithExplanationViewFilters.SUB_CHAPTERS_FILTERS,
                    ],
                }}
                showSelectAll={true}
                selectAllLabel={t('select_all')}
                statePrefix="expl_"
            />

            <Divider sx={{ my: '10px' }} />

            {/* Search By Chapters */}
            <FilterList
                label={t('chapters')}
                countAggregateIdentifier={'unique_chapters'}
                componentId={HadithExplanationViewFilters.CHAPTERS_FILTERS}
                dataField={HadithExplanationDataFields.HADITH_CHAPTER}
                dependencies={{
                    and: [
                        HadithExplanationViewFilters.BOOKS_FILTERS,
                        HadithExplanationViewFilters.EXPLANATION_BOOKS_AUTHORS_FILTERS,
                        HadithExplanationViewFilters.EXPLANATION_BOOKS_FILTERS,
                        HadithExplanationViewFilters.HADITHS_IDS,
                        HadithExplanationViewFilters.SUB_CHAPTERS_FILTERS,
                    ],
                }}
                setDrawerFiltersOpen={setDrawerFiltersOpen}
                searchType="server"
                searchComponentId={HadithExplanationViewFilters.CHAPTERS_SEARCH}
                searchField={
                    HadithExplanationDataFields.HADITH_CHAPTER_NORMALIZED
                }
                searchPlaceholder={t('search_for_a_chapter')}
                statePrefix="expl_"
            />

            <Divider sx={{ my: '10px' }} />

            {/* Search By SubChapters */}
            <FilterList
                label={t('sub_chapters')}
                countAggregateIdentifier={'unique_sub_chapters'}
                componentId={HadithExplanationViewFilters.SUB_CHAPTERS_FILTERS}
                dataField={HadithExplanationDataFields.HADITH_SUB_CHAPTER}
                dependencies={{
                    and: [
                        HadithExplanationViewFilters.BOOKS_FILTERS,
                        HadithExplanationViewFilters.EXPLANATION_BOOKS_AUTHORS_FILTERS,
                        HadithExplanationViewFilters.EXPLANATION_BOOKS_FILTERS,
                        HadithExplanationViewFilters.HADITHS_IDS,
                        HadithExplanationViewFilters.CHAPTERS_FILTERS,
                    ],
                }}
                setDrawerFiltersOpen={setDrawerFiltersOpen}
                searchType="server"
                searchComponentId={
                    HadithExplanationViewFilters.SUB_CHAPTERS_SEARCH
                }
                searchField={
                    HadithExplanationDataFields.HADITH_SUB_CHAPTER_NORMALIZED
                }
                searchPlaceholder={t('search_for_a_sub_chapter')}
                statePrefix="expl_"
            />
        </>
    );
};

export default HadithExplanationFilters;
