import {
    NavigateBefore as NavigateBeforeIcon,
    NavigateNext as NavigateNextIcon,
} from '@mui/icons-material';
import {
    Box,
    Button,
    MenuItem,
    Select,
    SelectChangeEvent,
} from '@mui/material';

import type { Ambiguous } from 'shared/interfaces/hadith';

interface FullAmbiguousListProps {
    selectedAmbiguousId?: string;
    ambiguities: Ambiguous[];
    handleAmbiguousChange: (
        id: string | number,
    ) => Promise<boolean> | undefined;
}

const HadithAmbiguitiesList = ({
    ambiguities,
    handleAmbiguousChange,
    selectedAmbiguousId,
}: FullAmbiguousListProps) => {
    const currentAmbiguousIndex = ambiguities.findIndex(
        (n) => selectedAmbiguousId === n.reference_id,
    );

    if (!selectedAmbiguousId) {
        return null;
    }

    const nextAmbiguous = ambiguities[currentAmbiguousIndex - 1];
    const prevAmbiguous = ambiguities[currentAmbiguousIndex + 1];

    const ambiguitiesDropdown = document.getElementById('ambiguities-dropdown');
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Button
                sx={{ ml: 1, minWidth: { xs: '10px' } }}
                disabled={
                    ambiguities.findIndex(
                        (ambiguous) => selectedAmbiguousId === ambiguous.reference_id,
                    ) === 0
                }
                variant="outlined"
                onClick={() => {
                    handleAmbiguousChange(nextAmbiguous.reference_id);
                }}
            >
                <NavigateNextIcon />
            </Button>
            <Select
                sx={{
                    ml: 1,
                    mr: 1,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'wrap',
                    maxWidth: { xs: '250px', sm: '400px' },
                }}
                dir="rtl"
                labelId="ambiguities-dropdown-label"
                id="ambiguities-dropdown"
                value={selectedAmbiguousId}
                label="ambiguities"
                onChange={(event: SelectChangeEvent) => {
                    const refreshed = handleAmbiguousChange(event.target.value);
                    if (refreshed) {
                        refreshed.then(() => {
                            setTimeout(() => ambiguitiesDropdown?.blur(), 100);
                        });
                    }
                }}
            >
                {ambiguities.map((ambiguous) => {
                    return (
                        <MenuItem
                            key={ambiguous.reference_id}
                            value={ambiguous.reference_id}
                            sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'wrap',
                            }}
                        >
                            {ambiguous['reference']}
                        </MenuItem>
                    );
                })}
            </Select>
            <Button
                sx={{ mr: 1, minWidth: { xs: '10px' } }}
                disabled={
                    ambiguities.findIndex(
                        (ambiguous) => selectedAmbiguousId === ambiguous.reference_id,
                    ) ===
                    ambiguities.length - 1
                }
                onClick={() => {
                    handleAmbiguousChange(prevAmbiguous.reference_id);
                }}
                variant="outlined"
            >
                <NavigateBeforeIcon />
            </Button>
        </Box>
    );
};

export default HadithAmbiguitiesList;
