import { type Dispatch, type SetStateAction, useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import FilterList from '@components/filters/FilterList';
import NumberSearch from '@components/filters/NumberSearch';

import { env } from '@constants/env';
import { SORT_OPTIONS } from '@constants/sorts';
import useTranslation from '@hooks/useTranslation';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { Box, Checkbox, Divider, Typography } from '@mui/material';
import { NarratorColor, handleNarratorColorChange } from '@utils/narratorColor';
import {
    HadithBook,
    sortByAuthenticity,
    sortByEarliest,
    sortByLatest,
} from '@utils/sortBooks';

import { NarrationsDataFields } from 'constants/datafields';
import { SearchFilters } from 'constants/filters';
import { IDataListItem } from 'shared/interfaces/hadith';

interface IMainSearchFiltersProps {
    collapsed?: boolean;
    setCollapsed: Dispatch<SetStateAction<boolean>>;
    narratorsColors: NarratorColor[];
    setNarratorsColors: (colors: NarratorColor[]) => void;
    sortBy?: SORT_OPTIONS;
    setLoadingCounts: (loading: boolean) => void;
    showAggregateCounts: boolean;
    setDrawerFiltersOpen: (open: boolean) => void;
}

function NarratorSearch({
    narratorsColors,
    setNarratorsColors,
    setLoadingCounts,
    showAggregateCounts,
    setDrawerFiltersOpen,
}: {
    setNarratorsColors: any;
    narratorsColors: any;
    setLoadingCounts: (loading: boolean) => void;
    showAggregateCounts: boolean;
    setDrawerFiltersOpen: (open: boolean) => void;
}) {
    const { t } = useTranslation('library');
    const router = useRouter();
    // default for the 'narrators linked in chain' is true
    const initToggle =
        router.query['narrators_linked'] !== 'false' ? true : false;
    const [narratorsLinkedSwitch, setNarratorsLinkedSwitch] =
        useState(initToggle);

    useEffect(() => {
        const toggleFromUrl =
            router.query.narrators_linked !== 'false' ? true : false;
        setNarratorsLinkedSwitch(toggleFromUrl);
    }, [router.query.narrators_linked]);

    return (
        <FilterList
            showAggregateCounts={showAggregateCounts}
            statePrefix=""
            label={t('narrators')}
            countAggregateIdentifier={'unique_narrators'}
            componentId={SearchFilters.NARRATORS_FILTERS}
            dataField={NarrationsDataFields.NARRATORS_FULL_NAMES}
            nestedField={NarrationsDataFields.NARRATORS}
            nestedFieldFull={NarrationsDataFields.NARRATORS_FULL_NAME}
            searchType="server-nested-field"
            searchComponentId={SearchFilters.NARRATORS_SEARCH}
            searchField={NarrationsDataFields.NARRATORS_FULL_NAME_NORMALIZED}
            searchPlaceholder={t('search_for_a_narrator')}
            queryFormat={narratorsLinkedSwitch ? 'and' : 'or'}
            setDrawerFiltersOpen={setDrawerFiltersOpen}
            size={10}
            dependencies={
                narratorsLinkedSwitch
                    ? {
                          and: [
                              SearchFilters.SEARCH,
                              SearchFilters.BOOKS_FILTERS,
                              SearchFilters.NARRATORS_FILTERS,
                              SearchFilters.CHAPTERS_FILTERS,
                              SearchFilters.HADITH_TYPES_FILTERS,
                              SearchFilters.HADITH_NUMBER_SEARCH,
                              //   SearchFilters.HADITH_RULINGS_FILTERS,
                              SearchFilters.SUB_CHAPTERS_FILTERS,
                          ],
                      }
                    : {
                          and: [
                              SearchFilters.SEARCH,
                              SearchFilters.BOOKS_FILTERS,
                              SearchFilters.CHAPTERS_FILTERS,
                              SearchFilters.HADITH_TYPES_FILTERS,
                              SearchFilters.HADITH_NUMBER_SEARCH,
                              //   SearchFilters.HADITH_RULINGS_FILTERS,
                              SearchFilters.SUB_CHAPTERS_FILTERS,
                          ],
                      }
            }
            coloredItems={narratorsColors}
            setColoredItems={(newList) =>
                handleNarratorColorChange(
                    newList,
                    narratorsColors,
                    setNarratorsColors,
                )
            }
            setLoadingCounts={setLoadingCounts}
        >
            <>
                <Typography variant="body1" color="primary">
                    {t('narrators_linked_in_narration_chain')}
                </Typography>
                <Checkbox
                    size="small"
                    checked={narratorsLinkedSwitch}
                    onChange={(event) => {
                        router.push(
                            {
                                pathname: router.pathname,
                                query: {
                                    ...router.query,
                                    narrators_linked: event.target.checked,
                                },
                            },
                            undefined,
                            { shallow: true },
                        );
                        setNarratorsLinkedSwitch(event.target.checked);
                    }}
                    name="narratorsLinkedSwitch"
                    color="secondary"
                />
            </>
        </FilterList>
    );
}

export const MainSearchFilters = ({
    collapsed,
    setCollapsed,
    narratorsColors,
    setNarratorsColors,
    sortBy,
    setLoadingCounts,
    showAggregateCounts,

    setDrawerFiltersOpen,
}: IMainSearchFiltersProps) => {
    const { t } = useTranslation('library');
    const router = useRouter();

    const numberSearchHadithSerialId =
        router.query[SearchFilters.HADITH_NUMBER_SEARCH] ?? '0';

    const booksFilterTitle = t('books');
    const typesFilterTitle = t('hadith_type');

    return (
        <>
            {/* Search By Hadith Serial ID */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    width: '100%',
                }}
            >
                <Box
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'flex-start'}
                    width="100%"
                    mb={1}
                >
                    <KeyboardDoubleArrowRightIcon
                        onClick={() => setCollapsed(!collapsed)}
                        color="primary"
                        sx={{
                            backgroundColor: 'transparent',
                            mr: 1,
                            ':hover': {
                                cursor: 'pointer',
                            },
                            display: { xs: 'none', md: 'block' },
                        }}
                    />
                    <Typography
                        variant="body1"
                        color="primary"
                        sx={{
                            display: { xs: 'none', md: 'block' },
                        }}
                    >
                        {t('tasnif')}
                    </Typography>
                </Box>

                <Box
                    sx={{
                        display: {
                            xs: 'flex',
                            md: 'none',
                        },
                        flexDirection: 'row',
                        justifyContent: 'start',

                        width: '100%',
                    }}
                >
                    <NumberSearch
                        label={t('search_by_hadith_number')}
                        componentId={SearchFilters.HADITH_NUMBER_SEARCH}
                        dataField={
                            env === 'prod'
                                ? NarrationsDataFields.NUMBER
                                : NarrationsDataFields.HADITH_SERIAL_ID
                        }
                        initialHadithSerialId={
                            numberSearchHadithSerialId as string
                        }
                    />
                </Box>
            </Box>

            {/* Search By Books */}
            <Divider sx={{ my: 2 }} />
            <FilterList
                showAggregateCounts={showAggregateCounts}
                label={booksFilterTitle}
                size={23}
                componentId={SearchFilters.BOOKS_FILTERS}
                dataField={NarrationsDataFields.BOOK_NAME}
                statePrefix=""
                setDrawerFiltersOpen={setDrawerFiltersOpen}
                dependencies={{
                    and: [
                        SearchFilters.SEARCH,
                        SearchFilters.NARRATORS_FILTERS,
                        SearchFilters.CHAPTERS_FILTERS,
                        SearchFilters.HADITH_NUMBER_SEARCH,
                        SearchFilters.HADITH_TYPES_FILTERS,
                        SearchFilters.SUB_CHAPTERS_FILTERS,
                    ],
                }}
                showSelectAll
                selectAllLabel={t('select_all')}
                manipulateListData={(data: IDataListItem[]) => {
                    switch (sortBy) {
                        case SORT_OPTIONS.NEWEST_TO_OLDEST:
                            return sortByLatest(data as HadithBook[]);
                        case SORT_OPTIONS.OLDEST_TO_NEWEST:
                            return sortByEarliest(data as HadithBook[]);
                        default:
                            return sortByAuthenticity(data as HadithBook[]);
                    }
                }}
                setLoadingCounts={setLoadingCounts}
            />

            <Divider sx={{ my: 2 }} />
            {/* Search By Narrators */}
            <NarratorSearch
                showAggregateCounts={showAggregateCounts}
                narratorsColors={narratorsColors}
                setNarratorsColors={setNarratorsColors}
                setLoadingCounts={setLoadingCounts}
                setDrawerFiltersOpen={setDrawerFiltersOpen}
            />
            <Divider sx={{ my: 2 }} />
            {/* Search By Chapters */}
            <FilterList
                showAggregateCounts={showAggregateCounts}
                label={t('chapters')}
                countAggregateIdentifier={'unique_chapters'}
                componentId={SearchFilters.CHAPTERS_FILTERS}
                dataField={NarrationsDataFields.CHAPTER}
                statePrefix=""
                setDrawerFiltersOpen={setDrawerFiltersOpen}
                dependencies={{
                    and: [
                        SearchFilters.SEARCH,
                        SearchFilters.NARRATORS_FILTERS,
                        SearchFilters.BOOKS_FILTERS,
                        SearchFilters.HADITH_TYPES_FILTERS,
                        SearchFilters.HADITH_NUMBER_SEARCH,
                        SearchFilters.SUB_CHAPTERS_FILTERS,
                    ],
                }}
                size={10}
                searchType="server"
                searchComponentId={SearchFilters.CHAPTERS_SEARCH}
                searchField={NarrationsDataFields.CHAPTER_NORMALIZED}
                searchPlaceholder={t('search_for_a_chapter')}
                setLoadingCounts={setLoadingCounts}
            />
            <Divider sx={{ my: 2 }} />
            {/* Search By Sub Chapters */}
            <FilterList
                label={t('sub_chapters')}
                showAggregateCounts={showAggregateCounts}
                countAggregateIdentifier={'unique_sub_chapters'}
                componentId={SearchFilters.SUB_CHAPTERS_FILTERS}
                dataField={NarrationsDataFields.SUB_CHAPTER}
                statePrefix=""
                setDrawerFiltersOpen={setDrawerFiltersOpen}
                dependencies={{
                    and: [
                        SearchFilters.SEARCH,
                        SearchFilters.NARRATORS_FILTERS,
                        SearchFilters.BOOKS_FILTERS,
                        SearchFilters.HADITH_TYPES_FILTERS,
                        SearchFilters.HADITH_NUMBER_SEARCH,

                        SearchFilters.CHAPTERS_FILTERS,
                    ],
                }}
                size={10}
                searchType="server"
                searchComponentId={SearchFilters.SUB_CHAPTERS_SEARCH}
                searchField={NarrationsDataFields.SUB_CHAPTER_NORMALIZED}
                searchPlaceholder={t('search_for_a_sub_chapter')}
                setLoadingCounts={setLoadingCounts}
            />

            <Divider sx={{ my: 2 }} />
            {/* Search By Hadith Types */}
            <FilterList
                showAggregateCounts={showAggregateCounts}
                label={typesFilterTitle}
                componentId={SearchFilters.HADITH_TYPES_FILTERS}
                dataField={NarrationsDataFields.TYPE}
                statePrefix=""
                setDrawerFiltersOpen={setDrawerFiltersOpen}
                dependencies={{
                    and: [
                        SearchFilters.SEARCH,
                        SearchFilters.HADITH_NUMBER_SEARCH,
                        SearchFilters.BOOKS_FILTERS,
                        SearchFilters.NARRATORS_FILTERS,
                        SearchFilters.CHAPTERS_FILTERS,
                        SearchFilters.SUB_CHAPTERS_FILTERS,
                    ],
                }}
                size={4}
                setLoadingCounts={setLoadingCounts}
            />
        </>
    );
};
