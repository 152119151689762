import { useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import { CustomTooltip } from '@components/CustomTooltip';
import ExcelSvg from '@components/ExcelSvg';
import { NarrationsTypeToggle } from '@components/NarrationsTypeToggle';
import WordSvg from '@components/WordSvg';
import SearchBase from '@components/base/SearchBase';
import FiltersSelected from '@components/filters/elements/FiltersSelected';
import CopyHadithModal from '@components/modal/Modal/CopyHadithModal';
import { MoreResultsNotice } from '@components/results/elements/MoreResultsNotice';
import ResultItemList from '@components/results/elements/ResultItemList';
import HadithResultItem from '@components/results/items/HadithResultItem';
import HadithSkeleton from '@components/skeletons/HadithSkeleton';
import { MySnackbarProps } from '@components/snackbar/Snackbar';
import SortComponent from '@components/views/MainSearchViews/SortComponent';
import { getDefaultQuerySort } from '@components/views/MainSearchViews/function';

import { ReactiveComponent, StateProvider } from '@appbaseio/reactivesearch';
import { narrationsIndex } from '@config/reactive-search';
import { SORT_OPTIONS } from '@constants/sorts';
import useTranslation from '@hooks/useTranslation';
import { ReactiveSearchLayout } from '@layout';
import {
    Box,
    Checkbox,
    FormControlLabel,
    FormGroup,
    IconButton,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { WordConfig, onExcelExport, onWordExport } from '@utils/export';
import { NarratorColor } from '@utils/narratorColor';
import { numberWithCommas } from '@utils/numbersDisplay';
import { sampleHadith } from '@utils/sampleHadith';
import { authenticity_order } from '@utils/sortBooks';

import RoadsFilters from './RoadsFilters';
import { NarrationsDataFields } from 'constants/datafields';
import { RoadsHadithsViewFilters } from 'constants/filters';
import {
    BookReference,
    EditionRefs,
    HadithNumbers,
    type NarrationsType,
    NextState,
    RoadsHadith,
    SearchPageHadith,
    appendPrimaryEditionToEditions,
} from 'shared/interfaces/hadith';
import { checkFiltersSelected } from 'shared/methods';
import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';

const ResultItems = z.object({
    data: z.array(RoadsHadith),
    loading: z.boolean(),
});

interface RoadsHadithsViewProps {
    /**
     * @description hadith
     */
    hadith: SearchPageHadith;
    setShowSnackbar: (x: MySnackbarProps) => void;
    narrationsType: NarrationsType;
    setNarrationsType: (x: NarrationsType) => void;
    collapsed: boolean;
    setCollapsed: (x: boolean) => void;
}

type BookReferenceWithSorting = BookReference & {
    hadith_number: number;
};

const RoadsReferences = z.object({
    hits: z.object({
        hits: z
            .object({
                _source: z.object({
                    page: z.number().nullable(),
                    volume: z.number().nullable(),
                    hadith_id: z.string(),
                    number: HadithNumbers,
                    book_name: z.string(),
                    chapter: z.string(),
                    editions: EditionRefs,
                }),
            })
            .transform((obj) => {
                appendPrimaryEditionToEditions(obj._source);
                return obj;
            })
            .array(),
    }),
});

function getRoadsReferences(
    fullSearchState: NextState,
    roadsCount: number,
): Promise<BookReference[]> {
    const jsonBody = JSON.stringify({
        query: fullSearchState['roads_result'].query,
        aggs: {},
        size: roadsCount,
        from: 0,
        _source: {
            includes: [
                NarrationsDataFields.BOOK_NAME,
                NarrationsDataFields.CHAPTER,
                NarrationsDataFields.VOLUME,
                NarrationsDataFields.PAGE,
                NarrationsDataFields.HADITH_ID,
                NarrationsDataFields.NUMBER,
                NarrationsDataFields.EDITIONS,
            ],
            excludes: [],
        },
    });

    return fetch(`/api/reactivesearchproxy/${narrationsIndex}/_search`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: jsonBody,
    })
        .then((res) => res.json())
        .then((json) => {
            const {
                hits: { hits },
            } = RoadsReferences.parse(json);

            const roadsReferences: BookReferenceWithSorting[] = hits.map(
                (hit) => ({
                    page: hit._source.page ?? 0,
                    volume: hit._source.volume ?? 0,
                    book_name: hit._source.book_name,
                    id: hit._source.hadith_id,
                    label: `${hit._source.number.join(',')} - ${
                        hit._source.chapter
                    }`,
                    hadith_number: hit._source.number[0],
                    group: hit._source.book_name,
                    editionsRefs: hit._source.editions,
                }),
            );
            return roadsReferences.sort((a, b) => {
                // MUST SORT ON .group first! then sort on whichever other criterion, so that grouped books actually
                // end up continguous in the list
                const bookNameA = a.group as keyof typeof authenticity_order;
                const bookNameB = b.group as keyof typeof authenticity_order;
                if (
                    authenticity_order[bookNameA] >
                    authenticity_order[bookNameB]
                ) {
                    return 1;
                } else if (
                    authenticity_order[bookNameB] >
                    authenticity_order[bookNameA]
                ) {
                    return -1;
                } else {
                    // same book
                    return a.hadith_number < b.hadith_number ? -1 : 1;
                }
            });
        });
}

const size = 10;

const RoadsHadithsView = ({
    hadith,
    setShowSnackbar,
    narrationsType,
    setNarrationsType,
    collapsed,
    setCollapsed,
}: RoadsHadithsViewProps) => {
    const { t } = useTranslation('library');
    const router = useRouter();
    const [hasMatnComparison, setHasMatnComparison] = useState(true);
    const [hasSelectedFilters, setHasSelectedFilters] = useState(false);
    const { hadithId } = router.query;
    const sortOptions = [
        SORT_OPTIONS.AUTHENTICITY,
        SORT_OPTIONS.OLDEST_TO_NEWEST,
        SORT_OPTIONS.NEWEST_TO_OLDEST,
    ];
    const sortByFromUrl = (router.query['roads_sort'] ??
        SORT_OPTIONS.AUTHENTICITY) as SORT_OPTIONS;
    const [sortBy, setSortBy] = useState(sortByFromUrl);

    const [totalResults, setTotalResults] = useState(0);
    const [narratorsColors, setNarratorsColors] = useState<NarratorColor[]>([]);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const exportDisabled = totalResults > 200 || isMobile;

    const [wordConfigOpen, setWordConfigOpen] = useState(false);

    const [fullSearchState, setFullSearchState] = useState<
        NextState | undefined
    >(undefined);

    const [roadsReferences, setRoadsReferences] = useState<BookReference[]>([]);

    const resultStatsLabel = hasSelectedFilters
        ? t('roads_count_with_filters')
        : t('roads_count');

    const dependencies: RoadsHadithsViewFilters[] = [
        RoadsHadithsViewFilters.BOOKS_FILTERS,
        RoadsHadithsViewFilters.NARRATORS_FILTERS,
        RoadsHadithsViewFilters.CHAPTERS_FILTERS,
        RoadsHadithsViewFilters.SUB_CHAPTERS_FILTERS,
        RoadsHadithsViewFilters.HADITH_TYPES_FILTERS,
        RoadsHadithsViewFilters.HADITHS_IDS,
    ];

    const resultStatsMessage = isMobile ? (
        <Typography variant="body1" color="primary">
            {`${t('roads_count')}: ${numberWithCommas(totalResults)}`}
        </Typography>
    ) : (
        <Typography variant="body1" color="primary">
            {`${resultStatsLabel}: ${numberWithCommas(totalResults)}`}
        </Typography>
    );

    const narrations =
        narrationsType === 'all'
            ? hadith.raw_narrations
                  .concat(hadith.extended_narrations)
                  .concat(hadith.hadith_id)
            : narrationsType === 'roads'
            ? hadith.raw_narrations.concat(hadith.hadith_id)
            : hadith.extended_narrations;

    useEffect(() => {
        if (narrationsType === 'shawahed') {
            setHasMatnComparison(false);
        }
    }, [narrationsType]);

    return (
        <SearchBase
            app={narrationsIndex}
            setSearchParams={(newURL) => {
                router.push(newURL, undefined, {
                    shallow: true,
                });
            }}
        >
            <ReactiveComponent
                componentId={RoadsHadithsViewFilters.HADITHS_IDS}
                customQuery={() => ({
                    query: {
                        terms: {
                            [NarrationsDataFields.HADITH_ID]: narrations,
                        },
                    },
                })}
            />
            <ReactiveSearchLayout
                hasSelectedFilters={hasSelectedFilters}
                collapsed={collapsed}
                setCollapsed={setCollapsed}
                filters={(setDrawerFiltersOpen) => (
                    <RoadsFilters
                        source="roads_hadiths"
                        narratorsColors={narratorsColors}
                        setNarratorsColors={setNarratorsColors}
                        sortBy={sortBy}
                        setDrawerFiltersOpen={setDrawerFiltersOpen}
                    />
                )}
                resultStatsMessage={resultStatsMessage}
                results={(handleFilter) => (
                    <>
                        <FiltersSelected />

                        <Box
                            sx={
                                isMobile
                                    ? {
                                          display: 'flex',
                                          justifyContent: 'center',
                                          mt: 1,
                                      }
                                    : {
                                          mt: 4,
                                          display: 'flex',
                                          justifyContent: 'start',
                                      }
                            }
                        >
                            <NarrationsTypeToggle
                                narrationsType={narrationsType}
                                setNarrationsType={setNarrationsType}
                                disabledShawahed={
                                    hadith.extended_narrations.length === 0
                                }
                                disabledShawahedMessage={t(
                                    'shawahed_not_clickable',
                                )}
                            />
                        </Box>
                        <ResultItemList
                            hasSelectedFilters={hasSelectedFilters}
                            resultStatsMessage={resultStatsMessage}
                            componentId={RoadsHadithsViewFilters.RESULT}
                            dataField={NarrationsDataFields.HADITH_ID}
                            onData={(items) => {
                                setTotalResults(
                                    items.resultStats.numberOfResults,
                                );
                            }}
                            dependencies={{
                                and: dependencies,
                            }}
                            defaultQuery={() => {
                                return {
                                    track_total_hits: true,
                                    sort: getDefaultQuerySort(sortBy),
                                    aggs: {
                                        unique_narrators: {
                                            cardinality: {
                                                field: NarrationsDataFields.NARRATORS_FULL_NAMES,
                                            },
                                        },
                                        unique_chapters: {
                                            cardinality: {
                                                field: NarrationsDataFields.CHAPTER,
                                            },
                                        },
                                        unique_sub_chapters: {
                                            cardinality: {
                                                field: NarrationsDataFields.SUB_CHAPTER,
                                            },
                                        },
                                    },
                                };
                            }}
                            includeFields={[
                                NarrationsDataFields.HADITH_ID,
                                NarrationsDataFields.HADITH_SERIAL_ID,
                                NarrationsDataFields.NUMBER,
                                NarrationsDataFields.BOOK_NAME,
                                NarrationsDataFields.TYPE,
                                NarrationsDataFields.PAGE,
                                NarrationsDataFields.VOLUME,
                                NarrationsDataFields.NARRATORS,
                                NarrationsDataFields.RAW_NARRATIONS,
                                NarrationsDataFields.NARRATIONS_NUMBERS,
                                NarrationsDataFields.CHAPTER,
                                NarrationsDataFields.SUB_CHAPTER,
                                NarrationsDataFields.HADITH,
                                NarrationsDataFields.MATN_WITH_TASHKEEL,

                                NarrationsDataFields.RULINGS,
                                NarrationsDataFields.RULINGS_RULER,
                                NarrationsDataFields.RULINGS_RULING,
                                NarrationsDataFields.RULINGS_BOOK_NAME,
                                NarrationsDataFields.RULINGS_PAGE,
                                NarrationsDataFields.RULINGS_VOLUME,

                                NarrationsDataFields.EDITIONS,
                            ]}
                            size={size}
                            resultItems={(items) => {
                                const { data, loading } =
                                    ResultItems.parse(items);

                                const hadiths = data.map((item) => (
                                    <HadithResultItem
                                        hadith_object={item}
                                        key={uuidv4()}
                                        withMatnComparison={
                                            hasMatnComparison &&
                                            item.hadith_id != hadith.hadith_id //Exclude main hadith from comparison
                                        }
                                        hadithToCompare={hadith}
                                        rightActions={[]}
                                        allowNarratorClick={false}
                                        narratorsColors={narratorsColors}
                                        isSelectedRoad={
                                            item.hadith_id == hadithId
                                        }
                                        setShowSnackbar={setShowSnackbar}
                                        quickNavPoints={roadsReferences}
                                    />
                                ));

                                if (loading && data.length > size) {
                                    return hadiths.concat(
                                        Array(size)
                                            .fill(0)
                                            .map((_, i) => (
                                                <HadithSkeleton
                                                    key={uuidv4()}
                                                    withActions
                                                    withMatnComparison={i > 0}
                                                />
                                            )),
                                    );
                                } else if (loading) {
                                    // filtering, not paginating, since we have one page only (data.length <= size)
                                    // return a single page of skeletons, no Hadiths
                                    // the *2 heuristic is because a general skeleton is shorter than a Hadith, not perfect
                                    return Array(size * 2)
                                        .fill(0)
                                        .map((_, i) => (
                                            <HadithSkeleton
                                                key={uuidv4()}
                                                withActions
                                                withMatnComparison={i > 0}
                                            />
                                        ));
                                } else {
                                    return hadiths.concat(
                                        <MoreResultsNotice
                                            key="more-results-notice"
                                            moreResults={
                                                hadiths.length < totalResults
                                            }
                                        />,
                                    );
                                }
                            }}
                            loader={Array(size)
                                .fill(0)
                                .map((_, i) => (
                                    <HadithSkeleton
                                        key={uuidv4()}
                                        withActions
                                        withMatnComparison={i > 0}
                                    />
                                ))}
                            stream={true}
                            showPagination={false}
                            scrollTarget={'main-modal'}
                            onFilterClick={handleFilter}
                            headerFirstLevel={
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                    }}
                                >
                                    <Box sx={{ position: 'relative' }}>
                                        {narrationsType === 'shawahed' && (
                                            <CustomTooltip
                                                message={t(
                                                    'shawahed_no_matn_diff',
                                                )}
                                            />
                                        )}
                                        <FormGroup>
                                            <FormControlLabel
                                                sx={{
                                                    minWidth: '150px',
                                                    margin: 0,
                                                    color: 'primary.main',
                                                }}
                                                control={
                                                    <Checkbox
                                                        disabled={
                                                            narrationsType ===
                                                            'shawahed'
                                                        }
                                                        checked={
                                                            hasMatnComparison
                                                        }
                                                        onChange={(e) =>
                                                            setHasMatnComparison(
                                                                e.target
                                                                    .checked,
                                                            )
                                                        }
                                                        color="secondary"
                                                        sx={{
                                                            ml: -1.4,
                                                        }}
                                                    />
                                                }
                                                label={t('matn_comparison')}
                                            />
                                        </FormGroup>
                                    </Box>
                                    <Box sx={{ position: 'relative' }}>
                                        {fullSearchState && exportDisabled && (
                                            <CustomTooltip
                                                message={
                                                    isMobile
                                                        ? t(
                                                              'feature_not_available_on_mobile',
                                                          )
                                                        : t('export_disabled')
                                                }
                                            />
                                        )}
                                        {fullSearchState && (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                }}
                                            >
                                                <IconButton
                                                    onClick={
                                                        exportDisabled
                                                            ? undefined
                                                            : () =>
                                                                  onExcelExport(
                                                                      fullSearchState,
                                                                      'roads_',
                                                                  )
                                                    }
                                                >
                                                    {
                                                        <ExcelSvg
                                                            width={24}
                                                            height={24}
                                                            disabled={
                                                                exportDisabled
                                                            }
                                                        />
                                                    }
                                                </IconButton>
                                                <IconButton
                                                    onClick={
                                                        exportDisabled
                                                            ? undefined
                                                            : () =>
                                                                  setWordConfigOpen(
                                                                      true,
                                                                  )
                                                    }
                                                    disabled={exportDisabled}
                                                >
                                                    <WordSvg
                                                        width={24}
                                                        height={24}
                                                        disabled={
                                                            exportDisabled
                                                        }
                                                    />
                                                </IconButton>
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                            }
                            headerSecondLevel={
                                <SortComponent
                                    sortOptions={sortOptions}
                                    sortBy={sortBy}
                                    prefix={'roads_'}
                                    setSortBy={setSortBy}
                                    sx={{
                                        justifyContent: 'flex-end',
                                    }}
                                />
                            }
                        />

                        <StateProvider
                            //Counts coming from aggregations query
                            includeKeys={[
                                'value',
                                'hits',
                                'aggregations',
                                'error',
                                'query',
                                'aggs',
                                'size',
                                'includeFields',
                                'react',
                                'sort',
                            ]}
                            onChange={(state, nextState: NextState) => {
                                checkFiltersSelected(
                                    { ...state, ...nextState },
                                    hasSelectedFilters,
                                    setHasSelectedFilters,
                                    'roads_',
                                );
                                const fullState: NextState = {
                                    ...state,
                                    ...nextState,
                                };
                                setFullSearchState(fullState);

                                if (nextState?.roads_result?.hits?.total) {
                                    getRoadsReferences(
                                        nextState,
                                        nextState['roads_result'].hits.total,
                                    ).then((references) =>
                                        setRoadsReferences(references),
                                    );
                                }
                            }}
                        />
                    </>
                )}
            />
            {wordConfigOpen && (
                <CopyHadithModal
                    onClose={() => setWordConfigOpen(false)}
                    setShowSnackbar={setShowSnackbar}
                    hadithHtml={sampleHadith.hadith}
                    hadith={sampleHadith as RoadsHadith}
                    title={t('word_config')}
                    downloadText={t('download_word')}
                    onWordExport={(config: WordConfig) => {
                        if (fullSearchState) {
                            onWordExport(fullSearchState, 'roads_', config);
                        }
                    }}
                />
            )}
        </SearchBase>
    );
};

export default RoadsHadithsView;
