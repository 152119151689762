import { mainSearchFilterNoSearchKeys } from '@constants/filters';

import type { NextState, StatePrefix } from 'shared/interfaces/hadith';

// The prefix is to encapsulate the filters to each screen / context
// so in Roads Hadiths, we provide the 'roads_' prefix, so we only care about filters that start
// with 'roads_', that are relevant for that page, we don't worry about other filters from the main page
// so we don't have leaking contexts
// YO this logic is wrong for the main search [@chammaaomar]
// but we're not using it for main search anymore, so please don't either
export const checkFiltersSelected = (
    state: Record<string, { value?: string[] }>,
    hasSelectedFilters: boolean,
    setHasSelectedFilters: (newVal: boolean) => void,
    prefix: StatePrefix,
) => {
    const isFilterSelected =
        Object.entries(state).filter(([key, value]) => {
            return (
                key.startsWith(prefix) &&
                value?.value?.length &&
                value.value.length > 0
            );
        }).length > 0;
    if (isFilterSelected !== hasSelectedFilters) {
        setHasSelectedFilters(isFilterSelected);
    }

    return isFilterSelected;
};

export const anyMainSearchFilterApplied = (state: NextState) => {
    return mainSearchFilterNoSearchKeys.some((key) => {
        const filterState = state[key];
        return filterState?.value?.length && filterState.value.length > 0;
    });
};
