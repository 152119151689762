import {
    type Dispatch,
    type MouseEvent,
    type ReactElement,
    type Ref,
    type SetStateAction,
    forwardRef,
    useEffect,
    useState,
} from 'react';

import { useRouter } from 'next/router';

import { CustomTooltip } from '@components/CustomTooltip';

import { ReactiveComponent } from '@appbaseio/reactivesearch';
import { numNeighbors } from '@constants/filters';
import { SORT_OPTIONS } from '@constants/sorts';
import useTranslation from '@hooks/useTranslation';
import {
    ArrowBack as ArrowBackIcon,
    Clear as ClearIcon,
    History as HistoryIcon,
    Search as SearchIcon,
} from '@mui/icons-material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
    Box,
    ClickAwayListener,
    Dialog,
    IconButton,
    InputAdornment,
    type InputBaseProps,
    ListItem,
    Slide,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { GridFilterListIcon } from '@mui/x-data-grid';
import { ConditionalWrapper } from '@utils/ConditionalWrapper';

import { getMainSearchDefaultQuery, getShouldMatch } from './queryFunctions';
import {
    ButtonWrapper,
    ListItemContent,
    SearchInput,
    SuggestionsList,
} from './styled';
import { useCombobox } from 'downshift';
import { analytics } from 'lib/analytics';
import { SearchType } from 'shared/interfaces/hadith';
import { colors } from 'theme';
import { useDebounce } from 'use-debounce';
import { v4 as uuid4 } from 'uuid';
import { z } from 'zod';

type Query = {
    query?: Record<string, unknown>;
    value: string;
    options: Record<string, unknown>;
};

interface ISearchBarAutosuggestProps {
    /**
     * @description filter label
     *
     */
    label: string;
    /**
     * @description component id for the DataSearch component
     *
     */
    componentId: string;
    /**
     * @description placeholder for the search bar
     * @default ''
     */
    searchPlaceholder: string;

    /**
     * @description LocalStorage key for the search history
     */
    historyLocalStorageKey: string;

    /**
     *  @description type of text search
     */
    searchType: SearchType;

    sortBy: SORT_OPTIONS;

    /**
     * @description Whether to add url params based on filter selection
     * @default true
     */
    URLParams?: boolean;

    initialTextSearch: string;
    collapsed?: boolean;
    openMobileSearchModalFromHeader: boolean;
    setCollapsed: Dispatch<SetStateAction<boolean>>;
    onFilterClick: Dispatch<SetStateAction<boolean>>;
    setOpenMobileSearchModalFromHeader: Dispatch<SetStateAction<boolean>>;
    hasSelectedFilters: boolean;

    setTextSearch: (x: string) => void;
    setSearchType: (x: SearchType) => void;

    setSortBy: (x: SORT_OPTIONS) => void;
    setLoadingCounts: (loading: boolean) => void;
}

const Suggestion = z.object({
    id: z.string(),
    label: z.string(),
    value: z.string(),
});

type Suggestion = z.infer<typeof Suggestion>;

interface SearchHistory extends Suggestion {
    type?: 'local';
}

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: ReactElement;
    },
    ref: Ref<unknown>,
) {
    // needs to be fast for the mobile landing page to not have artifacts ;)
    return <Slide direction="up" ref={ref} {...props} timeout={50} />;
});

function getTextQuery(
    selectedSearch: string,
    searchType: SearchType,
    sortBy: SORT_OPTIONS,
): Query {
    if (!selectedSearch) {
        return {
            query: { match_all: {} },
            options: {
                // this is logically not necessary, since these are the options for the SearchFilters.RESULT component
                // so why are they here? It seems like reactivesearch has a difficult time merging the options from this
                // component and the options from the SearchFilters.RESULT component (think about it, it's not that easy!),
                // and ends up dropping the options
                // from the SearchFilters.RESULT component, which messes up the sorting, the total results count, and
                // everything basically. So unfortunately this is needed.
                ...getMainSearchDefaultQuery(sortBy, searchType),
                _source: 'false',
            },
            value: '',
        };
    }

    return {
        query: {
            bool: {
                should: getShouldMatch(searchType, selectedSearch),
                minimum_should_match: 1,
            },
        },
        options: {
            ...getMainSearchDefaultQuery(sortBy, searchType),
            _source: 'false',
        },
        value: selectedSearch,
    };
}

type SearchBarAutosuggestRenderProps = Pick<
    ISearchBarAutosuggestProps,
    | 'searchPlaceholder'
    | 'historyLocalStorageKey'
    | 'searchType'
    | 'setTextSearch'
    | 'setLoadingCounts'
    | 'sortBy'
> & {
    setQuery: (query: object) => void;
    collapsed?: boolean;
    setCollapsed: Dispatch<SetStateAction<boolean>>;
    onFilterClick: Dispatch<SetStateAction<boolean>>;
    openMobileSearchModalFromHeader: boolean;
    setOpenMobileSearchModalFromHeader: Dispatch<SetStateAction<boolean>>;
    hasSelectedFilters: boolean;
    value: string;
};

function SearchBarAutosuggestRender({
    collapsed,
    setCollapsed,
    onFilterClick,
    setQuery,
    openMobileSearchModalFromHeader,
    setOpenMobileSearchModalFromHeader,
    hasSelectedFilters,
    searchPlaceholder,
    historyLocalStorageKey,
    searchType,
    value,
    setTextSearch,
    setLoadingCounts,
    sortBy,
}: SearchBarAutosuggestRenderProps) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));

    const mobileInputProps: InputBaseProps['inputProps'] = isMobile
        ? {
              autoFocus: true,
              autoComplete: 'off',
              lang: 'ar',
              // takes only certain allowed values: https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/enterkeyhint
              enterKeyHint: 'search',
          }
        : {};

    const router = useRouter();

    const [openMobileSearchModal, setOpenMobileSearchModal] = useState(false);
    const [searchSuggestions, setSearchSuggestions] = useState<Suggestion[]>(
        [],
    );
    const [searchHistory, setSearchHistory] = useState<SearchHistory[]>([]);
    const [historySuggestions, setHistorySuggestions] = useState<
        SearchHistory[]
    >([]);

    const closeMobileSearchModal = () => {
        if (isMobile && openMobileSearchModal) {
            setOpenMobileSearchModal(false);
            setOpenMobileSearchModalFromHeader(false);
        }
    };

    useEffect(() => {
        // handles changing exact match, or when navigating to a url with 'search' query param set
        setInputValue(value);
        setTextSearch(value);
        setQuery(getTextQuery(value, searchType, sortBy));
        if (value) {
            analytics('search', { type: searchType, id: value });
        }
    }, [value, searchType, sortBy]);

    const handleSearch = (selectedItem: string) => {
        if (selectedItem != '') {
            let existingEntries: SearchHistory[] = JSON.parse(
                localStorage.getItem(historyLocalStorageKey) ?? 'null',
            );
            if (existingEntries == null) existingEntries = [];
            const indexItem = existingEntries.findIndex(
                (item) => item.value == selectedItem,
            );
            if (indexItem === -1) {
                if (existingEntries.length >= 25) {
                    existingEntries.pop();
                }
                addToSearchHistory(existingEntries, selectedItem);
            } else {
                existingEntries.splice(indexItem, 1);
                addToSearchHistory(existingEntries, selectedItem);
            }

            setInputValue(selectedItem);
            setTextSearch(selectedItem);
            setQuery(getTextQuery(selectedItem, searchType, sortBy));

            if (selectedItem) {
                // vercel analytics
                analytics('search', { type: searchType, id: selectedItem });
                // analytics in ES
                fetch('/api/search_analytics', {
                    method: 'POST',
                    body: JSON.stringify({
                        search: selectedItem,
                        type: searchType,
                    }),
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
            }

            if (selectedItem !== inputValue) {
                // counts are only loaded if the new search is different than the old
                setLoadingCounts(true);
                if (isMobile) {
                    window.scrollTo({ top: 0 });
                }
            }

            setTimeout(() => {
                const searchField = document.getElementById(
                    'downshift-0-input',
                ) as HTMLTextAreaElement | null;
                searchField?.blur();
            }, 500);
        }
    };

    const addToSearchHistory = (
        existingEntries: SearchHistory[],
        selectedItem: string,
    ) => {
        existingEntries.unshift({
            id: uuid4(),
            label: selectedItem,
            value: selectedItem,
            type: 'local',
        });
        localStorage.setItem(
            historyLocalStorageKey,
            JSON.stringify(existingEntries),
        );
        setSearchHistory(existingEntries);
    };

    const removeFromHistory = (
        event: MouseEvent<HTMLElement>,
        item: SearchHistory,
    ) => {
        event.preventDefault();
        event.stopPropagation();
        let existingEntries: SearchHistory[] = JSON.parse(
            // TODO[chammaaomar]: Handle nullness properly
            localStorage.getItem(historyLocalStorageKey) ?? '[]',
        );

        const indexToRemove = existingEntries.findIndex(
            (el) => el.id === item.id,
        );

        existingEntries.splice(indexToRemove, 1);

        localStorage.setItem(
            historyLocalStorageKey,
            JSON.stringify(existingEntries),
        );
        setSearchHistory(existingEntries);
    };

    const allSuggestions = historySuggestions.concat(searchSuggestions);

    const {
        inputValue,
        setInputValue,
        isOpen,
        getMenuProps,
        highlightedIndex,
        getItemProps,
        getInputProps,
    } = useCombobox({
        items: allSuggestions,
        stateReducer: (state, { type, changes }) => {
            switch (type) {
                case useCombobox.stateChangeTypes.InputBlur:
                case useCombobox.stateChangeTypes.InputKeyDownEnter: {
                    if (changes.inputValue) {
                        // double quotes break everything and they're ignored due to normalization anyway
                        handleSearch(changes.inputValue.replaceAll('"', ''));
                    }
                    break;
                }
            }
            return { ...changes };
        },
        onSelectedItemChange({ selectedItem }) {
            if (selectedItem) {
                // search is done with the shorter version to match user expectations
                // since the user only sees the truncated version
                // so doesn't make sense for the user to select the truncated version, and end up with the full version
                // in the search bar... minimize suprises 🎈🎉
                handleSearch(selectedItem.label);
                closeMobileSearchModal();
            }
        },
        onInputValueChange({ inputValue }) {
            if (inputValue === '') {
                setSearchSuggestions([]);
            }
        },
        itemToString(item) {
            return item ? item.label : '';
        },
    });

    const [debouncedInput] = useDebounce(inputValue, 300);

    useEffect(() => {
        let existingEntries: SearchHistory[] = JSON.parse(
            localStorage.getItem(historyLocalStorageKey) ?? 'null',
        );
        if (existingEntries) {
            setSearchHistory(existingEntries);
            setHistorySuggestions(existingEntries);
        } else setSearchHistory([]);
    }, []);

    useEffect(() => {
        if (!inputValue.length) {
            setHistorySuggestions(searchHistory);
        } else {
            setHistorySuggestions(
                searchHistory.filter(
                    (sugg) =>
                        sugg.value.trim().substring(0, inputValue.length) ===
                        inputValue,
                ),
            );
        }
    }, [inputValue, searchHistory]);

    const showTasnifBtn = !isMobile && collapsed;
    const { t } = useTranslation('library');

    useEffect(() => {
        if (openMobileSearchModalFromHeader && !openMobileSearchModal) {
            document
                .getElementById('mainSearchInputWithAutoSuggestion')
                ?.click();
        }
    }, [openMobileSearchModalFromHeader, openMobileSearchModal]);

    useEffect(() => {
        if (!debouncedInput.length) {
            return;
        }

        const selectedBooks = router.query['books_filters'] ?? 'all';

        fetch(
            `/api/autosuggest?search=${debouncedInput}&books_filters=${selectedBooks}`,
        )
            .then((res) => res.json())
            .then((json) => {
                const parsed = z
                    .object({
                        suggestions: Suggestion.array(),
                    })
                    .safeParse(json);
                if (parsed.success) {
                    setSearchSuggestions(parsed.data.suggestions);
                }
            });
    }, [debouncedInput, router.query]);

    useEffect(() => {
        setTimeout(() => {
            const searchField = document.getElementById(
                'downshift-0-input',
            ) as HTMLTextAreaElement;
            if (searchField && openMobileSearchModal) {
                const length = searchField.value.length;
                searchField.focus();
                searchField.selectionStart = length;
                searchField.selectionEnd = length;
            }
        }, 500);
    }, [openMobileSearchModal]);

    const handleOnClear = () => {
        setInputValue('');
        const searchField = document.getElementById('downshift-0-input');
        if (searchField) {
            searchField.focus();
        }
    };
    return (
        <ConditionalWrapper
            condition={isMobile && openMobileSearchModal}
            wrap={(wrappedChildren) => (
                <Dialog fullScreen open={true} TransitionComponent={Transition}>
                    {wrappedChildren}
                </Dialog>
            )}
        >
            <ClickAwayListener
                onClickAway={() => {
                    if (isMobile && openMobileSearchModal) {
                        closeMobileSearchModal();
                    }
                }}
            >
                <Box
                    gap={isMobile ? 1 : 4}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: { xs: 'white', sm: 'transparent' },
                        padding: {
                            xs: openMobileSearchModal
                                ? 0
                                : '10px 10px 0px 10px',
                            sm: 0,
                        },
                    }}
                >
                    <Box
                        sx={{
                            display: openMobileSearchModal ? 'none' : 'block',
                        }}
                    >
                        {showTasnifBtn && (
                            <IconButton
                                onClick={() => setCollapsed(false)}
                                sx={{
                                    backgroundColor: colors.hover,
                                    borderRadius: '5px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1,
                                    mb: 1.2,
                                }}
                            >
                                <GridFilterListIcon color={'primary'} />
                                {hasSelectedFilters && (
                                    <span
                                        style={{
                                            content: '""',
                                            position: 'absolute',
                                            top: '25%',
                                            right: '8%',
                                            width: '8px',
                                            height: '8px',
                                            borderRadius: '50%',
                                            backgroundColor: colors.tertiary, // Customize the point color
                                        }}
                                    ></span>
                                )}
                                <Typography color={'primary'}>
                                    {t('tasnif')}
                                </Typography>
                            </IconButton>
                        )}

                        {isMobile && !openMobileSearchModal && (
                            <IconButton
                                onClick={() => {
                                    setLoadingCounts(true);
                                    // router.push('/') isn't working great
                                    // so let's try to use the clear all filters button instead
                                    // since that consistently works on mobile
                                    const clearAllButton =
                                        document.getElementById(
                                            'clear-all-filters',
                                        );
                                    clearAllButton?.click();
                                }}
                                sx={{
                                    backgroundColor: '#F7F7F7',
                                    borderRadius: '5px',
                                    mb: 1.2,
                                    p: 1.2,
                                    zIndex: 3,
                                }}
                                size="medium"
                            >
                                <ArrowForwardIosIcon
                                    color="secondary"
                                    fontSize="medium"
                                />
                            </IconButton>
                        )}
                    </Box>
                    <Box
                        id="mainSearchInputWithAutoSuggestion"
                        onClick={() => {
                            if (isMobile && !openMobileSearchModal) {
                                setOpenMobileSearchModal(true);
                            }
                        }}
                        sx={{
                            display: 'inline-block',
                            width: '100%',
                            position: 'relative',
                            backgroundColor: { xs: 'white', sm: 'none' },
                            marginBottom: '10px',
                            zIndex: 2,
                        }}
                    >
                        <SearchInput
                            variant={isMobile ? 'standard' : 'outlined'}
                            multiline
                            type="search"
                            autoCapitalize="off"
                            autoComplete="off"
                            autoCorrect="off"
                            rows={
                                openMobileSearchModalFromHeader && isMobile
                                    ? 1.5
                                    : isMobile
                                    ? 1.2
                                    : 1.4
                            }
                            placeholder={
                                isMobile && !openMobileSearchModal
                                    ? t('search_in_hadiths_short')
                                    : searchPlaceholder
                            }
                            {...getInputProps({
                                refKey: 'inputRef',
                                onKeyDown: (e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        closeMobileSearchModal();
                                    }
                                },
                            })}
                            inputProps={mobileInputProps}
                            InputProps={{
                                style: {
                                    textAlign: 'left',
                                },

                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="search"
                                            onClick={() => {
                                                closeMobileSearchModal();
                                            }}
                                            sx={{
                                                mb:
                                                    openMobileSearchModalFromHeader &&
                                                    isMobile
                                                        ? 0.5
                                                        : 0,
                                            }}
                                        >
                                            <SearchIcon
                                                fontSize="medium"
                                                color="secondary"
                                                aria-label="search"
                                            />
                                        </IconButton>
                                        {openMobileSearchModal && (
                                            <IconButton
                                                aria-label="back"
                                                onClick={() => {
                                                    closeMobileSearchModal();
                                                }}
                                                sx={{
                                                    mb:
                                                        openMobileSearchModalFromHeader &&
                                                        isMobile
                                                            ? 0.5
                                                            : 0,
                                                }}
                                            >
                                                <ArrowBackIcon
                                                    fontSize="medium"
                                                    color="secondary"
                                                    aria-label="search"
                                                />
                                            </IconButton>
                                        )}
                                    </InputAdornment>
                                ),
                                startAdornment: (
                                    <InputAdornment position="start">
                                        {inputValue.length > 0 && (
                                            <ButtonWrapper
                                                onClick={handleOnClear}
                                                sx={{
                                                    pl: 1,
                                                    mb:
                                                        openMobileSearchModalFromHeader &&
                                                        isMobile
                                                            ? 0.5
                                                            : 0,
                                                }}
                                            >
                                                <ClearIcon
                                                    fontSize="medium"
                                                    aria-label="clear"
                                                    color="secondary"
                                                />
                                            </ButtonWrapper>
                                        )}
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                pt:
                                    isMobile && openMobileSearchModalFromHeader
                                        ? 1.4
                                        : 0,
                                backgroundColor: {
                                    xs: colors.hover,
                                    sm: 'transparent',
                                },
                                outline: 'none',
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        border: '1px solid rgba(0,0,0,0.2)',
                                    },
                                    '&.Mui-focused fieldset': {
                                        border: '1px solid rgba(0,0,0,0.2)',
                                    },
                                },
                            }}
                        />

                        <SuggestionsList
                            {...getMenuProps({}, { suppressRefError: true })}
                            sx={{
                                display:
                                    (isMobile && openMobileSearchModal) ||
                                    (!isMobile &&
                                        isOpen &&
                                        allSuggestions.length > 0)
                                        ? 'block'
                                        : 'none',
                            }}
                        >
                            {allSuggestions.map((item, index) => {
                                return (
                                    <ListItem
                                        key={item.id}
                                        sx={
                                            index === highlightedIndex
                                                ? {
                                                      backgroundColor:
                                                          'rgb(238, 238, 238)',
                                                      cursor: 'pointer',
                                                  }
                                                : { cursor: 'pointer' }
                                        }
                                        {...getItemProps({
                                            item,
                                            index,
                                        })}
                                    >
                                        <ListItemContent>
                                            {item.type ? (
                                                <Box
                                                    sx={
                                                        item.type
                                                            ? {
                                                                  color: '#681da8',
                                                                  overflowX:
                                                                      'clip',
                                                                  textOverflow:
                                                                      'ellipsis',
                                                                  whiteSpace:
                                                                      'nowrap',
                                                                  width: '90%',
                                                              }
                                                            : {
                                                                  color: 'inherit',
                                                                  overflowX:
                                                                      'clip',
                                                                  textOverflow:
                                                                      'ellipsis',
                                                                  whiteSpace:
                                                                      'nowrap',
                                                                  width: '90%',
                                                              }
                                                    }
                                                >
                                                    <HistoryIcon
                                                        sx={{
                                                            marginLeft: '6px',
                                                            fontSize: '1rem',
                                                            color: '#681da8',
                                                        }}
                                                    />
                                                    {item.value}
                                                </Box>
                                            ) : (
                                                <Box
                                                    sx={{
                                                        overflowX: 'clip',
                                                        textOverflow:
                                                            'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                    }}
                                                >
                                                    <SearchIcon
                                                        sx={{
                                                            marginLeft: '6px',
                                                            fontSize: '1rem',
                                                        }}
                                                    />
                                                    {item.label}
                                                </Box>
                                            )}

                                            {item.type && (
                                                <IconButton
                                                    onClick={(event) =>
                                                        removeFromHistory(
                                                            event,
                                                            item,
                                                        )
                                                    }
                                                >
                                                    <ClearIcon
                                                        style={{
                                                            fontSize: '1rem',
                                                        }}
                                                        color="secondary"
                                                    />
                                                </IconButton>
                                            )}
                                        </ListItemContent>
                                    </ListItem>
                                );
                            })}
                        </SuggestionsList>
                    </Box>
                    <Box
                        sx={{
                            display: openMobileSearchModal ? 'none' : 'block',
                        }}
                    >
                        {isTablet && !openMobileSearchModal && (
                            <IconButton
                                onClick={() => {
                                    onFilterClick(true);
                                }}
                                sx={{
                                    backgroundColor: '#F7F7F7',
                                    borderRadius: '5px',
                                    mb: 1.2,
                                    zIndex: 2,
                                    p: 1.2,
                                }}
                            >
                                <GridFilterListIcon
                                    color={'secondary'}
                                    fontSize={
                                        isMobile
                                            ? 'medium'
                                            : isTablet
                                            ? 'large'
                                            : 'medium'
                                    }
                                />
                                {hasSelectedFilters && (
                                    <span
                                        style={{
                                            content: '""',
                                            position: 'absolute',
                                            top: '25%',
                                            right: '20%',
                                            width: '8px',
                                            height: '8px',
                                            borderRadius: '50%',
                                            backgroundColor: colors.tertiary, // Customize the point color
                                        }}
                                    ></span>
                                )}
                            </IconButton>
                        )}
                    </Box>
                </Box>
            </ClickAwayListener>
        </ConditionalWrapper>
    );
}

const SearchBarAutosuggest = ({
    label,
    collapsed,
    setCollapsed,
    onFilterClick,
    openMobileSearchModalFromHeader,
    setOpenMobileSearchModalFromHeader,
    componentId,
    searchPlaceholder,
    historyLocalStorageKey,
    searchType,
    sortBy,
    hasSelectedFilters,
    URLParams = true,
    initialTextSearch,
    setTextSearch,
    setLoadingCounts,
}: ISearchBarAutosuggestProps) => {
    return (
        <ReactiveComponent
            componentId={componentId}
            URLParams={URLParams}
            showFilter
            filterLabel={label}
            defaultValue={''}
            defaultQuery={() =>
                getTextQuery(initialTextSearch, searchType, sortBy)
            }
            render={({ value, setQuery }) => {
                return (
                    <SearchBarAutosuggestRender
                        collapsed={collapsed}
                        setCollapsed={setCollapsed}
                        onFilterClick={onFilterClick}
                        openMobileSearchModalFromHeader={
                            openMobileSearchModalFromHeader
                        }
                        setOpenMobileSearchModalFromHeader={
                            setOpenMobileSearchModalFromHeader
                        }
                        hasSelectedFilters={hasSelectedFilters}
                        setQuery={setQuery}
                        searchPlaceholder={searchPlaceholder}
                        historyLocalStorageKey={historyLocalStorageKey}
                        searchType={searchType}
                        sortBy={sortBy}
                        value={value ?? initialTextSearch}
                        setTextSearch={setTextSearch}
                        setLoadingCounts={setLoadingCounts}
                    />
                );
            }}
        />
    );
};

export default SearchBarAutosuggest;
