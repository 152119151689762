import FilterList from '@components/filters/FilterList';

import useTranslation from '@hooks/useTranslation';
import { NarratorColor } from '@utils/narratorColor';
import { ruler_order } from '@utils/sortBooks';

import { HadithRulingsDataFields } from 'constants/datafields';
import { HadithRulingsViewFilters } from 'constants/filters';

const HadithRulingsFilters = ({
    setDrawerFiltersOpen,
}: {
    setDrawerFiltersOpen: (open: boolean) => void;
}) => {
    const { t } = useTranslation('library');

    return (
        <>
            <FilterList
                label={t('rulers')}
                componentId={HadithRulingsViewFilters.RULERS_FILTERS}
                dataField={HadithRulingsDataFields.RULER_KEYWORD}
                setDrawerFiltersOpen={setDrawerFiltersOpen}
                dependencies={{
                    and: [HadithRulingsViewFilters.HADITH_ID],
                }}
                statePrefix="rul_"
                manipulateListData={(data) =>
                    data.sort((a, b) =>
                        ruler_order[a.key as keyof typeof ruler_order] >
                        ruler_order[b.key as keyof typeof ruler_order]
                            ? 1
                            : -1,
                    )
                }
                showAggregateCounts={false}
            />
        </>
    );
};

export default HadithRulingsFilters;
