import { useState } from 'react';

import { useRouter } from 'next/router';

import { CustomTooltip } from '@components/CustomTooltip';
import ExcelSvg from '@components/ExcelSvg';
import WordSvg from '@components/WordSvg';
import SearchBase from '@components/base/SearchBase';
import FiltersSelected from '@components/filters/elements/FiltersSelected';
import CopyHadithModal from '@components/modal/Modal/CopyHadithModal';
import { MoreResultsNotice } from '@components/results/elements/MoreResultsNotice';
import ResultItemList from '@components/results/elements/ResultItemList';
import HadithResultItem from '@components/results/items/HadithResultItem';
import HadithSkeleton from '@components/skeletons/HadithSkeleton';
import { type MySnackbarProps } from '@components/snackbar/Snackbar';

import { ReactiveComponent, StateProvider } from '@appbaseio/reactivesearch';
import { chainsLinksIndex } from '@config/reactive-search';
import { ROUTING } from '@hooks/useParams';
import useTranslation from '@hooks/useTranslation';
import { ReactiveSearchLayout } from '@layout';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
    Box,
    IconButton,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { WordConfig, onExcelExport, onWordExport } from '@utils/export';
import { highlightSrcTarget } from '@utils/highlightSrcTarget';
import { NarratorColor } from '@utils/narratorColor';
import { numberWithCommas } from '@utils/numbersDisplay';
import { sampleHadith } from '@utils/sampleHadith';

import NarratorStudentsFilters from './NarratorStudentsFilters';
import { NarrationsLinksDataFields } from 'constants/datafields';
import {
    HadithNarratorStudentsViewFilters,
    NarratorStudentsViewFilters,
} from 'constants/filters';
import {
    Link,
    NarratorHadith,
    NextState,
    StatePrefix,
} from 'shared/interfaces/hadith';
import { checkFiltersSelected } from 'shared/methods';
import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';

const ResultItems = z.object({
    loading: z.boolean(),
    data: z.array(
        z.object({
            link: Link,
            hadith: NarratorHadith,
            _id: z.string(),
        }),
    ),
});

const size = 10;

// TODO[chammaaomar]: Handle nullness properly
const NarratorStudents = ({
    narratorId,
    containerId,
    setShowSnackbar,
    routing = ROUTING.HADITH_PAGE_NARRATOR,
}: {
    narratorId?: string;
    containerId?: string;
    setShowSnackbar: (x: MySnackbarProps) => void;
    routing?: ROUTING;
}) => {
    const router = useRouter();
    const { t } = useTranslation('library');
    const [hasSelectedFilters, setHasSelectedFilters] = useState(false);

    const scrollToTopMin =
        typeof window !== 'undefined' ? window.innerHeight * 3 : undefined;

    const [totalResults, setTotalResults] = useState(0);
    const [totalMatns, setTotalMatns] = useState(0);
    const [studentsColors, setStudentsColors] = useState<NarratorColor[]>([]);
    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const exportDisabled = totalResults > 200 || isMobile;

    const [wordConfigOpen, setWordConfigOpen] = useState(false);

    const [fullSearchState, setFullSearchState] = useState<
        NextState | undefined
    >(undefined);

    const uniqueMatnTooltip = (
        <Tooltip
            placement="top"
            title={<Typography fontSize={12}>{t('matn_info')}</Typography>}
            enterTouchDelay={1}
        >
            <InfoOutlinedIcon
                fontSize="small"
                sx={{
                    display: 'inline',
                    pl: '4px',
                    pt: '4px',
                }}
            />
        </Tooltip>
    );

    const resultStatsLabel = hasSelectedFilters
        ? t('narrations_count_with_filters')
        : t('narrations_count');

    const resultStatsMessage = isMobile ? (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <Typography variant="body1" color="primary">
                {`${t('narrations_count')}: ${numberWithCommas(totalResults)}`}
            </Typography>
            {/* <Box sx={{ position: 'relative', display: 'inline' }}>
                <Typography
                    variant="body1"
                    sx={{
                        color: colors.primary,
                        ml: 1,
                    }}
                >
                    {`(${numberWithCommas(totalMatns)} ${t('matn')})`}
                    {uniqueMatnTooltip}
                </Typography>
            </Box> */}
        </Box>
    ) : (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <Typography variant="body1" color="primary">
                {`${resultStatsLabel} : ${numberWithCommas(totalResults)}`}
            </Typography>
            {/* <Box sx={{ position: 'relative', display: 'inline' }}>
                <Typography
                    variant="body1"
                    sx={{
                        color: colors.primary,
                        ml: 1,
                    }}
                >
                    {`(${numberWithCommas(totalMatns)} ${t('matn')})`}
                    {uniqueMatnTooltip}
                </Typography>
            </Box> */}
        </Box>
    );

    const [collapsed, setCollapsed] = useState(false);

    if (!narratorId) {
        // TODO[chammaaomar]: right way to handle null?
        return null;
    }

    const StudentsViewFilters:
        | typeof NarratorStudentsViewFilters
        | typeof HadithNarratorStudentsViewFilters =
        routing === ROUTING.NARRATOR_PAGE
            ? NarratorStudentsViewFilters
            : HadithNarratorStudentsViewFilters;

    const statePrefix: StatePrefix =
        routing === ROUTING.NARRATOR_PAGE ? 'narr_student_' : 'student_';

    return (
        <SearchBase
            app={chainsLinksIndex}
            setSearchParams={(newURL) => {
                router.push(newURL, undefined, {
                    shallow: true,
                });
            }}
        >
            <ReactiveComponent
                componentId={StudentsViewFilters.NARRATOR_ID}
                customQuery={() => ({
                    query: {
                        match: {
                            [NarrationsLinksDataFields.LINK_TGT_ID]: narratorId,
                        },
                    },
                })}
            />
            <ReactiveSearchLayout
                hasSelectedFilters={hasSelectedFilters}
                collapsed={collapsed}
                setCollapsed={setCollapsed}
                filters={(setDrawerFiltersOpen) => (
                    <NarratorStudentsFilters
                        studentsColors={studentsColors}
                        setStudentsColors={setStudentsColors}
                        routing={routing}
                        setDrawerFiltersOpen={setDrawerFiltersOpen}
                    />
                )}
                resultStatsMessage={resultStatsMessage}
                results={(handleFilter) => (
                    <>
                        <FiltersSelected />
                        <ResultItemList
                            hasSelectedFilters={hasSelectedFilters}
                            componentId={StudentsViewFilters.RESULT}
                            dataField={NarrationsLinksDataFields.LINK_SRC_ID}
                            onData={(items) => {
                                setTotalResults(
                                    items.resultStats.numberOfResults,
                                );
                            }}
                            dependencies={{
                                and: [
                                    StudentsViewFilters.STUDENTS_FILTERS,
                                    StudentsViewFilters.BOOKS_FILTERS,
                                    StudentsViewFilters.GRADES_FILTERS,
                                    StudentsViewFilters.HADITH_VERB_FILTERS,
                                    StudentsViewFilters.TYPES_FILTERS,
                                    StudentsViewFilters.NARRATOR_ID,
                                    StudentsViewFilters.SINGULARITY_TOGGLE,
                                ],
                            }}
                            includeFields={[
                                NarrationsLinksDataFields.HADITH,
                                NarrationsLinksDataFields.LINK,
                                NarrationsLinksDataFields.NARRATIONS,

                                NarrationsLinksDataFields.RULINGS,
                                NarrationsLinksDataFields.RULINGS_RULER,
                                NarrationsLinksDataFields.RULINGS_RULING,
                                NarrationsLinksDataFields.RULINGS_BOOK_NAME,
                                NarrationsLinksDataFields.RULINGS_PAGE,
                                NarrationsLinksDataFields.RULINGS_VOLUME,
                            ]}
                            defaultQuery={() => {
                                return {
                                    track_total_hits: true,
                                    sort: [
                                        {
                                            [NarrationsLinksDataFields.HADITH_AUTHENTICITY_ORDER]:
                                                { order: 'asc' },
                                        },
                                        {
                                            [NarrationsLinksDataFields.HADITH_HADITH_SERIAL_ID]:
                                                { order: 'asc' },
                                        },
                                    ],
                                    aggs: {
                                        // unique_matns: {
                                        //     cardinality: {
                                        //         field: NarrationsLinksDataFields.NARRATIONS_GROUP_ID,
                                        //         precision_threshold: 15000,
                                        //     },
                                        // },
                                        unique_students: {
                                            cardinality: {
                                                field: NarrationsLinksDataFields.LINK_SRC_ID,
                                            },
                                        },
                                        unique_grades: {
                                            cardinality: {
                                                field: NarrationsLinksDataFields.LINK_SRC_GRADE,
                                            },
                                        },
                                        unique_verbs: {
                                            cardinality: {
                                                field: NarrationsLinksDataFields.LINK_VERB,
                                            },
                                        },
                                    },
                                };
                            }}
                            highlightLegend={
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent={
                                        isMobile ? 'space-between' : 'flex-end'
                                    }
                                    flexGrow={isMobile ? 1 : 0}
                                    gap={1}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            gap: 1,
                                        }}
                                    >
                                        <Typography
                                            variant="body2"
                                            style={{
                                                backgroundColor:
                                                    'rgba(255, 255, 0, 0.9)',
                                                padding: '5px 12px',
                                                borderRadius: '10px',
                                                fontWeight: 600,
                                                color: '#121212',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            {t('narrator')}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            style={{
                                                backgroundColor:
                                                    'rgba(0, 255, 255, 0.3)',
                                                padding: '5px 12px',
                                                fontWeight: 600,

                                                borderRadius: '10px',
                                                color: '#121212',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            {t('narrated_on_his_behalf')}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ position: 'relative' }}>
                                        {fullSearchState && exportDisabled && (
                                            <CustomTooltip
                                                message={
                                                    isMobile
                                                        ? t(
                                                              'feature_not_available_on_mobile',
                                                          )
                                                        : t('export_disabled')
                                                }
                                            />
                                        )}
                                        {fullSearchState && (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                }}
                                            >
                                                <IconButton
                                                    onClick={
                                                        exportDisabled
                                                            ? undefined
                                                            : () =>
                                                                  onExcelExport(
                                                                      fullSearchState,
                                                                      statePrefix,
                                                                  )
                                                    }
                                                >
                                                    {
                                                        <ExcelSvg
                                                            width={24}
                                                            height={24}
                                                            disabled={
                                                                exportDisabled
                                                            }
                                                        />
                                                    }
                                                </IconButton>
                                                <IconButton
                                                    onClick={
                                                        exportDisabled
                                                            ? undefined
                                                            : () =>
                                                                  setWordConfigOpen(
                                                                      true,
                                                                  )
                                                    }
                                                    disabled={exportDisabled}
                                                >
                                                    <WordSvg
                                                        width={24}
                                                        height={24}
                                                        disabled={
                                                            exportDisabled
                                                        }
                                                    />
                                                </IconButton>
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                            }
                            resultStatsMessage={resultStatsMessage}
                            size={size}
                            resultItems={(items) => {
                                const { data, loading } =
                                    ResultItems.parse(items);

                                const hadiths = data.map((item) => {
                                    const srcId = item.link.src_id;
                                    const targetId = item.link.tgt_id;

                                    item.hadith.hadith = highlightSrcTarget(
                                        item.hadith.hadith,
                                        srcId,
                                        targetId,
                                    );

                                    // if the student is an author and is not found in the body of the hadith
                                    // highlight the book name
                                    const bookNameRender =
                                        !item.hadith.hadith.includes(
                                            '<highlight-cyan>',
                                        ) && srcId.startsWith('a-') ? (
                                            <span
                                                style={{
                                                    backgroundColor:
                                                        'rgba(0, 255, 255, 0.3)',
                                                    padding: '3px 4px',
                                                    borderRadius: '10px',
                                                }}
                                            >
                                                {item.hadith.book_name}
                                            </span>
                                        ) : null;

                                    return (
                                        <HadithResultItem
                                            // TODO: Use keys such as the hadith ID, but we want unique keys that don't cause
                                            // rendering issues
                                            key={uuidv4()}
                                            hadith_object={item.hadith}
                                            allowNarratorClick
                                            routing={routing}
                                            narratorsColors={studentsColors}
                                            setShowSnackbar={setShowSnackbar}
                                            bookNameRender={bookNameRender}
                                            reportErrorType="narrator-student"
                                        />
                                    );
                                });

                                if (loading && data.length > size) {
                                    return hadiths.concat(
                                        Array(size)
                                            .fill(0)
                                            .map(() => (
                                                <HadithSkeleton
                                                    key={uuidv4()}
                                                    withActions={false}
                                                />
                                            )),
                                    );
                                } else if (loading) {
                                    // filtering, not paginating, since we have one page only (data.length <= size)
                                    // return a single page of skeletons, no Hadiths
                                    // the *2 heuristic is because a general skeleton is shorter than a Hadith, not perfect
                                    return Array(size * 2)
                                        .fill(0)
                                        .map(() => (
                                            <HadithSkeleton
                                                key={uuidv4()}
                                                withActions={false}
                                            />
                                        ));
                                }

                                return hadiths.concat(
                                    <MoreResultsNotice
                                        key="more-results-notice"
                                        moreResults={
                                            hadiths.length < totalResults
                                        }
                                    />,
                                );
                            }}
                            loader={Array(size)
                                .fill(0)
                                .map(() => (
                                    <HadithSkeleton
                                        key={uuidv4()}
                                        withActions={false}
                                    />
                                ))}
                            stream={true}
                            showPagination={false}
                            scrollTarget={containerId}
                            onFilterClick={handleFilter}
                        />
                        {/* since react-scroll-to-top only works with window, not
                        scrollable containers */}
                        {/* {!containerId && (
                            <ScrollToTop
                                smooth
                                top={scrollToTopMin}
                                component={
                                    <KeyboardArrowUpIcon
                                        sx={{ color: 'white' }}
                                    />
                                }
                                style={{
                                    backgroundColor: colors.secondary,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    bottom: '80px',
                                }}
                            />
                        )} */}
                        <StateProvider
                            includeKeys={[
                                'value',
                                'hits',
                                'aggregations',
                                'error',
                                'query',
                                'aggs',
                                'size',
                                'includeFields',
                                'react',
                                'sort',
                            ]}
                            onChange={(state, nextState) => {
                                checkFiltersSelected(
                                    { ...state, ...nextState },
                                    hasSelectedFilters,
                                    setHasSelectedFilters,
                                    statePrefix,
                                );

                                const fullState: NextState = {
                                    ...state,
                                    ...nextState,
                                };
                                setFullSearchState(fullState);

                                // if (
                                //     nextState[StudentsViewFilters.RESULT]
                                //         ?.aggregations
                                // ) {
                                //     setTotalMatns(
                                //         nextState[StudentsViewFilters.RESULT]
                                //             .aggregations.unique_matns.value,
                                //     );
                                // }
                            }}
                        />
                    </>
                )}
            />
            {wordConfigOpen && (
                <CopyHadithModal
                    onClose={() => setWordConfigOpen(false)}
                    setShowSnackbar={setShowSnackbar}
                    hadithHtml={sampleHadith.hadith}
                    hadith={sampleHadith as NarratorHadith}
                    title={t('word_config')}
                    downloadText={t('download_word')}
                    onWordExport={(config: WordConfig) => {
                        if (fullSearchState) {
                            onWordExport(fullSearchState, statePrefix, config);
                        }
                    }}
                />
            )}
        </SearchBase>
    );
};

export default NarratorStudents;
