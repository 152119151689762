import { getDefaultQuerySort } from '@components/views/MainSearchViews/function';

import { NarrationsDataFields } from '@constants/datafields';
import { SORT_OPTIONS } from '@constants/sorts';

import { SearchType } from 'shared/interfaces/hadith';

// For exact match (searchType === "phrase"), we return array of length 1 since we want only to match_phrase
// For inexact match (searchType === "token"), we return an array of length 2, since we want to match_phrase or match_token
// this array is then used in a "should" clause in Elastic DSL, which corresponds to "OR" boolean logic
export const getShouldMatch = (searchType: SearchType, query: string) => {
    if (!query) {
        return [];
    }

    if (searchType === 'phrase') {
        return [
            {
                match_phrase: {
                    [NarrationsDataFields.HADITH_EXACT]: {
                        query,
                        _name: 'non_normalized_phrase_query',
                    },
                },
            },
        ];
    }

    return [
        {
            match_phrase: {
                [NarrationsDataFields.HADITH]: {
                    query,
                    _name: 'phrase_query',
                },
            },
        },
        {
            match: {
                [NarrationsDataFields.HADITH_TOKENIZED]: {
                    query,
                    _name: 'token_query',
                },
            },
        },
    ];
};

interface IHighlightedFields {
    [key: string]: { pre_tags: string[]; post_tags: string[] };
}

export const getHighlight = (searchType: SearchType) => {
    if (searchType === 'phrase') {
        return {
            highlight: {
                fields: {
                    [NarrationsDataFields.HADITH_EXACT]: {
                        pre_tags: ['<highlight>'],
                        post_tags: ['</highlight>'],
                    },
                },
                number_of_fragments: 0,
            },
        };
    }

    return {
        highlight: {
            fields: {
                [NarrationsDataFields.HADITH]: {
                    pre_tags: ['<highlight>'],
                    post_tags: ['</highlight>'],
                },
                [NarrationsDataFields.HADITH_TOKENIZED]: {
                    pre_tags: ['<highlight-light>'],
                    post_tags: ['</highlight-light>'],
                },
            },
            number_of_fragments: 0,
        },
    };
};

export function getMainSearchAggs() {
    return {
        aggs: {
            // unique_matns: {
            //     cardinality: {
            //         field: NarrationsDataFields.NARRATIONS_GROUP_ID,
            //         precision_threshold: 15000,
            //     },
            // },
            unique_narrators: {
                cardinality: {
                    field: NarrationsDataFields.NARRATORS_FULL_NAMES,
                },
            },
            unique_chapters: {
                cardinality: {
                    field: NarrationsDataFields.CHAPTER,
                },
            },
            unique_sub_chapters: {
                cardinality: {
                    field: NarrationsDataFields.SUB_CHAPTER,
                },
            },
        },
    };
}

export function getMainSearchDefaultQuery(
    sortBy: SORT_OPTIONS,
    searchType: SearchType,
) {
    return {
        track_total_hits: true,
        sort: getDefaultQuerySort(sortBy, searchType),
        ...getMainSearchAggs(),
        ...getHighlight(searchType),
    };
}
