import { ToggleButton, ToggleButtonGroup } from '@mui/material';

import { CustomTooltip } from './CustomTooltip';
import { NarrationsType } from 'shared/interfaces/hadith';

export function NarrationsTypeToggle({
    narrationsType,
    setNarrationsType,
    disabledShawahed,
    disabledShawahedMessage,
}: {
    narrationsType: NarrationsType;
    setNarrationsType: (x: NarrationsType) => void;
    disabledShawahed: boolean;
    disabledShawahedMessage: string;
}) {
    return (
        <ToggleButtonGroup
            color="secondary"
            value={narrationsType}
            exclusive
            onChange={(_e, newType) =>
                setNarrationsType(newType as NarrationsType)
            }
            aria-label="نوع التخريج"
            sx={{ mb: 1, position: 'relative' }}
        >
            {disabledShawahed && (
                <CustomTooltip message={disabledShawahedMessage} />
            )}
            <ToggleButton
                value={'roads' as NarrationsType}
                sx={{ whiteSpace: 'nowrap', wordBreak: 'keep-all' }}
            >
                طرق
            </ToggleButton>
            <ToggleButton
                value={'shawahed' as NarrationsType}
                disabled={disabledShawahed}
                sx={{ whiteSpace: 'nowrap', wordBreak: 'keep-all' }}
            >
                شواهد
            </ToggleButton>
            {/* <ToggleButton
                value={'all' as NarrationsType}
                disabled={disabledShawahed}
                sx={{ whiteSpace: 'nowrap', wordBreak: 'keep-all' }}
            >
                طرق وشواهد
            </ToggleButton> */}
        </ToggleButtonGroup>
    );
}
