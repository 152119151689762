// @ts-nocheck
import { useState } from 'react';

import ScrollToTop from 'react-scroll-to-top';

import { BookPreview } from '@components/bookpreview';
import { booksConfig } from '@components/bookpreview/bookConfigs';
import FiltersSelected from '@components/filters/elements/FiltersSelected';
import ResultItemList from '@components/results/elements/ResultItemList';
import ReferencesPageFilters from '@components/views/ReferencesViews/ReferencesPageFilters';

import useTranslation from '@hooks/useTranslation';
import { ReactiveSearchLayout } from '@layout';
import { InfoOutlined } from '@mui/icons-material';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Skeleton, type Theme, Tooltip } from '@mui/material';
import { Typography, lighten, useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/system';
import { numberWithCommas } from '@utils/numbersDisplay';

import { ReferencesDataFields } from 'constants/datafields';
import { ReferencesFilters } from 'constants/filters';
import type { EditionRefs } from 'shared/interfaces/hadith';
import { colors } from 'theme';

const StyledTableRow = styled(TableRow)`
    &:hover {
        background-color: rgba(0, 0, 0, 0.02);
    }
    transition: background-color 0.1s ease-in-out;
`;

const StyledTableHeader = styled(TableRow)`
    background-color: ${colors.hover};
`;

const StyledHeaderCell = styled(TableCell)`
    background-color: ${colors.hover};
    color: ${colors.secondary};
`;

const StyledBodyCell = styled(TableCell)`
    color: #000;
`;

function ReferencesLoader({ theme }: { theme: Theme }) {
    return (
        <TableContainer
            component={Paper}
            sx={{
                mt: 1,
                borderRadius: '10px',
                border: `1px solid ${theme.palette.divider}`,
            }}
            elevation={0}
        >
            <Table aria-label="a dense table">
                <TableBody>
                    {[...Array(20).keys()].map((index) => (
                        <StyledTableRow key={index}>
                            {[...Array(4).keys()].map((cellIndex) => (
                                <StyledBodyCell key={cellIndex}>
                                    <Skeleton animation="wave" />
                                </StyledBodyCell>
                            ))}
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

const References = ({ filters, hasSelectedFilters }) => {
    const { t } = useTranslation('references');
    const scrollToTopMin =
        typeof window !== 'undefined' ? window.innerHeight * 3 : undefined;
    const [totalResults, setTotalResults] = useState(0);

    const resultStatsLabel = hasSelectedFilters
        ? t('references_count_with_filters')
        : t('references_count');

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [bookToPreview, setBookToPreview] = useState('');
    const [openPreviewModal, setOpenPreviewModal] = useState<boolean>(false);
    const resultStatsMessage = isMobile ? (
        <Typography variant="body1" color="primary">
            {`${t('references_count')}: ${numberWithCommas(totalResults)}`}
        </Typography>
    ) : (
        <Typography variant="body1" color="primary">
            {`${resultStatsLabel}: ${numberWithCommas(totalResults)}`}
        </Typography>
    );

    function LinearProgressWithLabel(
        props: LinearProgressProps & { value: number; status_: string },
    ) {
        const progress = props.value !== 0 ? `(${props.value}%)` : '';
        return (
            <Box {...props}>
                <Typography
                    variant="body2"
                    sx={{ py: 1 }}
                >{`${props.status_} ${progress}`}</Typography>
            </Box>
        );
    }
    const [collapsed, setCollapsed] = useState(false);

    const editionsRefs: EditionRefs = booksConfig[bookToPreview]
        ? Object.keys(booksConfig[bookToPreview].editions ?? {})
              .concat([booksConfig[bookToPreview].bookCopy])
              .map((edition) => ({ edition, page: 1, volume: 1 }))
        : [];

    return (
        <>
            <ReactiveSearchLayout
                hasSelectedFilters={hasSelectedFilters}
                filters={(setDrawerFiltersOpen) => (
                    <ReferencesPageFilters
                        filters={filters}
                        setDrawerFiltersOpen={setDrawerFiltersOpen}
                    />
                )}
                resultStatsMessage={resultStatsMessage}
                collapsed={collapsed}
                setCollapsed={setCollapsed}
                results={(handleFilter) => (
                    <>
                        <FiltersSelected />
                        <ResultItemList
                            hasSelectedFilters={hasSelectedFilters}
                            // to turn off the default "loading..." loader
                            // if you put a nullish string, such as '' or null
                            // it still shows up
                            loader={' '}
                            resultStatsMessage={resultStatsMessage}
                            componentId={ReferencesFilters.RESULT}
                            dataField={ReferencesDataFields.BOOK_NAME}
                            onData={(data) =>
                                setTotalResults(
                                    data.resultStats.numberOfResults,
                                )
                            }
                            showPagination={false}
                            stream={true}
                            //to show all at the same time
                            size={1000}
                            onFilterClick={handleFilter}
                            includeFields={[
                                ReferencesDataFields.ID,
                                ReferencesDataFields.AUTHOR,
                                ReferencesDataFields.BOOK_NAME,
                                ReferencesDataFields.TYPE,
                                ReferencesDataFields.STATUS,
                                ReferencesDataFields.PROGRESS,
                                ReferencesDataFields.EDITION,
                            ]}
                            resultItems={(items) => {
                                const final_result =
                                    totalResults === 0 ? (
                                        <ReferencesLoader theme={theme} />
                                    ) : (
                                        <TableContainer
                                            component={Paper}
                                            sx={{
                                                mt: 1,
                                                borderRadius: '10px',
                                                border: `1px solid ${theme.palette.divider}`,
                                            }}
                                            elevation={0}
                                        >
                                            <Table aria-label="a dense table">
                                                <TableBody>
                                                    <StyledTableHeader>
                                                        <StyledHeaderCell>
                                                            {t('type')}
                                                        </StyledHeaderCell>
                                                        <StyledHeaderCell>
                                                            {t('bookName')}
                                                        </StyledHeaderCell>
                                                        <StyledHeaderCell
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                                gap: 1,
                                                            }}
                                                        >
                                                            {t('edition')}
                                                            <Tooltip
                                                                title={t(
                                                                    'edition_tooltip',
                                                                )}
                                                                placement="top"
                                                                arrow
                                                            >
                                                                <InfoOutlined
                                                                    sx={{
                                                                        cursor: 'pointer',
                                                                    }}
                                                                />
                                                            </Tooltip>
                                                        </StyledHeaderCell>
                                                        <StyledHeaderCell>
                                                            {t('author')}
                                                        </StyledHeaderCell>
                                                        <StyledHeaderCell>
                                                            {t('status')}
                                                        </StyledHeaderCell>
                                                        <StyledHeaderCell></StyledHeaderCell>
                                                    </StyledTableHeader>
                                                    {items.data.map(
                                                        (item, index) => (
                                                            <StyledTableRow
                                                                key={index}
                                                                sx={{
                                                                    '&:last-child td, &:last-child th':
                                                                        {
                                                                            border: 0,
                                                                        },
                                                                }}
                                                            >
                                                                <StyledBodyCell>
                                                                    {item.type}
                                                                </StyledBodyCell>
                                                                <StyledBodyCell>
                                                                    {
                                                                        item.book_name
                                                                    }
                                                                </StyledBodyCell>
                                                                <StyledBodyCell>
                                                                    {
                                                                        item.edition
                                                                    }
                                                                </StyledBodyCell>
                                                                <StyledBodyCell>
                                                                    {
                                                                        item.author
                                                                    }
                                                                </StyledBodyCell>
                                                                <StyledBodyCell>
                                                                    <Box
                                                                        sx={{
                                                                            width: '100%',
                                                                            textAlign:
                                                                                'center',
                                                                        }}
                                                                    >
                                                                        <LinearProgressWithLabel
                                                                            value={
                                                                                item.progress
                                                                            }
                                                                            status_={
                                                                                item.status
                                                                            }
                                                                            sx={{
                                                                                backgroundColor:
                                                                                    item.progress ===
                                                                                        100 &&
                                                                                    !isMobile
                                                                                        ? lighten(
                                                                                              '#00FF00',
                                                                                              0.8,
                                                                                          )
                                                                                        : item.progress >=
                                                                                              70 &&
                                                                                          !isMobile
                                                                                        ? lighten(
                                                                                              '#4CAF50',
                                                                                              0.8,
                                                                                          )
                                                                                        : item.progress >=
                                                                                              1 &&
                                                                                          !isMobile
                                                                                        ? lighten(
                                                                                              '#FFA500',
                                                                                              0.8,
                                                                                          )
                                                                                        : item.progress <
                                                                                              1 &&
                                                                                          !isMobile
                                                                                        ? lighten(
                                                                                              '#808080',
                                                                                              0.8,
                                                                                          )
                                                                                        : 'transparent',
                                                                                color:
                                                                                    item.progress ===
                                                                                    100
                                                                                        ? '#4CAF50'
                                                                                        : item.progress >=
                                                                                          70
                                                                                        ? '#4CAF50'
                                                                                        : item.progress >=
                                                                                          1
                                                                                        ? '#FFA500'
                                                                                        : '#808080',
                                                                                borderRadius:
                                                                                    '20px',
                                                                            }}
                                                                        />
                                                                    </Box>
                                                                </StyledBodyCell>
                                                                <StyledBodyCell>
                                                                    <Tooltip
                                                                        title={
                                                                            booksConfig[
                                                                                item
                                                                                    .book_name
                                                                            ]
                                                                                ? ''
                                                                                : t(
                                                                                      'book_perview_not_clickable',
                                                                                  )
                                                                        }
                                                                        placement="top"
                                                                    >
                                                                        <FindInPageIcon
                                                                            sx={{
                                                                                cursor: booksConfig[
                                                                                    item
                                                                                        .book_name
                                                                                ]
                                                                                    ? 'pointer'
                                                                                    : 'not-allowed',
                                                                                color: booksConfig[
                                                                                    item
                                                                                        .book_name
                                                                                ]
                                                                                    ? '#C7956D'
                                                                                    : '#ccc',
                                                                            }}
                                                                            onClick={() => {
                                                                                setBookToPreview(
                                                                                    item.book_name,
                                                                                );
                                                                                setOpenPreviewModal(
                                                                                    true,
                                                                                );
                                                                            }}
                                                                        />
                                                                    </Tooltip>
                                                                </StyledBodyCell>
                                                            </StyledTableRow>
                                                        ),
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    );
                                return final_result;
                            }}
                            dependencies={{
                                and: [
                                    ReferencesFilters.CONTENT_SEARCH,
                                    ReferencesFilters.AUTHOR_FILTER,
                                    ReferencesFilters.BOOK_FILTER,
                                    ReferencesFilters.STATUS_FILTER,
                                ],
                            }}
                        />
                        {booksConfig[bookToPreview] && openPreviewModal && (
                            <BookPreview
                                volume={1}
                                page={1}
                                open={openPreviewModal}
                                setOpen={setOpenPreviewModal}
                                initBookConfig={booksConfig[bookToPreview]}
                                initNavPointId={bookToPreview}
                                quickNavPoints={[
                                    {
                                        page: 1,
                                        volume: 1,
                                        book_name: bookToPreview,
                                        editionsRefs,
                                        id: bookToPreview,
                                        label: bookToPreview,
                                        group: bookToPreview,
                                    },
                                ]}
                                initEditionsRefs={editionsRefs}
                            />
                        )}
                    </>
                )}
            />
            <ScrollToTop
                smooth
                top={scrollToTopMin}
                component={<KeyboardArrowUpIcon sx={{ color: 'white' }} />}
                style={{
                    backgroundColor: colors.secondary,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bottom: '80px',
                }}
            />
        </>
    );
};

export default References;
