import { NextRouter } from 'next/router';

/**
 * Update query param from router
 * @param {router} router  from next/router
 * @param {param} param search query param to update
 * @param {value} value value to update param with
 * @param {shallow} shallow whether to use shallow routing or not, default is false
 */
const updateQueryParamFromRouter = (
    router: NextRouter,
    param: string,
    value: any[] = [],
    shallow = false,
) => {
    router.push(
        {
            pathname: router.pathname,
            query: {
                ...router.query,
                [param]: JSON.stringify(value),
            },
        },
        undefined,
        /**
         * Do not refresh the page
         */
        { shallow },
    );
};

export default updateQueryParamFromRouter;
