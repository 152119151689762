export interface NarratorColor {
    key: string;
    color: string;
}

//https://sashamaps.net/docs/resources/20-colors/
export const NARRATOR_COLORS = [
    '#EF6C00',
    '#304FFE',
    '#43A047',
    '#00ACC1',
    '#5E35B1',
    '#911eb4',
    '#46f0f0',
    '#f032e6',
    '#bcf60c',
    '#fabebe',
    '#008080',
    '#e6beff',
    '#9a6324',
    '#fffac8',
    '#800000',
    '#aaffc3',
    '#808000',
    '#ffd8b1',
    '#000075',
    '#808080',
];

export const pickNarratorColor = (narratorsColors: NarratorColor[]) => {
    const availableColors = NARRATOR_COLORS.filter(
        (color) => !narratorsColors.find((x) => x.color === color),
    );
    return availableColors[0];
};

export const getNarratorColor = (
    narratorName: string,
    narratorColors: NarratorColor[],
) => {
    return narratorColors.find((x) => x.key === narratorName)?.color;
};

export const handleNarratorColorChange = (
    newList: string[],
    narratorsColors: NarratorColor[],
    setNarratorsColors: (colors: NarratorColor[]) => void,
) => {
    // 1. Find deleted narrator
    const deletedNarrator = narratorsColors.filter(
        (x) => !newList?.some((e) => e === x.key),
    )[0];

    // 2. Find new narrator
    const newNarrator = newList?.filter(
        (x) => !narratorsColors.some((e) => e.key === x),
    )[0];

    if (deletedNarrator) {
        setNarratorsColors(
            narratorsColors.filter((x) => x.key !== deletedNarrator.key),
        );
    }

    if (newNarrator) {
        setNarratorsColors([
            ...narratorsColors,
            {
                key: newNarrator,
                color: pickNarratorColor(narratorsColors),
            },
        ]);
    }
};
