import { useRouter } from 'next/router';

import type { MySnackbarProps } from '@components/snackbar/Snackbar';
import { AmbiguousView } from '@components/views/AmbiguousViews/AmbiguousView';

import { mainSearchStateKeys } from '@constants/filters';
import { HadithViews } from '@constants/views';
import { useContextualRouting } from '@hooks';
import { Box, FormControl } from '@mui/material';

import HadithAmbiguitiesList from '../NarratorsController/AmbiguousList';
import { SearchPageHadith } from 'shared/interfaces/hadith';

interface AmbiguousControllerProps {
    /**
     * @description hadith
     */
    hadith: SearchPageHadith;
    setShowSnackbar: (x: MySnackbarProps) => void;
    selectedAmbiguousId: string;
    explanationIds: number[];
}

/**
 * @description
 * @info follows this page pattern: '/[page]/[hadithId]/[hadithView]'
 */
const AmbiguousController = ({
    hadith,
    selectedAmbiguousId,
    explanationIds,
    setShowSnackbar,
}: AmbiguousControllerProps) => {
    const router = useRouter();
    const { makeContextualHref } = useContextualRouting({
        queryToKeep: mainSearchStateKeys,
    });
    const handleAmbiguousChange = (id: string | number) => {
        return router.push(
            makeContextualHref({
                hadithId: hadith.hadith_id,
                hadithView: HadithViews.AMBIGUOUS_WORDS,
                ambg_id: id,
            }),
            undefined,
            { shallow: true },
        );
    };

    //Include only unique references, and references which actually exist in the hadith (There are cases where tashkeel is different from original data source)
    const uniqueAmbiguities = hadith.ambiguous.filter(
        (obj, index, self) =>
            self.findIndex((t) => t.reference === obj.reference) === index,
    );

    return (
        <Box mt={1}>
            <Box mt={2}>
                <Box
                    display={'flex'}
                    alignItems={'center'}
                    sx={{
                        justifyContent: { xs: 'center', md: 'flex-start' },
                    }}
                >
                    <FormControl
                        variant="standard"
                        sx={{ mt: { xs: 1, md: 0 }, minWidth: 300 }}
                    >
                        <HadithAmbiguitiesList
                            ambiguities={uniqueAmbiguities}
                            selectedAmbiguousId={selectedAmbiguousId}
                            handleAmbiguousChange={handleAmbiguousChange}
                        />
                    </FormControl>
                </Box>

                <AmbiguousView
                    hadithId={hadith.hadith_id}
                    selectedAmbiguousId={selectedAmbiguousId}
                    explanationIds={explanationIds}
                />
            </Box>
        </Box>
    );
};

export default AmbiguousController;
