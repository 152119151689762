import useTranslation from '@hooks/useTranslation';
import {
    Box,
    ButtonBase,
    type SxProps,
    type Theme,
    useMediaQuery,
    useTheme,
} from '@mui/material';

export default function ExactInExactTabs({
    textSearch,
    isTokenMatch,
    onTokenClick,
    onPhraseClick,
}: {
    textSearch: boolean;
    isTokenMatch: boolean;
    onTokenClick: () => void;
    onPhraseClick: () => void;
}) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const isPhraseMatch = !isTokenMatch;

    const { t } = useTranslation('library');

    const phraseText = t('exact_match');

    if (!textSearch) {
        return null;
    }

    const searchTypesSx: SxProps<Theme> = isMobile
        ? // takes up full screen and center in mobile
          {
              display: 'flex',
              alignItems: 'end',
              justifyContent: 'center',
              pr: 0,
              mt: 0,
              position: 'relative',
              flexDirection: 'row',
              width: '100%',
          }
        : // to the right in desktop
          {
              display: 'flex',
              alignItems: 'end',
              justifyContent: 'right',
              pr: 0,
              mt: 0,
              position: 'relative',
              flexDirection: 'row',
          };

    const tokenMatchesSx: SxProps<Theme> = isMobile
        ? {
              color: isTokenMatch ? '#fff' : '#000',
              backgroundColor: isTokenMatch ? '#C7956D' : '#ddd',
              paddingY: '4px',
              ':hover': {
                  cursor: 'pointer',
              },
              fontSize: 14,
              flexGrow: 1,
              textAlign: 'center',
              gap: 0.5,
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              borderTopLeftRadius: '5px',
              borderBottomLeftRadius: '5px',
          }
        : {
              color: isTokenMatch ? '#fff' : '#000',
              backgroundColor: isTokenMatch ? '#C7956D' : '#ddd',
              paddingX: '16px',
              paddingY: '4px',
              display: 'flex',
              alignItems: 'center',
              gap: 0.5,
              borderTopLeftRadius: '5px',
              borderBottomLeftRadius: '5px',
              ':hover': {
                  cursor: !textSearch ? 'default' : 'pointer',
              },
          };

    const phraseMatchesSx: SxProps<Theme> = isMobile
        ? {
              color: isPhraseMatch ? '#fff' : '#000',
              backgroundColor: isPhraseMatch ? '#C7956D' : '#ddd',
              paddingY: '4px',
              ':hover': {
                  cursor: 'pointer',
              },
              fontSize: 14,
              flexGrow: 1,
              textAlign: 'center',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              gap: 1,
              borderTopRightRadius: '5px',
              borderBottomRightRadius: '5px',
              borderRadius: textSearch ? undefined : '5px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
          }
        : {
              color: isPhraseMatch ? '#fff' : '#000',
              backgroundColor: isPhraseMatch ? '#C7956D' : 'rgba(0, 0, 0, 0.1)',

              paddingX: '16px',
              paddingY: '4px',
              borderTopRightRadius: '5px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 1,
              borderBottomRightRadius: '5px',
              ':hover': {
                  cursor: 'pointer',
              },
          };
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    pr: 0,
                    mt: 0,
                }}
            >
                <Box sx={searchTypesSx}>
                    <ButtonBase
                        component="div"
                        onClick={onTokenClick}
                        sx={tokenMatchesSx}
                    >
                        {t('inexact_match')}
                    </ButtonBase>
                    <ButtonBase
                        component="div"
                        onClick={onPhraseClick}
                        sx={phraseMatchesSx}
                    >
                        {phraseText}
                    </ButtonBase>
                </Box>
            </Box>
        </Box>
    );
}
