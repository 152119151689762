import {
    type ReactElement,
    type Ref,
    forwardRef,
    useEffect,
    useState,
} from 'react';

import { ReactiveComponent } from '@appbaseio/reactivesearch';
import { narratorsIndex } from '@config/reactive-search';
import { NarratorsDataFields } from '@constants/datafields';
import { NarratorViews } from '@constants/views';
import useTranslation from '@hooks/useTranslation';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
    Box,
    DialogContent,
    FormControl,
    IconButton,
    InputLabel,
    ListSubheader,
    ListSubheaderProps,
    MenuItem,
    Select,
    Skeleton,
    TextField,
    Typography,
} from '@mui/material';
import { Dialog, Slide, useMediaQuery, useTheme } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { ConditionalWrapper } from '@utils/ConditionalWrapper';
import { numberWithCommas } from '@utils/numbersDisplay';

import { NarratorsPageNarrator } from 'shared/interfaces/hadith';
import { z } from 'zod';

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: ReactElement;
    },
    ref: Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface FullNarratorsListOptions {
    id: string;
    full_name: string;
    narrations_count: string;
    is_companion: boolean;
    is_unknown: boolean;
}

interface FullNarratorsListProps {
    narratorIdFromUrl: string;
    narrators: FullNarratorsListOptions[];
    handleNarratorChange: (id: string | number, view?: NarratorViews) => void;
}

const NarratorsDetails = z.object({
    data: z.object({
        hits: z.object({
            hits: z.array(
                z.object({
                    _source: NarratorsPageNarrator,
                }),
            ),
        }),
    }),
});

function searchNarrators(search: string): Promise<NarratorsPageNarrator[]> {
    return fetch(`/api/narrators?search=${search}`)
        .then((res) => res.json())
        .then((json) => {
            const {
                data: {
                    hits: { hits },
                },
            } = NarratorsDetails.parse(json);

            const narrators = hits.map((hit) => hit._source);
            return narrators;
        })
        .catch((err) => {
            console.error('error searching for narrators', err);
            return [];
        });
}

// to allow proper focussing on the search bar within the <Select>
function MyListSubheader(
    props: ListSubheaderProps & { muiSkipListHighlight: boolean },
) {
    const { muiSkipListHighlight, ...other } = props;
    return <ListSubheader {...other} />;
}

const FullNarratorsList = ({
    narrators,
    handleNarratorChange,
    narratorIdFromUrl,
}: FullNarratorsListProps) => {
    const [openMobileSearchModal, setOpenMobileSearchModal] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const { t } = useTranslation('library');

    const [textInput, setTextInput] = useState('');

    const [narratorHits, setNarratorHits] =
        useState<NarratorsPageNarrator[]>(narrators);

    const [selectedNarratorId, setSelectedNarratorId] =
        useState<string>(narratorIdFromUrl);

    const [selectedNarrator, setSelectedNarrator] =
        useState<FullNarratorsListOptions>();

    const handleDropdownOpen = () => {
        if (isMobile && !openMobileSearchModal) {
            setOpenMobileSearchModal(true);
        }
        setIsDropdownOpen(true);
    };

    const handleDropdownClose = () => {
        setIsDropdownOpen(false);
        setOpenMobileSearchModal(false);
    };
    useEffect(() => {
        fetch(`/api/narrators?narratorId=${selectedNarratorId}`)
            .then((res) => res.json())
            .then((json) => {
                const {
                    data: {
                        hits: {
                            hits: [{ _source }],
                        },
                    },
                } = NarratorsDetails.parse(json);

                setSelectedNarrator(_source);
            })
            .catch((err) =>
                console.error('error fetching narrator details', err),
            );
    }, [selectedNarratorId]);

    if (!selectedNarrator) {
        return <Skeleton variant="text" width="300px" height="100px" />;
    }

    return (
        <ConditionalWrapper
            condition={isMobile && openMobileSearchModal}
            wrap={(wrappedChildren) => (
                <Dialog
                    fullScreen
                    open
                    onClose={handleDropdownClose}
                    TransitionComponent={Transition}
                >
                    <DialogContent sx={{ pl: 0 }}>
                        {wrappedChildren}
                    </DialogContent>
                </Dialog>
            )}
        >
            <>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'row',
                        width: '100%',
                    }}
                >
                    {openMobileSearchModal && (
                        <IconButton onClick={() => handleDropdownClose()}>
                            <ArrowForwardIcon color="secondary" />
                        </IconButton>
                    )}
                    <FormControl
                        variant="standard"
                        sx={{
                            width: '100%',
                        }}
                    >
                        <InputLabel htmlFor="narrator-select">
                            {t('narrators')}
                        </InputLabel>
                        <Select
                            // size="small"
                            id="narrator-select"
                            value={selectedNarratorId}
                            variant="filled"
                            displayEmpty
                            onChange={(e) => {
                                setSelectedNarratorId(e.target.value);
                                // announces the change to other components via url params
                                handleNarratorChange(e.target.value);
                            }}
                            open={isDropdownOpen}
                            onOpen={handleDropdownOpen}
                            onBlur={handleDropdownClose}
                            onClose={() => {
                                // reset the search state
                                setTextInput('');
                                setNarratorHits(narrators);
                                handleDropdownClose();
                            }}
                            MenuProps={{
                                autoFocus: false,
                                keepMounted: true,
                                marginThreshold: 0,
                                slotProps: isMobile
                                    ? {
                                          paper: {
                                              sx: {
                                                  width: '100%',
                                                  maxWidth: '100%',
                                              },
                                          },
                                      }
                                    : undefined,
                                sx: {
                                    height: {
                                        xs: '100%',
                                        sm: '600px',
                                    },
                                    width: isMobile ? '100%' : undefined,
                                    color: '#121212',
                                },
                                disablePortal: true,
                                id: 'narrator-select-menu',
                            }}
                            sx={{
                                maxWidth: '100%',
                                textOverflow: 'ellipsis',
                            }}
                            renderValue={() =>
                                `${
                                    selectedNarrator.full_name
                                } - (${numberWithCommas(
                                    selectedNarrator.narrations_count,
                                )} رواية)`
                            }
                        >
                            <MyListSubheader
                                key="narrator-search-input"
                                muiSkipListHighlight
                                sx={{
                                    paddingTop: '8px',
                                    paddingBottom: '8px',
                                }}
                            >
                                <TextField
                                    label={t('search_for_a_narrator')}
                                    value={textInput}
                                    onChange={(e) => {
                                        if (!e.target.value) {
                                            setTextInput('');
                                            setNarratorHits(narrators);
                                        } else {
                                            setTextInput(e.target.value);
                                            if (e.target.value === '') {
                                                return;
                                            }

                                            searchNarrators(
                                                e.target.value,
                                            ).then((narrs) =>
                                                setNarratorHits(narrs),
                                            );
                                        }
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.keyCode === 13) {
                                            e.preventDefault();
                                        }
                                        e.stopPropagation();
                                    }}
                                    sx={{ mb: 0 }}
                                    variant="outlined"
                                    fullWidth
                                    size="medium"
                                    multiline
                                    maxRows={1}
                                />
                            </MyListSubheader>

                            <MenuItem
                                value=""
                                disabled
                                sx={{
                                    display: narratorHits.length
                                        ? 'none'
                                        : undefined,
                                    width: '100%',
                                }}
                            >
                                {t('no_narrators_found')}
                            </MenuItem>
                            {narratorHits.map((narratorHit) => (
                                <MenuItem
                                    key={narratorHit.id}
                                    value={narratorHit.id}
                                    sx={{
                                        width: '100%',
                                        height: '50px',
                                        whiteSpace: 'normal',
                                    }}
                                >
                                    {/* https://mui.com/material-ui/react-menu/#limitations there's a bug that prevents textOverflow: ellipsis from working, so use noWrap  */}
                                    {narratorHit.full_name} - (
                                    {numberWithCommas(
                                        narratorHit.narrations_count,
                                    )}
                                    {'  '}
                                    رواية )
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </>
        </ConditionalWrapper>
    );
};

export default FullNarratorsList;
