import { useState } from 'react';

import { CustomTooltip } from '@components/CustomTooltip';
import { BookPreview } from '@components/bookpreview';
import { booksConfig } from '@components/bookpreview/bookConfigs';
import OriginalHadithModal from '@components/modal/Modal/OriginalHadithModal';

import { useFontSize } from '@hooks/fontSizeContext';
import { useTashkeel } from '@hooks/tashkeelContext';
import useTranslation from '@hooks/useTranslation';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import PreviewIcon from '@mui/icons-material/Preview';
import {
    Box,
    Button,
    Divider,
    Grid,
    IconButton,
    Typography,
} from '@mui/material';
import { removeTashkeel } from '@utils/tashkeel';
// @ts-ignore this library doesn't have exported types, nor is it part of the definitely typed project
import ReadMoreAndLess from '@vendor/react-read-more-less';

import { getBookAndIdText } from '../helpers';
import { HadithRulingsContainer, HadithRulingsTitleContainer } from './styled';
import { analytics } from 'lib/analytics';
import {
    BookReference,
    Rulings,
    RulingsHadith,
} from 'shared/interfaces/hadith';
import { z } from 'zod';

const HadithModalResult = z.object({
    data: z.object({
        hits: z.object({
            hits: z
                .object({
                    _source: RulingsHadith,
                })
                .array()
                .length(1),
        }),
    }),
});

interface HadithRulingsResultItemProps {
    key: string;
    quickNavPoints: BookReference[];
    ruler: string;
    rulerDod: number;
    rulings: Rulings;
}

const HadithRulingsResultItem = ({
    key,
    quickNavPoints,
    rulings,
    ruler,
}: HadithRulingsResultItemProps) => {
    const { t } = useTranslation('library');
    const { fontSize } = useFontSize();
    const { tashkeelEnabled } = useTashkeel();
    const [rulingPreview, setRulingPreview] = useState<{
        book_name: string;
        initNavPointId: string;
    }>();
    const [selectedHadith, setSelectedHadith] = useState<RulingsHadith | null>(
        null,
    );

    return (
        <>
            {selectedHadith ? (
                <OriginalHadithModal
                    onClose={() => {
                        setSelectedHadith(null);
                    }}
                    originalHadith={selectedHadith}
                />
            ) : null}
            {rulingPreview && (
                <BookPreview
                    open={!!rulingPreview}
                    setOpen={(open: boolean) => {
                        if (!open) {
                            setRulingPreview(undefined);
                        }
                    }}
                    initNavPointId={rulingPreview.initNavPointId}
                    quickNavPoints={quickNavPoints}
                    initEditionsRefs={[]}
                />
            )}
            <HadithRulingsContainer
                key={key}
                elevation={0}
                variant="outlined"
                sx={{ margin: { xs: '10px 0', md: '10px 0' } }}
            >
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <HadithRulingsTitleContainer
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: {
                                    xs: 'space-between',
                                    sm: 'start',
                                },
                                alignItems: 'center',
                                gap: 2,
                                mb: 1,
                            }}
                        >
                            <Typography
                                variant="body1"
                                fontSize={fontSize.subtitle}
                                sx={{ textAlign: 'start' }}
                            >
                                {ruler}
                            </Typography>
                        </HadithRulingsTitleContainer>
                        <Divider />
                        {rulings
                            .sort((a, b) => {
                                if (a.volume !== b.volume) {
                                    return (a.volume ?? 0) > (b.volume ?? 0)
                                        ? 1
                                        : -1;
                                }

                                return (a.page ?? 0) > (b.page ?? 0) ? 1 : -1;
                            })
                            .map((ruling) => {
                                // only defined if 'type' is 'embedded' not 'external'
                                const hadithNumber =
                                    ruling.hadith_id?.split('-')?.[1];
                                return (
                                    <Box
                                        // have to use esIndex here instead of the 'index' coming from .map
                                        // because the index changes upon sorting
                                        key={`${ruling.hadith_id}-${ruling.esIndex}`}
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'flex-start',
                                            justifyContent: 'space-between',
                                            px: 1.5,
                                            py: 1,
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: 1,
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent:
                                                        'space-between',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    fontSize={fontSize.body}
                                                    color="secondary"
                                                    sx={{
                                                        textAlign: 'start',
                                                    }}
                                                >
                                                    <ReadMoreAndLess
                                                        charLimit={80}
                                                        readMoreText={
                                                            <IconButton>
                                                                <ExpandCircleDownIcon
                                                                    color="secondary"
                                                                    fontSize="small"
                                                                />
                                                            </IconButton>
                                                        }
                                                        readLessText={
                                                            <IconButton>
                                                                <ExpandCircleDownIcon
                                                                    color="secondary"
                                                                    sx={{
                                                                        rotate: '180deg',
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        }
                                                    >
                                                        {tashkeelEnabled
                                                            ? ruling.ruling
                                                            : removeTashkeel(
                                                                  ruling.ruling,
                                                              )}
                                                    </ReadMoreAndLess>
                                                </Typography>
                                            </Box>
                                            {ruling.book_name &&
                                            ruling.page &&
                                            ruling.volume ? (
                                                ruling.hadith_id ? (
                                                    <Button
                                                        sx={{
                                                            textAlign: 'start',
                                                            textDecoration:
                                                                'underline',
                                                            paddingLeft: 0,
                                                            fontSize:
                                                                fontSize.body,
                                                        }}
                                                        onClick={() =>
                                                            fetch(
                                                                `/api/hadith-book-preview?id=${ruling.hadith_id}`,
                                                            )
                                                                .then((res) => {
                                                                    if (
                                                                        res.ok
                                                                    ) {
                                                                        return res.json();
                                                                    } else {
                                                                        throw new Error(
                                                                            `error fetching hadith book preview ${res.status}, ${res.statusText}`,
                                                                        );
                                                                    }
                                                                })
                                                                .then(
                                                                    (json) => {
                                                                        console.log(
                                                                            'nice json',
                                                                            json,
                                                                        );
                                                                        const {
                                                                            data: {
                                                                                hits: {
                                                                                    hits: [
                                                                                        {
                                                                                            _source,
                                                                                        },
                                                                                    ],
                                                                                },
                                                                            },
                                                                        } =
                                                                            HadithModalResult.parse(
                                                                                json,
                                                                            );

                                                                        setSelectedHadith(
                                                                            {
                                                                                ..._source,
                                                                                hadith_id:
                                                                                    ruling.hadith_id as string,
                                                                            },
                                                                        );
                                                                    },
                                                                )
                                                        }
                                                    >
                                                        {` ${getBookAndIdText(
                                                            ruling.book_name,
                                                            hadithNumber,
                                                        )} (${ruling.page}/${
                                                            ruling.volume
                                                        })`}
                                                    </Button>
                                                ) : (
                                                    `${getBookAndIdText(
                                                        ruling.book_name,
                                                        hadithNumber,
                                                    )} (${ruling.page}/${
                                                        ruling.volume
                                                    })`
                                                )
                                            ) : null}
                                        </Box>
                                        <Box
                                            sx={{
                                                position: 'relative',
                                            }}
                                        >
                                            <IconButton
                                                disabled={
                                                    !ruling.book_name ||
                                                    !ruling.page ||
                                                    !ruling.volume ||
                                                    !booksConfig[
                                                        ruling.book_name
                                                    ]
                                                }
                                                onClick={() => {
                                                    analytics('book-preview', {
                                                        type: 'hadith-ruling',
                                                        id: ruler,
                                                        hadith_id:
                                                            ruling.hadith_id ??
                                                            'external',
                                                        source: 'hadith-rulings-result-item',
                                                    });
                                                    setRulingPreview({
                                                        book_name:
                                                            ruling.book_name as string,
                                                        // same as `id` in getRulingsReferences function
                                                        initNavPointId: `${ruler}-${ruling.esIndex}`,
                                                    });
                                                }}
                                            >
                                                <PreviewIcon
                                                    color={
                                                        ruling.book_name &&
                                                        booksConfig[
                                                            ruling.book_name
                                                        ] &&
                                                        ruling.volume &&
                                                        ruling.page
                                                            ? 'secondary'
                                                            : 'disabled'
                                                    }
                                                    fontSize="large"
                                                    sx={{
                                                        alignSelf: 'flex-start',
                                                    }}
                                                />
                                            </IconButton>

                                            {(!ruling.book_name ||
                                                !booksConfig[
                                                    ruling.book_name
                                                ]) && (
                                                <CustomTooltip
                                                    message={t(
                                                        'ruling_preview_not_avilable',
                                                    )}
                                                />
                                            )}
                                        </Box>
                                    </Box>
                                );
                            })}
                    </Grid>
                </Grid>
            </HadithRulingsContainer>
        </>
    );
};

export default HadithRulingsResultItem;
