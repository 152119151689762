import { NarratorViews } from '@constants/views';
import {
    NavigateBefore as NavigateBeforeIcon,
    NavigateNext as NavigateNextIcon,
} from '@mui/icons-material';
import {
    Box,
    Button,
    MenuItem,
    Select,
    SelectChangeEvent,
} from '@mui/material';

import type { Narrator, NarratorsPageNarrator } from 'shared/interfaces/hadith';

interface FullNarratorsListProps {
    selectedNarratorId?: string;
    narrators: Narrator[] | NarratorsPageNarrator[];
    handleNarratorChange: (
        id: string | number,
        view?: NarratorViews,
    ) => Promise<boolean> | undefined;
}

const HadithNarratorsList = ({
    narrators,
    handleNarratorChange,
    selectedNarratorId,
}: FullNarratorsListProps) => {
    const currentNarratorIndex = narrators.findIndex(
        (n) => selectedNarratorId === n.id,
    );

    if (!selectedNarratorId) {
        return null;
    }

    const nextNarrator = narrators[currentNarratorIndex - 1];
    const prevNarrator = narrators[currentNarratorIndex + 1];

    const narratorsDropdown = document.getElementById('narrators-dropdown');
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Button
                sx={{ ml: 1, minWidth: { xs: '10px' } }}
                disabled={
                    narrators.findIndex(
                        (narrator) => selectedNarratorId === narrator.id,
                    ) === 0
                }
                variant="outlined"
                onClick={() => {
                    handleNarratorChange(nextNarrator.id);
                }}
            >
                <NavigateNextIcon />
            </Button>
            <Select
                sx={{
                    ml: 1,
                    mr: 1,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'wrap',
                    maxWidth: { xs: '250px', sm: '400px' },
                }}
                dir="rtl"
                labelId="narrators-dropdown-label"
                id="narrators-dropdown"
                value={selectedNarratorId}
                label="narrators"
                onChange={(event: SelectChangeEvent) => {
                    const refreshed = handleNarratorChange(event.target.value);
                    if (refreshed) {
                        refreshed.then(() => {
                            setTimeout(() => narratorsDropdown?.blur(), 100);
                        });
                    }
                }}
            >
                {narrators.map((narrator) => {
                    return (
                        <MenuItem
                            key={narrator.id}
                            value={narrator.id}
                            sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'wrap',
                            }}
                        >
                            {narrator['full_name']}
                        </MenuItem>
                    );
                })}
            </Select>
            <Button
                sx={{ mr: 1, minWidth: { xs: '10px' } }}
                disabled={
                    narrators.findIndex(
                        (narrator) => selectedNarratorId === narrator.id,
                    ) ===
                    narrators.length - 1
                }
                onClick={() => {
                    handleNarratorChange(prevNarrator.id);
                }}
                variant="outlined"
            >
                <NavigateBeforeIcon />
            </Button>
        </Box>
    );
};

export default HadithNarratorsList;
