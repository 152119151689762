import styled from '@emotion/styled';
import { List, TextField, Box } from '@mui/material';

export const ButtonWrapper = styled(Box)`
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-item: center;
`;

export const SearchInput = styled(TextField)`
    width: 100%;
    border-radius: 4px;
`;
export const SuggestionsList = styled(List)`
    background-color: white;
    width: 100%;
    overflowy: 'auto';
    position: absolute;
    margin: 0;
    border-top: none;
    z-index: 1000;
    border-right: 1px solid rgb(204, 204, 204);
    border-bottom: 1px solid rgb(204, 204, 204);
    border-left: 1px solid rgb(204, 204, 204);
    padding: 0;
`;

export const ListItemContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;
