import Head from 'next/head';
import { useRouter } from 'next/router';

import { env } from '@constants/env';

// Default value for some meta data
const defaultMeta = {
    title: 'المنصة الحديثية',
    siteName: 'المنصة الحديثية',
    description:
        'المنصة الحديثية: منصة رائدة لخدمة الحديث النبوي الشريف دراية ورواية، مستخدمة أحدث وسائل التكنولوجيا والذكاء الاصطناعي',
    // change base url of your web (without '/' at the end)
    url: 'https://alminasa.ai',
    type: 'website',
    robots: env === 'prod' ? 'follow, index' : 'noindex',
    // change with url of your image (recommended dimension = 1.91:1)
    // used in twitter, facebook, etc. card when link copied in tweet/status
    image: '/images/logo-horizontal.png',
    author: 'Alminasa',
};

/**
 * Next Head component populated with necessary SEO tags and title
 * props field used:
 * - title
 * - siteName
 * - description
 * - url
 * - type
 * - robots
 * - image
 * - date
 * - author
 * - templateTitle
 * all field are optional (default value defined on defaultMeta)
 * @example
 * <SeoHead title="Page's Title" />
 */
const SeoHead = (props: any) => {
    const router = useRouter();
    const meta = {
        ...defaultMeta,
        ...props,
    };

    // Use siteName if there is templateTitle
    // but show full title if there is none
    meta.title = props.templateTitle
        ? `${props.templateTitle} | ${meta.siteName}`
        : meta.title;

    return (
        <Head>
            <title>{meta.title}</title>
            <meta name="robots" content={meta.robots} />
            <meta content={meta.description} name="description" />
            <meta property="og:url" content={`${meta.url}${router.asPath}`} />
            <link rel="canonical" href={`${meta.url}${router.asPath}`} />
            {/* Open Graph */}
            <meta property="og:type" content={meta.type} />
            <meta property="og:site_name" content={meta.siteName} />
            <meta property="og:description" content={meta.description} />
            <meta property="og:title" content={meta.title} />
            <meta name="image" property="og:image" content={meta.image} />
            {/* Twitter */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@hp" />
            <meta name="twitter:title" content={meta.title} />
            <meta name="twitter:description" content={meta.description} />
            <meta name="twitter:image" content={meta.image} />
            {/* https://stackoverflow.com/questions/47302707/css-safe-area-attributes-doesnt-work-on-iphone-x */}
            <meta
                name="viewport"
                content="viewport-fit=cover, width=device-width, initial-scale=1.0"
            />
            <meta name="apple-mobile-web-app-capable" content="yes" />
            <meta
                name="apple-mobile-web-app-status-bar-style"
                content="default"
            />

            {meta.date && (
                <>
                    <meta
                        property="article:published_time"
                        content={meta.date}
                    />
                    <meta
                        name="publish_date"
                        property="og:publish_date"
                        content={meta.date}
                    />
                    <meta
                        name="author"
                        property="article:author"
                        content={meta.author}
                    />
                </>
            )}
            {/* Favicons */}
            {favicons.map((linkProps) => (
                <link key={linkProps.href} {...linkProps} />
            ))}
            {/* Windows 8 app icon */}
            <meta name="msapplication-TileColor" content="#FFFFFF" />
            <meta
                name="msapplication-TileImage"
                content="/favicon/ms-icon-144x144.png"
            />
            {/* this is for the status bar color, persumably */}
            <meta name="theme-color" content="#FFFFFF" />
        </Head>
    );
};

// Favicons, other icons, and manifest definition
const favicons = [
    {
        rel: 'apple-touch-icon',
        sizes: '57x57',
        href: '/ios/57.png',
    },
    {
        rel: 'apple-touch-icon',
        sizes: '60x60',
        href: '/ios/60.png',
    },
    {
        rel: 'apple-touch-icon',
        sizes: '72x72',
        href: '/ios/72.png',
    },
    {
        rel: 'apple-touch-icon',
        sizes: '76x76',
        href: '/ios/76.png',
    },
    {
        rel: 'apple-touch-icon',
        sizes: '114x114',
        href: '/ios/114.png',
    },
    {
        rel: 'apple-touch-icon',
        sizes: '120x120',
        href: '/ios/120.png',
    },
    {
        rel: 'apple-touch-icon',
        sizes: '144x144',
        href: '/ios/144.png',
    },
    {
        rel: 'apple-touch-icon',
        sizes: '152x152',
        href: '/ios/152.png',
    },
    {
        rel: 'apple-touch-icon',
        sizes: '180x180',
        href: '/ios/180.png',
    },
    {
        rel: 'mask-icon',
        href: '/favicon/safari-pinned-tab.svg',
        color: '#F59A9A',
    },
    {
        rel: 'icon',
        href: '/favicon/favicon.ico',
    },
    {
        rel: 'icon',
        type: 'image/png',
        sizes: '16x16',
        href: '/favicon/favicon-16x16.png',
    },
    {
        rel: 'icon',
        type: 'image/png',
        sizes: '32x32',
        href: '/favicon/favicon-32x32.png',
    },
    {
        rel: 'icon',
        type: 'image/png',
        sizes: '96x96',
        href: '/favicon/favicon-96x96.png',
    },
    {
        rel: 'icon',
        type: 'image/png',
        sizes: '192x192',
        href: '/android/android-launchericon-192-192.png',
    },
];

export default SeoHead;
