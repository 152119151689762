import { useEffect, useState } from 'react';

import { CustomTooltip } from '@components/CustomTooltip';
import ScrollSvg from '@components/ScrollSvg';

import { ReactiveComponent } from '@appbaseio/reactivesearch';
import useTranslation from '@hooks/useTranslation';
import { Search } from '@mui/icons-material';
import { Box, IconButton, TextField } from '@mui/material';
import { a2e, e2a } from '@utils/arabicToEnglishNumbers';

interface INumberSearchProps {
    /**
     * @description The title for the header
     */
    label: string;

    /**
     * @description DataSearch component Id for ReactiveSearch
     */
    componentId: string;

    /**
     * @description Reactive base data field for the MultiList
     */
    dataField: string;

    /**
     * @description MultiList react prop
     * @default {}
     */
    dependencies?: object;

    /**
     * @description Displays the filter label and selected items once selected
     * @default true
     */
    showFilter?: boolean;

    /**
     * @description Whether to add url params based on filter selection
     * @default true
     */
    URLParams?: boolean;

    /**
     * @description style prop.
     * @default {marginBottom: 20}
     */
    style?: object;

    initialHadithSerialId: string;
}

type setStringFn = (str: string) => void;

function incrementHadithSerialId(
    hadithSerialId: string,
    setHadithSerialId: setStringFn,
) {
    if (hadithSerialId.match(/[٠-٩]+/g)) {
        const newHadithSerialId = e2a(Number(a2e(hadithSerialId)) + 1);
        setHadithSerialId(newHadithSerialId);
        return newHadithSerialId;
    } else {
        const newHadithSerialId = (Number(hadithSerialId) + 1).toString();
        setHadithSerialId(newHadithSerialId);
        return newHadithSerialId;
    }
}

function decrementHadithSerialId(
    hadithSerialId: string,
    setHadithSerialId: setStringFn,
) {
    if (hadithSerialId.match(/[٠-٩]+/g)) {
        const newHadithSerialId = e2a(
            Math.max(0, Number(a2e(hadithSerialId)) - 1),
        );
        setHadithSerialId(newHadithSerialId);
        return newHadithSerialId;
    } else {
        const newHadithSerialId = Math.max(
            0,
            Number(hadithSerialId) - 1,
        ).toString();
        setHadithSerialId(newHadithSerialId);
        return newHadithSerialId;
    }
}

function getNumberQuery(hadithSerialId: string, dataField: string) {
    const hadithNumber = Number(a2e(hadithSerialId));
    if (hadithNumber > 0) {
        return {
            query: {
                term: {
                    [dataField]: hadithNumber,
                },
            },
            value: hadithSerialId,
        };
    } else {
        return {
            query: {},
            value: '',
        };
    }
}

type NumberSearchRenderProps = Pick<
    INumberSearchProps,
    'dataField' | 'initialHadithSerialId'
> & { setQuery: (query: object) => void };

function NumberSearchRender({
    dataField,
    setQuery,
    initialHadithSerialId,
}: NumberSearchRenderProps) {
    const [hadithSerialId, setHadithSerialId] = useState<string>(
        initialHadithSerialId,
    );
    const { t } = useTranslation('library');

    // only run effect on first render, this is a workaround to defaultQuery, which doesn't seem to work
    useEffect(() => {
        setQuery(getNumberQuery(initialHadithSerialId, dataField));
        setHadithSerialId(initialHadithSerialId);
    }, [initialHadithSerialId]);

    return (
        <Box>
            <TextField
                inputProps={{
                    inputMode: 'numeric',
                    pattern: '([0-9]+ | [٠-٩]+)',
                }}
                multiline
                label={t('search_by_hadith_number')}
                rows={0.8}
                autoComplete="off"
                id="hadith_number_search_input"
                placeholder={'0'}
                variant="outlined"
                onChange={(event) => {
                    // no setQuery here because we only submit when user hits 'enter'
                    // or when they 'blur' / leave the component
                    // we can also add an 'enter' button
                    const inputValue = Number(a2e(event.target.value));
                    if (Number.isInteger(inputValue)) {
                        setHadithSerialId(event.target.value);
                    }
                }}
                onBlur={() => {
                    setQuery(getNumberQuery(hadithSerialId, dataField));
                }}
                onFocus={(event) => event.target.select()}
                onKeyDown={(event) => {
                    if (event.key === 'ArrowUp') {
                        const newHadithSerialId = incrementHadithSerialId(
                            hadithSerialId,
                            setHadithSerialId,
                        );
                        setQuery(getNumberQuery(newHadithSerialId, dataField));
                    } else if (event.key === 'ArrowDown') {
                        const newHadithSerialId = decrementHadithSerialId(
                            hadithSerialId,
                            setHadithSerialId,
                        );
                        setQuery(getNumberQuery(newHadithSerialId, dataField));
                    } else if (event.key === 'Enter') {
                        event.preventDefault();
                        setQuery(getNumberQuery(hadithSerialId, dataField));
                    }
                }}
                value={hadithSerialId}
                sx={{
                    width: '100%',
                    borderRadius: '5px',
                    outline: 'none',
                    mt: { xs: 1.5, md: 0 },

                    '& .MuiOutlinedInput-root': {
                        paddingY: 1.2,
                        '& fieldset': {
                            border: '1px solid rgba(0,0,0,0.2)',
                        },
                        '&.Mui-focused fieldset': {
                            border: '1px solid rgba(0,0,0,0.2)',
                        },
                    },
                }}
                InputProps={{
                    endAdornment: (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                height: '45px',
                                gap: 1,
                            }}
                        >
                            <IconButton
                                onClick={() => {
                                    setQuery(
                                        getNumberQuery(
                                            hadithSerialId,
                                            dataField,
                                        ),
                                    );
                                }}
                            >
                                <Search
                                    fontSize="small"
                                    color="secondary"
                                    aria-label="search"
                                />
                            </IconButton>
                            <ScrollSvg
                                onClickUp={() => {
                                    const newHadithSerialId =
                                        incrementHadithSerialId(
                                            hadithSerialId,
                                            setHadithSerialId,
                                        );
                                    setQuery(
                                        getNumberQuery(
                                            newHadithSerialId,
                                            dataField,
                                        ),
                                    );
                                }}
                                onClickDown={() => {
                                    const newHadithSerialId =
                                        decrementHadithSerialId(
                                            hadithSerialId,
                                            setHadithSerialId,
                                        );
                                    setQuery(
                                        getNumberQuery(
                                            newHadithSerialId,
                                            dataField,
                                        ),
                                    );
                                }}
                            />
                        </Box>
                    ),
                }}
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </Box>
    );
}

const NumberSearch = ({
    label,
    componentId,
    dataField,
    URLParams = true,
    initialHadithSerialId,
}: INumberSearchProps) => {
    return (
        <ReactiveComponent
            URLParams={URLParams}
            filterLabel={label}
            defaultValue={initialHadithSerialId}
            render={({ setQuery, value }) => (
                <NumberSearchRender
                    dataField={dataField}
                    setQuery={setQuery}
                    initialHadithSerialId={value ?? ''}
                />
            )}
            componentId={componentId}
        />
    );
};

export default NumberSearch;
export type { INumberSearchProps };
