import { Fragment } from 'react';

import { Box, Divider, Grid, Skeleton } from '@mui/material';

import {
    HadithActionsContainer,
    HadithActionsWrapper,
    HadithContainer,
    HadithHeaderInfo,
    HadithHeaderInfoDetails,
    HadithHeaderInfoNumber,
    HadithTitleContainer,
} from '../results/items/HadithResultItem/styled';

const HadithSkeleton = ({
    withActions,
    withMatnComparison,
}: {
    withActions: boolean;
    withMatnComparison?: boolean;
}) => {
    const matnComparisonSkeleton = (
        <>
            <Divider />
            <Box sx={{ padding: '10px' }}>
                <Skeleton
                    animation="wave"
                    variant="text"
                    width={150}
                    sx={{ fontSize: '20px' }}
                />
                <Skeleton
                    animation="wave"
                    variant="text"
                    width={200}
                    sx={{ fontSize: '16px' }}
                />
                <Skeleton
                    animation="wave"
                    variant="text"
                    sx={{ fontSize: '18px' }}
                />
                <Skeleton
                    animation="wave"
                    variant="text"
                    sx={{ fontSize: '18px' }}
                />
                <Skeleton
                    animation="wave"
                    variant="text"
                    sx={{ fontSize: '18px' }}
                />
            </Box>
        </>
    );
    return (
        <HadithContainer
            elevation={0}
            variant="outlined"
            sx={{
                margin: { xs: '10px 0px', md: '10px 0' },
                width: '100%',
            }}
        >
            <HadithHeaderInfo>
                <HadithHeaderInfoNumber>
                    <Skeleton
                        animation="wave"
                        variant="text"
                        width={30}
                        sx={{ fontSize: '16px' }}
                    />
                </HadithHeaderInfoNumber>
                <HadithHeaderInfoDetails sx={{ padding: '5px' }}>
                    <Skeleton
                        animation="wave"
                        variant="text"
                        width={40}
                        sx={{ fontSize: '16px' }}
                    />
                    <Divider
                        sx={{ marginX: '5px' }}
                        orientation="vertical"
                        flexItem
                    />
                    <Skeleton
                        animation="wave"
                        variant="text"
                        width={40}
                        sx={{ fontSize: '16px' }}
                    />
                    <Divider
                        sx={{ marginX: '5px' }}
                        orientation="vertical"
                        flexItem
                    />
                    <Skeleton
                        animation="wave"
                        variant="text"
                        width={60}
                        sx={{ fontSize: '16px' }}
                    />
                </HadithHeaderInfoDetails>
            </HadithHeaderInfo>
            <HadithTitleContainer dir="rtl">
                <Skeleton
                    animation="wave"
                    variant="text"
                    width={150}
                    sx={{ fontSize: '20px' }}
                />
                <Skeleton
                    animation="wave"
                    variant="text"
                    width={200}
                    sx={{ fontSize: '16px' }}
                />
            </HadithTitleContainer>
            <Divider />
            <Box sx={{ padding: '10px' }}>
                <Skeleton
                    animation="wave"
                    variant="text"
                    sx={{ fontSize: '18px' }}
                />
                <Skeleton
                    animation="wave"
                    variant="text"
                    sx={{ fontSize: '18px' }}
                />
                <Skeleton
                    animation="wave"
                    variant="text"
                    sx={{ fontSize: '18px' }}
                />
            </Box>
            {withMatnComparison && matnComparisonSkeleton}
            {withActions && <Divider />}
            <>
                {withActions && (
                    <HadithActionsWrapper>
                        <HadithActionsContainer>
                            <Grid
                                container
                                sx={{
                                    display: { xs: 'block', sm: 'flex' },
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    sm={4}
                                    display="flex"
                                    flexDirection="row"
                                    justifyContent={{
                                        xs: 'space-evenly',
                                        sm: 'flex-end',
                                    }}
                                >
                                    {[...Array(3)].map((_, index) => (
                                        <Fragment key={`actions-${index}`}>
                                            <Box
                                                key={`actions-${index}`}
                                                sx={{
                                                    padding: '10px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'right',
                                                }}
                                            >
                                                <Skeleton
                                                    animation="wave"
                                                    variant="text"
                                                    width={40}
                                                    sx={{
                                                        fontSize: '16px',
                                                    }}
                                                />
                                            </Box>
                                        </Fragment>
                                    ))}
                                </Grid>
                            </Grid>
                        </HadithActionsContainer>
                    </HadithActionsWrapper>
                )}
            </>
        </HadithContainer>
    );
};

export default HadithSkeleton;
