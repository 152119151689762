import { CustomTooltip } from '@components/CustomTooltip';
import ExcelSvg from '@components/ExcelSvg';
import WordSvg from '@components/WordSvg';

import useTranslation from '@hooks/useTranslation';
import LinkIcon from '@mui/icons-material/Link';
import { Box, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { onExcelExport } from '@utils/export';

import { NextState } from 'shared/interfaces/hadith';

function ShareActions({
    exportDisabledTooMany,
    exportDisabledZero,
    fullSearchState,
    onWordExport,
    onCopy,
}: {
    exportDisabledTooMany: boolean;
    exportDisabledZero: boolean;
    fullSearchState: NextState | undefined;
    onWordExport: () => void;
    onCopy: () => void;
}) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const { t } = useTranslation('library');

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'end',
            }}
        >
            <Box sx={{ position: 'relative' }}>
                {fullSearchState && (exportDisabledTooMany || isMobile) && (
                    <CustomTooltip
                        message={
                            isMobile
                                ? t('feature_not_available_on_mobile')
                                : t('export_disabled')
                        }
                    />
                )}
                {fullSearchState && (
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <IconButton
                            onClick={
                                exportDisabledTooMany ||
                                exportDisabledZero ||
                                isMobile
                                    ? undefined
                                    : () => onExcelExport(fullSearchState, '')
                            }
                            disabled={
                                exportDisabledTooMany ||
                                exportDisabledZero ||
                                isMobile
                            }
                        >
                            <ExcelSvg
                                width={24}
                                height={24}
                                disabled={
                                    exportDisabledTooMany ||
                                    exportDisabledZero ||
                                    isMobile
                                }
                            />
                        </IconButton>
                        <IconButton
                            onClick={
                                exportDisabledTooMany ||
                                exportDisabledZero ||
                                isMobile
                                    ? undefined
                                    : onWordExport
                            }
                            disabled={
                                exportDisabledTooMany ||
                                exportDisabledZero ||
                                isMobile
                            }
                        >
                            <WordSvg
                                width={24}
                                height={24}
                                disabled={
                                    exportDisabledTooMany ||
                                    exportDisabledZero ||
                                    isMobile
                                }
                            />
                        </IconButton>
                    </Box>
                )}
            </Box>
            {fullSearchState && (
                <IconButton
                    sx={{
                        justifySelf: 'flex-end',
                    }}
                    onClick={onCopy}
                >
                    <LinkIcon
                        color="secondary"
                        fontSize="medium"
                        sx={{ rotate: '135deg' }}
                    />
                </IconButton>
            )}
        </Box>
    );
}

export default ShareActions;
