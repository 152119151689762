import useTranslation from '@hooks/useTranslation';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
    Box,
    Skeleton,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { numberWithCommas } from '@utils/numbersDisplay';

import { colors } from 'theme';

export function ResultsStats({
    loading,
    totalResults,
    totalMatns,
    resultStatsLabel,
}: {
    loading: boolean;
    totalResults: number;
    totalMatns: number;
    resultStatsLabel: string;
}) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { t } = useTranslation('library');

    if (loading) {
        return (
            <Skeleton
                variant="text"
                width={isMobile ? theme.spacing(24) : theme.spacing(32)}
                height={isMobile ? theme.spacing(5) : theme.spacing(3)}
            />
        );
    }

    if (!totalResults) {
        return null;
    }

    const totalMatnsWithTooltip = totalMatns ? (
        <Tooltip
            placement="top"
            title={<Typography fontSize={12}>{t('matn_info')}</Typography>}
            enterTouchDelay={1}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    gap: 0.2,
                }}
            >
                <Typography
                    variant="body1"
                    sx={{
                        color: colors.secondary,
                        fontSize: 14,
                    }}
                >
                    {`(${numberWithCommas(totalMatns)} ${t('matn')})`}
                </Typography>
                <InfoOutlinedIcon fontSize="small" color="secondary" />
            </Box>
        </Tooltip>
    ) : null;

    return isMobile ? (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 1,
                mb: 1,
            }}
        >
            <Typography variant="body1" color="primary">
                {`${numberWithCommas(totalResults)} ${resultStatsLabel}`}
            </Typography>
            {totalMatnsWithTooltip}
        </Box>
    ) : (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
            }}
        >
            <Typography variant="body1" color="primary">
                {`${resultStatsLabel} : ${numberWithCommas(totalResults)}`}
            </Typography>
            {totalMatnsWithTooltip}
        </Box>
    );
}
