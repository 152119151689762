import { Box, styled } from '@mui/material';

export interface GraphCanvasContainerProps {}

export const GraphCanvasContainer = styled(Box)<GraphCanvasContainerProps>(
    () => ({
        display: 'flex',
        flexDirection: 'column',
    }),
);
